import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { UserSliceState, FindAccountsThunkParams } from "../users.slice";

export const initUsers = createAsyncThunk(
  "users/init",
  async ({ apiInternal }: FindAccountsThunkParams, thunkAPI) => {
    const { filters } = (thunkAPI.getState() as RootState).users;
    const [users] = await Promise.all([apiInternal.findUsers(filters)]);
    return { users };
  }
);

export const usersInitHandlers = (
  builder: ActionReducerMapBuilder<UserSliceState>
) => {
  builder.addCase(initUsers.fulfilled, (state, action) => {
    const {
      users: { data, total },
    } = action.payload;
    state.users = data;
    state.filters.total = total;
  });
};
