import {
  Box,
  Button,
  Heading,
  HStack,
  Spinner,
  StackItem,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiReportType } from "@operations-hero/lib-api-client";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { QuickFilterButton } from "../../../components/buttons/QuickFilterButton";
import { Pager } from "../../../components/pagination/Pager";
import { RootState, useThunkDispatch } from "../../../store";
import {
  initializeReports,
  loadReports,
  PAGE_SIZE,
  unloadReports,
  updateFilters,
} from "../../../store/account/account-reports.slice";
import { ReportItem } from "./ReportItem";
import { ReportFormModal } from "./ReportModal";

export const Reports: FC = () => {
  const headerBg = useColorModeValue("gray.100", "whiteAlpha.100");
  const { apiInternal } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const reportsLoaded = useRef(false);

  const { workingAccount } = useSelector((state: RootState) => state.accounts);
  const { filters, reports, total } = useSelector(
    (root: RootState) => root.reports
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isSystemActive, isAccountActive, isAllActive } = {
    isSystemActive: !!filters.type && filters.type === "system",
    isAccountActive: !!filters.type && filters.type === "account",
    isAllActive: !!filters.type === false,
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const accountReportItems = useMemo(() => {
    return reports.map((ar) => (
      <ReportItem report={ar} key={`report::${ar.id}`} />
    ));
  }, [reports]);

  const onSubmitCompleted = useCallback(async () => {
    dispatch(updateFilters({ type: ApiReportType.account, page: 1 }));
  }, [dispatch]);

  const updateTypeFilter = useCallback(
    (type: ApiReportType | undefined) => {
      dispatch(updateFilters({ type, page: 1 }));
    },
    [dispatch]
  );

  const onPageChange = useCallback(
    (newPage: number) => {
      dispatch(updateFilters({ page: newPage }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (reportsLoaded.current) return;
    setIsLoading(true);
    thunkDispatch(initializeReports({ apiInternal, filters })).then(() => {
      reportsLoaded.current = true;
      setIsLoading(false);
    });
  }, [apiInternal, workingAccount, filters, thunkDispatch, dispatch]);

  useEffect(() => {
    if (reportsLoaded.current)
      thunkDispatch(loadReports({ apiInternal, filters }));
  }, [filters, thunkDispatch, apiInternal]);

  useEffect(() => {
    return () => {
      dispatch(unloadReports());
    };
  }, [dispatch]);

  return (
    <Box>
      <HStack py={4} justifyContent="space-between">
        <Heading mb={2} size="lg">
          Reports
        </Heading>
        <Button size="sm" onClick={onOpen}>
          Add report
        </Button>
      </HStack>
      <HStack>
        <QuickFilterButton
          label="System"
          isActive={isSystemActive}
          onClick={() => updateTypeFilter(ApiReportType.system)}
        />
        <QuickFilterButton
          label="Account"
          isActive={isAccountActive}
          onClick={() => updateTypeFilter(ApiReportType.account)}
        />

        <QuickFilterButton
          label="All"
          isActive={isAllActive}
          onClick={() => updateTypeFilter(undefined)}
        />
      </HStack>
      <HStack
        p={2}
        w="100%"
        gap="4px"
        spacing={0}
        bgColor={headerBg}
        display={["none", "none", "grid"]}
        gridTemplateColumns="20% 50% 1fr"
      >
        <StackItem fontWeight="bold">OH Product</StackItem>
        <StackItem fontWeight="bold">Luzmo dashboard</StackItem>
        <StackItem fontWeight="bold">Actions</StackItem>
      </HStack>

      {isLoading ? (
        <Box w="full" justifyContent="center" display="flex" mt={2}>
          <Spinner color="gray" size="md" />
        </Box>
      ) : accountReportItems.length === 0 ? (
        <Text>No reports for this account</Text>
      ) : (
        accountReportItems
      )}

      {filters.page && filters.pageSize && (
        <Pager
          totalPages={Math.ceil(total / PAGE_SIZE)}
          currentPage={filters.page || 1}
          onPageChange={onPageChange}
        />
      )}

      {workingAccount && (
        <ReportFormModal
          isOpen={isOpen}
          onClose={onClose}
          accountId={workingAccount.id}
          onSubmit={onSubmitCompleted}
        />
      )}
    </Box>
  );
};
