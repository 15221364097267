import {
  Button,
  Flex,
  FlexProps,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { CustomCollapse } from "../customCollpase/CustomCollapse";
import { CustomModal } from "../modal/CustomModal";

interface PageHeaderProps {
  pageTitle: string | React.ReactNode;
  firstSectionComponent?: React.ReactNode;
  firstSectionInCollpase?: boolean;
  firstSectionFlexProps?: FlexProps;

  secondSectionComponent?: React.ReactNode;
  secondSectionInModal?: boolean;
  secondSectionFlexProps?: FlexProps;
}

export const PageHeader: FC<PageHeaderProps> = ({
  pageTitle,
  firstSectionComponent,
  firstSectionInCollpase,
  firstSectionFlexProps,

  secondSectionComponent,
  secondSectionInModal,
  secondSectionFlexProps,
}) => {
  const textColor = useColorModeValue("blue.500", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex w="100%" flexDir="column" gap={4}>
      <Flex gap={4} w="full" flexDir={["column", "column", "row"]}>
        <Heading size="lg" isTruncated>
          {pageTitle}
        </Heading>

        {firstSectionComponent && (
          <>
            {firstSectionInCollpase ? (
              <CustomCollapse
                trigger={
                  <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      color={textColor}
                      display="inline-flex"
                      alignItems="center"
                    >
                      Show filters <Icon as={IoMdArrowDropdown} ml={1} />
                    </Text>
                    {secondSectionComponent && secondSectionInModal && (
                      <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onOpen();
                        }}
                      >
                        Search/Selects
                      </Button>
                    )}
                  </Flex>
                }
                children={firstSectionComponent}
              />
            ) : (
              <Flex
                gap={2}
                flex={1}
                ml={[0, 0, 12]}
                alignItems="center"
                {...firstSectionFlexProps}
              >
                {firstSectionComponent}
              </Flex>
            )}
          </>
        )}
      </Flex>

      {secondSectionComponent && !secondSectionInModal && (
        <Flex gap={2} alignItems="center" {...secondSectionFlexProps}>
          {secondSectionComponent}
        </Flex>
      )}

      {!firstSectionInCollpase &&
        secondSectionComponent &&
        secondSectionInModal && (
          <Flex w="full" justifyContent="flex-end">
            <Button size="sm" colorScheme="blue" onClick={onOpen}>
              Search/Selects
            </Button>
          </Flex>
        )}

      {isOpen && (
        <CustomModal
          content={secondSectionComponent}
          title="Filters"
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Flex>
  );
};
