import { FC, useCallback, Fragment, useState } from "react";
import { Box, Input, Text, useColorModeValue } from "@chakra-ui/react";

interface MetadataSectionProps {
  metadata: Record<string, string>;
  setFieldValue: (
    field: string,
    value: Record<string, string>,
    shouldValidate?: boolean | undefined
  ) => void;
}

const MetadataSection: FC<MetadataSectionProps> = ({
  metadata,
  setFieldValue,
}) => {
  const errorTextColor = useColorModeValue("red.500", "red.300");
  const [internalValues, setInternalValues] = useState([
    ...Object.keys(metadata).map((key) => ({
      key,
      value: metadata[key],
    })),
    { key: "", value: "" },
  ]);

  const onChangeText = useCallback(
    (text: string, index: number, type: "key" | "value") => {
      setInternalValues((oldValues) => {
        let copy = [...oldValues];
        copy[index][type] = text;
        if (index === copy.length - 1 || copy.length === 1) {
          copy = [...copy, { key: "", value: "" }];
        }

        if (
          text === "" &&
          copy[index][type] === "" &&
          copy[index][type === "key" ? "value" : "key"] === ""
        ) {
          copy.splice(index, 1);
        }

        const newMetadata = copy.reduce((pre, cu) => {
          if (cu.key.length > 0 && cu.value.length > 0) {
            pre[cu.key] = cu.value;
          }
          return pre;
        }, {} as Record<string, string>);
        setFieldValue("metadata", newMetadata);
        return copy;
      });
    },
    [setFieldValue]
  );

  return (
    <Box>
      <Text>Labels</Text>
      <Text fontSize="xs" color="gray.500">
        {`Use labels to add extra information about the metadata.`}
      </Text>
      <Text fontSize="xs" color="gray.500">
        Keys should be unique.
      </Text>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4} mt={3}>
        {internalValues.map((label, index) => (
          <Fragment key={`key${index}`}>
            <Box>
              <Input
                id={`key${index}`}
                className="key"
                placeholder="Enter a Label"
                value={label.key}
                onChange={(element) => {
                  onChangeText(element.target.value, index, "key");
                }}
                isInvalid={
                  (label.key === "" && index !== internalValues.length - 1) ||
                  internalValues
                    .filter((l, ind) => index !== ind)
                    .map((label) => label.key)
                    .includes(internalValues[index].key)
                }
              />
              {label.key === "" && index !== internalValues.length - 1 && (
                <Text fontSize="sm" textColor={errorTextColor}>
                  key is a required field
                </Text>
              )}
              {(label.key === "" && index !== internalValues.length - 1) ||
                (internalValues
                  .filter((l, ind) => index !== ind)
                  .map((label) => label.key)
                  .includes(internalValues[index].key) && (
                  <Text fontSize="sm" textColor={errorTextColor}>
                    duplicate key
                  </Text>
                ))}
            </Box>
            <Box>
              <Input
                id={`value${index}`}
                className="value"
                placeholder="Enter a Value"
                value={label.value}
                onChange={(element) => {
                  onChangeText(element.target.value, index, "value");
                }}
                isInvalid={
                  label.value === "" && index !== internalValues.length - 1
                }
              />
              {label.value === "" && index !== internalValues.length - 1 && (
                <Text fontSize="sm" textColor={errorTextColor}>
                  value is a required field
                </Text>
              )}
            </Box>
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default MetadataSection;
