import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { FC, useCallback, useState } from "react";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { CustomModal } from "../../components/modal/CustomModal";
import { UserAutocomplete } from "../../components/selects/UserAutocomplete";
import { UserMultipleAutocomplete } from "../../components/selects/UserMultipleAutocomplete";
import { useShowToast } from "../../hooks/showToast";
import { MergeUserConfirmation } from "./MergeUserConfirmation";

interface MergeUsersProps {
  accountId: string;
  selectedUser: ApiUserSummary | null;
  onCloseMergeModal: () => void;
}

export const MergeUsers: FC<MergeUsersProps> = ({
  accountId,
  selectedUser,
  onCloseMergeModal,
}) => {
  const initUser = selectedUser ? [selectedUser] : [];
  const [sourceUsers, setSourceUsers] = useState<ApiUserSummary[]>(initUser);
  const [targetUser, setTargetUser] = useState<ApiUserSummary | null>(null);
  const [formErrors, setFormErrors] = useState({
    source: false,
    target: false,
  });

  const showToast = useShowToast();
  const { apiClient } = useAuthentication();
  const {
    isOpen: isOpenConfirm,
    onOpen: openConfirmDialog,
    onClose: closeConfirmDialog,
  } = useDisclosure();

  const handleOnChangeSourceUsers = useCallback(
    (value: ApiUserSummary[]) => {
      setSourceUsers(value);
      const source = !value || value.length === 0 ? true : false;
      setFormErrors({ ...formErrors, source });
    },
    [formErrors]
  );

  const handleOnChangeTargetUser = useCallback(
    (value: ApiUserSummary | null) => {
      setTargetUser(value);
      const target = value ? false : true;
      setFormErrors({ ...formErrors, target });
    },
    [formErrors]
  );

  const checkErrors = useCallback(() => {
    const source = !sourceUsers || sourceUsers.length === 0 ? true : false;
    const target = targetUser ? false : true;
    setFormErrors({ source, target });
    if (!source && !target) openConfirmDialog();
  }, [openConfirmDialog, sourceUsers, targetUser]);

  const handleClickMerge = useCallback(async () => {
    await checkErrors();
  }, [checkErrors]);

  const handleOnMerge = useCallback(() => {
    if (!sourceUsers || !targetUser) return;

    const sourceUserIds = sourceUsers.map((item) => item.id);

    const targetUserId = targetUser.id;
    apiClient
      .mergeAccountUsers(accountId, {
        usersToMerge: sourceUserIds,
        targetUser: targetUserId,
      })
      .then(() => {
        showToast("success", "User(s) merged successufully");
        onCloseMergeModal();
      })
      .catch(() => {
        showToast("error", "something went wrong, please try again!");
      });
  }, [
    accountId,
    apiClient,
    onCloseMergeModal,
    showToast,
    sourceUsers,
    targetUser,
  ]);

  return (
    <VStack gap={2}>
      <FormControl isInvalid={formErrors.source}>
        <FormLabel fontSize="sm">Source user(s)</FormLabel>
        <UserMultipleAutocomplete
          value={sourceUsers}
          accountId={accountId}
          onChange={handleOnChangeSourceUsers}
        />
        <FormErrorMessage>
          At least one user should be selected
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={formErrors.target}>
        <FormLabel fontSize="sm">Target user</FormLabel>
        <UserAutocomplete
          value={targetUser}
          accountId={accountId}
          onChange={handleOnChangeTargetUser}
          excludeUsers={sourceUsers as ApiUserSummary[]}
        />
        <FormErrorMessage>Target user is required</FormErrorMessage>
      </FormControl>

      <Flex justifyContent="space-between" w="100%" py={2}>
        <Button variant="ghost" onClick={onCloseMergeModal}>
          Cancel
        </Button>
        <Button colorScheme="blue" onClick={handleClickMerge}>
          Merge
        </Button>
      </Flex>

      <CustomModal
        title="Merge Users Confirmation"
        isOpen={isOpenConfirm}
        onClose={closeConfirmDialog}
        contentProps={{ maxW: "xl" }}
        content={
          <MergeUserConfirmation
            onClose={closeConfirmDialog}
            onMerge={handleOnMerge}
          />
        }
      />
    </VStack>
  );
};
