import { Badge, BadgeProps } from "@chakra-ui/react";
import { useMemo } from "react";

const COLOR_MAP: Record<string, string> = {
  pending: "orange",
  processing: "blue",
  completed: "green",
  failed: "red",
};

export function ImportStatusBadge({
  status,
  ...rest
}: { status: string } & BadgeProps) {
  const color = useMemo(() => COLOR_MAP[status] || "", [status]);

  return (
    <Badge colorScheme={color} {...rest}>
      {status}
    </Badge>
  );
}
