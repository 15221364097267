import {
  Box,
  Flex,
  Icon,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiClient, ApiToken, ApiUser } from "@operations-hero/lib-api-client";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiInternalService } from "../../services/api-internal/internal-service";
import { AccountsData } from "../../services/api-internal/models/AccountData";
import { RootState, useThunkDispatch } from "../../store";
import { initAuth, setCurrentAccount } from "../../store/auth.slice";
import { Logo } from "../shell/nav/Logo";

export interface AuthenticationContextInterface {
  token: ApiToken;
  apiClient: ApiClient;
  apiInternal: ApiInternalService;
  currentUser: ApiUser;
  currentAccount: AccountsData;
  setCurrentAccount: (acct: AccountsData) => void;
  isInternalUser: boolean;
}

const AuthenticationContext = createContext<AuthenticationContextInterface>(
  {} as AuthenticationContextInterface
);

export const useAuthentication = () => useContext(AuthenticationContext);

export const AuthProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<ApiToken>({} as ApiToken);
  const [apiClient, setApiClient] = useState<ApiClient>({} as ApiClient);
  const [apiInternal, setApiInternal] = useState<ApiInternalService>(
    {} as ApiInternalService
  );
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const { currentAccount, currentUser, isInternalUser } = useSelector(
    (state: RootState) => state.auth
  );

  // useHistory was replaced by useNavigate react-router-dom
  const navigate = useNavigate();
  const location = useLocation();
  const errorBg = useColorModeValue("red.200", "red.300");

  const setCurrentAccountWrapper = useCallback(
    (acct: AccountsData) => dispatch(setCurrentAccount(acct)),
    [dispatch]
  );

  useEffect(() => {
    const qs = window.location.search;
    const accessTokenMatches = qs.match(/(&|\?)access_token=(.*)?(&|$)/);

    if (!accessTokenMatches || !accessTokenMatches[2]) {
      localStorage.setItem("returnUrl", location.pathname + location.search);
      window.location.href = `${process.env.REACT_APP_AUTH_SERVER}/login?client_id=${process.env.REACT_APP_CLIENT_ID}`;
      return;
    }

    const token = { accessToken: accessTokenMatches[2] };

    const apiClient = new ApiClient({
      apiServer: process.env.REACT_APP_API_SERVER || "",
    });

    const apiInternal = ApiInternalService.getInstance(token.accessToken);

    apiClient.setToken(token);

    thunkDispatch(initAuth({ apiClient, apiInternal })).then(() => {
      setToken(token);
      setApiClient(apiClient);
      setApiInternal(apiInternal);

      setLoading(false);
      const returnPath = localStorage.getItem("returnUrl");
      localStorage.removeItem("returnUrl");
      navigate(`${returnPath}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thunkDispatch]);

  return loading ? (
    <Flex
      minW="100%"
      minH="100vh"
      flexDir="row"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner color="gray" size="lg" boxSize="32px" />
    </Flex>
  ) : isInternalUser ? (
    <AuthenticationContext.Provider
      value={{
        token,
        currentUser,
        apiClient,
        apiInternal,
        currentAccount,
        isInternalUser,
        setCurrentAccount: setCurrentAccountWrapper,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  ) : (
    <Flex
      mt={8}
      w="100%"
      h="250px"
      as="main"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb={8}>
        <Logo />
      </Box>
      <Text
        p={8}
        minH="60px"
        d="inline-flex"
        borderRadius={4}
        bgColor={errorBg}
        alignItems="center"
      >
        <Icon as={FiAlertCircle} boxSize="24px" mr={2} color="red.500" />
        There was an error processing your request, make sure that you have
        enabled permissions to access
      </Text>
    </Flex>
  );
};
