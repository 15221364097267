import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField } from "formik";
import { useCallback } from "react";
import { StateSelect } from "../selects/StateSelect";

export const StateSelectControl = ({
  name,
  value,
  country,
}: {
  name: string;
  value: string | null;
  country?: string;
}) => {
  const [field, meta, helper] = useField({
    name,
    value: value || "",
  });

  const handleOnChange = useCallback(
    (state: string | null) => {
      helper.setTouched(true);
      helper.setValue(state || undefined);
    },
    [helper]
  );

  return (
    <FormControl id={name} mb={7}>
      <FormLabel htmlFor={name}>State</FormLabel>
      <StateSelect
        key={`stateSelect::${field.value}`}
        value={field.value}
        onChange={handleOnChange}
        allowEmpty={true}
        country={country}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
