import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../..";
import {
  FindOrganizationAccountThunkParams,
  OrganizationSliceState,
} from "../organizations-accounts.slice";

export const initOrganizationsAccounts = createAsyncThunk(
  "organizations-accounts/init",
  async (
    { apiInternal, orgId }: FindOrganizationAccountThunkParams,
    thunkAPI
  ) => {
    const { workingOrganization, filters } = (thunkAPI.getState() as RootState)
      .organizationAccountSlice;
    if (!workingOrganization && !orgId) return Promise.reject();
    const id = (workingOrganization?.id || orgId) as string;
    const accounts = await apiInternal.findOrganizationAccounts(id, filters);
    return { accounts };
  }
);

export const organizationsAccountsInitHandlers = (
  builder: ActionReducerMapBuilder<OrganizationSliceState>
) => {
  builder.addCase(initOrganizationsAccounts.fulfilled, (state, action) => {
    const {
      accounts: { data, options, total },
    } = action.payload;
    state.accounts = data;
    state.filters.page = options.page || 1;
    state.filters.total = total;
  });
};
