import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "../components/auth/AuthProvider";
import { AppShell } from "../components/shell/AppShell";
import store from "../store";
import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <ChakraProvider>
            <AppShell />
          </ChakraProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
