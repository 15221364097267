import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Icon,
  Img,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ApiAccountDetail } from "@operations-hero/lib-api-client";
import axios from "axios";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { IoIosCamera } from "react-icons/io";
import { useImageResizer } from "../../hooks/useImageResizer";
import { useAuthentication } from "../auth/AuthProvider";

export interface LogoEditorProps {
  account: ApiAccountDetail;
  onLogoChange: (account: string) => void;
}

export const LogoEditor = ({ account, onLogoChange }: LogoEditorProps) => {
  const { apiClient } = useAuthentication();
  const toast = useToast();

  const fileRef = useRef(null);
  const canvas = useRef(null);
  const { resizeImage } = useImageResizer();

  const [isUploading, setIsUploading] = useState(false);
  const noImageBgColor = useColorModeValue("transparent", "whiteAlpha.100");
  const noImageBorderColor = useColorModeValue("blue.500", "blue.300");

  const browseFiles = useCallback(() => {
    //@ts-ignore
    fileRef.current.click();
  }, []);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }
      const rawFile = event.target.files[0];
      setIsUploading(true);
      resizeImage(rawFile, 400, 200, "PNG")
        .then(async (file) => {
          const upload = await apiClient.createUpload(account.id);
          await axios.put(upload.url, file, {
            headers: { "Content-Type": file.type },
          });
          const updated = await apiClient.updateAccountLogo(account.id, {
            uploadId: upload.id,
          });

          if (fileRef.current) {
            //@ts-ignore
            fileRef.current.value = "";
          }
          onLogoChange(updated.logo);
        })
        .catch((error) => {
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "error",
            title: "Error updating logo",
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [apiClient, resizeImage, account, toast, onLogoChange]
  );

  const handleRemoveLogo = useCallback(() => {
    setIsUploading(true);
    apiClient
      .updateAccountLogo(account.id, { uploadId: null })
      .then((updated) => {
        if (fileRef.current) {
          //@ts-ignore
          fileRef.current.value = "";
        }
        onLogoChange(updated.logo);
        toast({
          duration: 1500,
          position: "top",
          isClosable: true,
          status: "success",
          title: "Removed successfully",
          id: `${new Date().getDate().toString()}`,
        });
      })
      .catch(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Error removing logo",
        });
      })
      .finally(() => {
        setIsUploading(false);
      });
  }, [apiClient, onLogoChange, toast, account]);

  return (
    <>
      <Heading size="md">Logo</Heading>
      {account.logo ? (
        <Img src={account.logo} alt="Logo" />
      ) : (
        <AspectRatio
          p={4}
          ratio={4 / 2}
          cursor="pointer"
          border="dashed 2px"
          onClick={browseFiles}
          bgColor={noImageBgColor}
          borderColor={noImageBorderColor}
        >
          <Stack>
            <Text color="blue.500">
              <Icon as={IoIosCamera} width={10} height={10} />
            </Text>
            <Button
              size="sm"
              variant="outline"
              colorScheme="blue"
              // onClick={browseFiles}
            >
              Upload Image
            </Button>
            <Text>You can add your logo</Text>
          </Stack>
        </AspectRatio>
      )}
      <Text as="i" fontSize="sm">
        Max Image Size 400x200
      </Text>
      <Box pt={2}>
        <input
          type="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="image/png, image/jpeg"
        />
        {account.logo && (
          <Wrap>
            <WrapItem>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={browseFiles}
                isLoading={isUploading}
              >
                Change Logo
              </Button>
            </WrapItem>
            <WrapItem>
              <Button
                size="sm"
                onClick={handleRemoveLogo}
                isDisabled={isUploading}
              >
                Remove Logo
              </Button>
            </WrapItem>
          </Wrap>
        )}
        <canvas ref={canvas} style={{ display: "none" }} />
      </Box>
    </>
  );
};
