import {
  Box,
  Divider,
  Heading,
  HStack,
  StackItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiInvoiceInternal } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AccountFormProps } from "../AccountForm";
import { InvoiceModal } from "./InvoiceModal";

export const Invoices: FC<AccountFormProps> = ({ account }) => {
  const { apiInternal } = useAuthentication();

  const [invoice, setInvoice] = useState<ApiInvoiceInternal>();

  const headerBg = useColorModeValue("gray.100", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    apiInternal.findInvoiceNumberInternal(account.id).then((inv) => {
      setInvoice(inv);
    });
  }, [apiInternal, account]);

  return (
    <Box>
      <HStack py={4} justifyContent="space-between">
        <Box>
          <Heading mb={2} size="lg">
            Invoices
          </Heading>
        </Box>
      </HStack>

      <HStack
        p={2}
        w="100%"
        gap="4px"
        spacing={0}
        flexDir="row"
        flexWrap="wrap"
        bgColor={headerBg}
        alignItems="center"
        d={["none", "none", "flex"]}
      >
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Invoice
        </StackItem>
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Last Invoice Number
        </StackItem>
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Current Invoice Number
        </StackItem>
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Total Invoice
        </StackItem>
      </HStack>
      {invoice && (
        <HStack
          py={[2, 2, 4]}
          w="100%"
          gap="4px"
          spacing={0}
          flexDir="row"
          flexWrap="wrap"
          cursor="pointer"
          alignItems="center"
          onClick={onOpen}
        >
          <StackItem w={["calc(25% - 4px)"]} order={1} isTruncated>
            Invoice
          </StackItem>

          <StackItem
            w={["calc(25% - 4px)"]}
            order={[3, 3, 2]}
            textAlign={["right", "right", "left"]}
          >
            {invoice.invoiceCurrent}
          </StackItem>
          <StackItem
            w={["calc(25% - 4px)"]}
            order={[3, 3, 2]}
            textAlign={["right", "right", "left"]}
          >
            {invoice.currentInvoiceNumber}
          </StackItem>
          <StackItem w={["calc(25% - 4px)"]} order={[2, 2, 3]}>
            {invoice.totalInvoices}
          </StackItem>

          <Divider order={5} />
        </HStack>
      )}

      {invoice && isOpen && (
        <InvoiceModal
          isOpen={isOpen}
          onClose={closeModal}
          invoice={invoice}
          accountId={account.id}
        />
      )}
    </Box>
  );
};
