import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  ApiWorkflowInternal,
  UpdateApiWorkflowInternal,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { NumberInputControl } from "../../../components/form-helpers/NumberControl";
import { useShowToast } from "../../../hooks/showToast";

const WorkflowSchema = yup.object().shape({
  currentRequestNumber: yup.string().required("Current Request is required"),
});
export interface WorkflowModalProps {
  isOpen: boolean;
  onClose: () => void;
  workingWorkflow: ApiWorkflowInternal;
  accountId: string;
}

const initialValues: UpdateApiWorkflowInternal = {
  currentRequestNumber: undefined,
};
export const WorkflowModal: React.FC<WorkflowModalProps> = ({
  isOpen,
  onClose,
  workingWorkflow,
  accountId,
}) => {
  const { apiInternal } = useAuthentication();
  const showToast = useShowToast();

  const [workflow, setWorkflow] = useState<UpdateApiWorkflowInternal>();

  const handleOnClose = useCallback(() => {
    setWorkflow(initialValues);
    onClose();
  }, [onClose]);

  const handleOnSubmit = useCallback(
    async (values: UpdateApiWorkflowInternal) => {
      try {
        await apiInternal.updateNextRequestWorkflow(
          accountId,
          workingWorkflow.id,
          values as UpdateApiWorkflowInternal
        );
        showToast(
          "success",
          "Workflow current request was updated succesfully"
        );
        workingWorkflow.currentRequestNumber = values.currentRequestNumber
          ? values.currentRequestNumber
          : workingWorkflow.currentRequestNumber;
        handleOnClose();
      } catch {
        showToast("error", "Something happened while updating current request");
      }
    },
    [accountId, apiInternal, handleOnClose, workingWorkflow, showToast]
  );

  useEffect(() => {
    setWorkflow(workingWorkflow);
  }, [workingWorkflow, setWorkflow]);

  return (
    <>
      {workflow ? (
        <Modal isOpen={isOpen} onClose={handleOnClose} closeOnEsc={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{"Edit Workflow"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box w="100%">
                <Formik
                  initialValues={workflow}
                  validationSchema={WorkflowSchema}
                  onSubmit={handleOnSubmit}
                >
                  {({ values, isValid }) => {
                    return (
                      <Form>
                        <Grid templateColumns={"repeat(12, 1fr)"} gap={5}>
                          <GridItem colSpan={12}>
                            <NumberInputControl
                              name="currentRequestNumber"
                              label="Current Request"
                              value={
                                values.currentRequestNumber
                                  ? values.currentRequestNumber
                                  : null
                              }
                            />
                          </GridItem>
                          <GridItem colSpan={6}>
                            <Button onClick={handleOnClose}>Cancel</Button>
                          </GridItem>
                          <GridItem colSpan={6}>
                            <Button
                              float="right"
                              type="submit"
                              isDisabled={!isValid}
                            >
                              Save
                            </Button>
                          </GridItem>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};
