import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiInternalService } from "../../services/api-internal/internal-service";
import { InternalUser } from "../../services/api-internal/models/InternalUser";
import { usersInitHandlers } from "./thunks/user-init.thunk";

export interface UserListFilters {
  page: number;
  pageSize: number;
  total: number;
  search: string;
  accounts: string[];
  includeInactive: boolean;
}

export interface UserSliceState {
  users: InternalUser[];
  filters: UserListFilters;
  initialized: boolean;
}

export interface FindAccountsThunkParams {
  apiInternal: ApiInternalService;
}

const INITIAL_FILTERS = {
  page: 1,
  total: 0,
  search: "",
  pageSize: 20,
  accounts: [],
  includeInactive: false,
};

export const userSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    filters: INITIAL_FILTERS,
    initialized: false,
  } as UserSliceState,
  reducers: {
    updateFilters: (state, action: PayloadAction<Partial<UserListFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    unloadUsers: (state) => {
      state.users = [];
      state.initialized = false;
      state.filters = INITIAL_FILTERS;
    },

    updateUser: (state, action: PayloadAction<Partial<InternalUser>>) => {
      state.users = state.users.map((user) =>
        action.payload.id === user.id ? { ...user, ...action.payload } : user
      );
    },
  },

  extraReducers: (builder) => {
    usersInitHandlers(builder);
  },
});

export const { updateFilters, unloadUsers, updateUser } = userSlice.actions;

export default userSlice.reducer;
