import { SearchIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";

interface SearchBoxProps {
  searchPlaceholder: string;
  onInputChange: (value: string | undefined) => void;
  initialValue?: string;
  id?: string;
}

export const SearchBox: FC<SearchBoxProps> = ({
  searchPlaceholder,
  onInputChange,
  initialValue,
  id,
}) => {
  const searchBackground = useColorModeValue("white", "transparent");

  return (
    <InputGroup
      w="100%"
      borderRadius={6}
      alignSelf="flex-end"
      bgColor={searchBackground}
    >
      <InputLeftElement children={<SearchIcon color="gray.300" />} />
      <Input
        id={id}
        type="text"
        placeholder={searchPlaceholder}
        defaultValue={initialValue}
        onChange={(event) => onInputChange(event.target.value)}
      />
    </InputGroup>
  );
};
