import { Button, Divider, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { ApiOrganization } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { IoTrash } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { UserBadge } from "../../components/badges/UserBadge";
import { RootState, useThunkDispatch } from "../../store";
import { loadOrganizationUsers } from "../../store/organization/accounts/thunks/organization-users.thunk";

interface OrganizationUsersProps {
  organization: ApiOrganization;
  onClose?: () => void;
}

export const OrganizationUsersList: FC<OrganizationUsersProps> = ({
  onClose,
  organization,
}) => {
  const thunkDispatch = useThunkDispatch();
  const { apiInternal } = useAuthentication();
  const { organizationUsers } = useSelector(
    (state: RootState) => state.organizationAccountSlice
  );

  const handleOnDeactivateOrgUserRole = useCallback(
    (userId: string) => {
      apiInternal
        .deactivateOrganizationUserRole(organization.id, userId)
        .then(() => {
          thunkDispatch(
            loadOrganizationUsers({
              apiInternal,
              orgId: organization.id,
              options: organizationUsers.filters,
            })
          );
        });
    },
    [apiInternal, organization.id, organizationUsers.filters, thunkDispatch]
  );

  return (
    <Flex w="100%" flexDir="column" gap={4}>
      <Flex
        gap={4}
        justifyContent="space-between"
        flexDir={["column", "column", "row"]}
      >
        <Heading fontSize="lg">Organization User(s)</Heading>
      </Flex>
      {Object.values(organizationUsers.usersHash).length !== 0 ? (
        Object.values(organizationUsers.usersHash).map((orgUser) => (
          <Flex
            key={orgUser.id}
            alignItems="center"
            justifyContent="space-between"
          >
            <UserBadge value={orgUser} />
            <Icon
              as={IoTrash}
              color="gray.400"
              boxSize="1.2em"
              cursor="pointer"
              onClick={() => handleOnDeactivateOrgUserRole(orgUser.id)}
            />
          </Flex>
        ))
      ) : (
        <Text>No user roles for this organization</Text>
      )}

      <Divider />
      <Flex justifyContent="flex-end">
        <Button
          size="sm"
          colorScheme="blue"
          variant="outline"
          onClick={() => onClose && onClose()}
        >
          Close
        </Button>
      </Flex>
    </Flex>
  );
};
