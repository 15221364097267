import { Box, Button, Flex } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QuickFilterButton } from "../../components/buttons/QuickFilterButton";
import { SearchBox } from "../../components/inputs/Search";
import { RootState } from "../../store";
import { updateFilters } from "../../store/organization/organizations.slice";
import { debounce } from "../../utils/debounce";

interface OrganizationsFiltersFirstSectionProsps {
  onOpen: () => void;
  onClose: () => void;
}

export const OrganizationsFiltersFirstSection: FC<
  OrganizationsFiltersFirstSectionProsps
> = ({ onOpen, onClose }) => {
  const { filters } = useSelector((state: RootState) => state.organizations);
  const { includeInactive } = filters;

  const dispatch = useDispatch();
  const handleIncludeInactiveChange = useCallback(
    (value: boolean) => {
      dispatch(updateFilters({ includeInactive: value, page: 1 }));
    },
    [dispatch]
  );

  return (
    <Flex minW="100%" justifyContent="space-between">
      <Box w="50%" display="flex" alignItems="center">
        <QuickFilterButton
          label="Show Expired"
          isActive={!!includeInactive}
          onClick={() => handleIncludeInactiveChange(!includeInactive)}
        />
      </Box>

      <Button
        size="sm"
        onClick={onOpen}
        colorScheme="blue"
        display={["none", "none", "block"]}
      >
        Add Organization
      </Button>
    </Flex>
  );
};

export const OrganizationsFiltersSecondSection: FC = () => {
  const { filters } = useSelector((state: RootState) => state.organizations);
  const { search } = filters;

  const dispatch = useDispatch();

  const onSearchChange = useCallback(
    (value: string | undefined) => {
      dispatch(updateFilters({ search: value, page: 1 }));
    },
    [dispatch]
  );

  const debouncedOnSearchChange = debounce(onSearchChange, 400);

  return (
    <Flex w="full">
      <Box w={["full", "full", "360px"]}>
        <SearchBox
          initialValue={search}
          searchPlaceholder="Search organization"
          onInputChange={debouncedOnSearchChange}
        />
      </Box>
    </Flex>
  );
};
