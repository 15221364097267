import { Box } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import {
  AsyncSelect,
  MultiValue,
  MultiValueGenericProps,
} from "chakra-react-select";
import { FC, FocusEventHandler, useCallback, useMemo } from "react";
import { useShowToast } from "../../hooks/showToast";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponetProp,
} from "./select-overrides-types";

export interface UserAutocompleteProps {
  name?: string;
  value: ApiUserSummary[] | null;
  allowEmpty?: boolean;
  emptyText?: string;
  onChange: (value: ApiUserSummary[]) => void;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  isInvalid?: boolean;
  accountId?: string;
}

const CustomOptionComponent = createOptionComponent(UserBadge);

export function CustomMultiValueLabelComponent(
  props: MultiValueGenericProps<ApiUserSummary>
) {
  const { data, innerProps } = props;

  if (data.id && data.id === "null-user") {
    return <>{data.firstName}</>;
  }
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
}

export const UserMultipleAutocomplete: FC<UserAutocompleteProps> = ({
  onChange,
  onBlur,
  name,
  value: user,
  isDisabled,
  accountId,
}) => {
  const { apiInternal, apiClient } = useAuthentication();
  const showToast = useShowToast();

  const handleChange = useCallback(
    (newValue: MultiValue<ApiUserSummary>) => {
      onChange(newValue.map((item) => item));
    },
    [onChange]
  );

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      if (accountId) {
        apiClient
          .findAccountUsers(accountId, { search: inputValue })
          .then((response) => cb(response.data))
          .catch(() => {
            showToast("error", "Something went wrong loading users");
          });
      } else {
        apiInternal
          .findUsers({ search: inputValue })
          .then((response) => cb(response.data))
          .catch(() => {
            showToast("error", "Something went wrong loading users");
          });
      }
    },
    [accountId, apiClient, apiInternal, showToast]
  );

  const components = useMemo((): CustomSelectComponetProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiUserSummary>) =>
        CustomOptionComponent(props),
      MultiValueLabel: (props: any) => CustomMultiValueLabelComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      name={name}
      onBlur={onBlur}
      defaultOptions={true}
      value={user || null}
      cacheOptions={true}
      loadOptions={loadOptions}
      onChange={handleChange}
      isMulti={true}
      components={components}
      getOptionValue={(item: ApiUserSummary) => item.id}
      isDisabled={isDisabled}
    />
  );
};
