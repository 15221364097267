import {
  ApiClient,
  ApiDashboard,
  ApiProduct,
  ApiUser,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiInternalService } from "../services/api-internal/internal-service";
import { AccountsData } from "../services/api-internal/models/AccountData";

export interface AuthSliceState {
  currentUser: ApiUser;
  currentAccount: AccountsData;
  isInternalUser: boolean;
  products: ApiProduct[];
  dashboards: ApiDashboard[];
}

export interface InitAuthThunkParams {
  apiClient: ApiClient;
  apiInternal: ApiInternalService;
}

export const initAuth = createAsyncThunk(
  "auth/init",
  async ({ apiClient, apiInternal }: InitAuthThunkParams, thunkAPI) => {
    const [currentUser, isInternal, products, dashboards] = await Promise.all([
      apiClient.getCurrentUser(),
      apiInternal.isInternal(),
      apiInternal.findProducts(),
      apiInternal.getDashboards(),
    ]);

    return { currentUser, isInternal, products, dashboards };
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    currentUser: {},
    currentAccount: {},
    isInternalUser: false,
    products: [],
    dashboards: [],
  } as unknown as AuthSliceState,

  reducers: {
    setCurrentAccount: (state, action: PayloadAction<AccountsData>) => {
      const account = action.payload;
      state.currentAccount = account;
      localStorage.setItem(`${state.currentUser.id}:acct`, account.id);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(initAuth.fulfilled, (state, action) => {
      state.currentUser = action.payload.currentUser;
      state.isInternalUser = action.payload.isInternal.authorized;
      state.products = action.payload.products.data;
      state.dashboards = action.payload.dashboards;
    });
  },
});

export const { setCurrentAccount } = authSlice.actions;

export default authSlice.reducer;
