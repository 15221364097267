import { Button, Flex, IconButton, useColorModeValue } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

interface PagerProps {
  totalPages: number;
  dotsValue?: number;
  currentPage: number;
  neighbours?: number;
  onPageChange: (newPage: number) => void;
}

const DOTS = "···";

export const Pager: FC<PagerProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  dotsValue = 3,
  neighbours = 1,
}) => {
  const textColor = useColorModeValue("black", "white");
  const buttonBg = useColorModeValue("gray.100", "whiteAlpha.200");

  const getPagesRange = useCallback((from: number, to: number, step = 1) => {
    let index = from;
    const range = [];

    while (index <= to) {
      range.push(index);
      index += step;
    }

    return range;
  }, []);

  const pages = useMemo(() => {
    const activePage = currentPage;
    const pageNeighbours = neighbours;

    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = activePage - pageNeighbours;
      const rightBound = activePage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = getPagesRange(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftPages = startPage > 2;
      const rightpages = endPage < beforeLastPage;

      if (leftPages && !rightpages) {
        const extraPages = getPagesRange(
          startPage - singleSpillOffset,
          startPage - 1
        );
        pages = [DOTS, ...extraPages, ...pages];
      } else if (!leftPages && rightpages) {
        const extraPages = getPagesRange(
          endPage + 1,
          endPage + singleSpillOffset
        );
        pages = [...pages, ...extraPages, DOTS];
      } else if (leftPages && rightpages) {
        pages = [DOTS, ...pages, DOTS];
      }

      return [1, ...pages, totalPages];
    }

    return getPagesRange(1, totalPages);
  }, [currentPage, getPagesRange, neighbours, totalPages]);

  const handlePrevPag = useCallback(() => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange]);

  const handleNextPag = useCallback(() => {
    if (currentPage !== totalPages) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, onPageChange, totalPages]);

  const handleMoveLeftDashes = useCallback(() => {
    const newPage = currentPage - dotsValue;
    onPageChange(newPage < 1 ? 1 : newPage);
  }, [currentPage, dotsValue, onPageChange]);

  const handleMoveRightDashes = useCallback(() => {
    const newPage = currentPage + dotsValue;
    onPageChange(newPage > totalPages ? totalPages : newPage);
  }, [currentPage, dotsValue, onPageChange, totalPages]);

  const handleClickDashes = useCallback(
    (index: number) => {
      index === 1 ? handleMoveLeftDashes() : handleMoveRightDashes();
    },
    [handleMoveLeftDashes, handleMoveRightDashes]
  );

  const handleOnClickPage = useCallback(
    (page: string | number, index: number) => {
      page === DOTS ? handleClickDashes(index) : onPageChange(page as number);
    },
    [handleClickDashes, onPageChange]
  );

  return (
    <Flex flexDir="row" gap="4px" justifyContent="flex-end" p={2}>
      <IconButton
        icon={<MdChevronLeft />}
        aria-label="prev"
        size="sm"
        disabled={currentPage <= 1}
        onClick={handlePrevPag}
      />
      {pages.map((page, index) => {
        return (
          <Button
            size="sm"
            key={Math.random() + index}
            _hover={{ backgroundColor: "none" }}
            onClick={() => handleOnClickPage(page, index)}
            color={page === currentPage ? "white" : textColor}
            bgColor={page === currentPage ? "blue.500" : buttonBg}
          >
            {page}
          </Button>
        );
      })}
      <IconButton
        size="sm"
        aria-label="next"
        onClick={handleNextPag}
        icon={<MdChevronRight />}
        disabled={currentPage >= totalPages}
      />
    </Flex>
  );
};
