import { CopyIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, Tag, TagLabel, useToast } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";

export interface ApiUserSummarySession extends ApiUserSummary {
  isActiveSession?: boolean;
}

export interface UserBadgeProps {
  size?: string;
  hideName?: boolean;
  value: ApiUserSummarySession;
  includeAvatar?: boolean;
  textFontWeight?: string;
  backgroundColor?: string;
  includeCopyIcon?: boolean;
}

export const UserBadge: FC<UserBadgeProps> = ({
  value,
  hideName,
  size = "xs",
  textFontWeight,
  includeAvatar = true,
  includeCopyIcon = false,
}) => {
  const toast = useToast();

  const copyUserId = useCallback(() => {
    if (!value || !includeCopyIcon) {
      return;
    }
    navigator.clipboard.writeText(value.id);
    toast({
      duration: 1500,
      isClosable: true,
      position: "top",
      status: "info",
      title: "Copied user id",
    });
  }, [value, includeCopyIcon, toast]);

  return (
    <Tag size="lg" background="inherit" pl={0} pr={1} onClick={copyUserId}>
      {includeAvatar && (
        <Avatar
          size={size}
          name={`${value.firstName} ${value.lastName}`}
          src={value.profileImage}
          visibility={value.id === "null-user" ? "hidden" : "initial"}
          mr={2}
        >
          {value.isActiveSession !== undefined && (
            <AvatarBadge
              bg={value.isActiveSession ? "green.400" : "red.500"}
              boxSize="1.25em"
            />
          )}
        </Avatar>
      )}
      {value.id === "null-user" || !hideName ? (
        <TagLabel
          fontWeight={textFontWeight || "normal"}
          fontStyle={value.id === "null-user" ? "italic" : "initial"}
        >
          {value.firstName} {value.lastName}
        </TagLabel>
      ) : null}
      {includeCopyIcon && (
        <CopyIcon
          ml={1}
          w={3}
          h={3}
          color="gray"
          _hover={{ cursor: "pointer" }}
        />
      )}
    </Tag>
  );
};
