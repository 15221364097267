import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React, { FC, useCallback } from "react";
import {
  StyledDatePicker,
  StyledDatePickerProps,
} from "../inputs/StyledDatePicker";

type DatePickerControlProps = Omit<StyledDatePickerProps, "onChange"> & {
  label: string;
  name: string;
  format?: string;
  isReadOnly?: boolean;
  labelFontWeight?: string;
};

export const DatePickerControl: FC<DatePickerControlProps> = ({
  name,
  label,
  value,
  format,
  showTime,
  isDisabled,
  placeholder,
  isReadOnly = false,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField({
    name,
    value: value ? new Date(value).toISOString() : undefined,
    placeholder,
  });

  const handleOnChange = useCallback(
    (date: Date | null) => {
      helpers.setValue(date ? date.toISOString() : null);
      helpers.setTouched(true);
    },
    [helpers]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <StyledDatePicker
        showTime={showTime}
        value={field.value}
        onChange={handleOnChange}
        name={name}
        isDisabled={isDisabled}
        format={format}
        isReadOnly={isReadOnly}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
