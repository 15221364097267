import { SmallAddIcon } from "@chakra-ui/icons";
import { Icon, Tag, TagProps, useColorModeValue } from "@chakra-ui/react";
import { FC } from "react";

interface AdditionalNumberBadgeProps {
  number: number;
  tagProps?: TagProps;
}

export const AdditionalNumberBadge: FC<AdditionalNumberBadgeProps> = ({
  number,
  tagProps,
}) => {
  const badgeColor = useColorModeValue("blue.50", "whiteAlpha.300");
  return (
    <Tag borderRadius="full" bgColor={badgeColor} {...tagProps}>
      <Icon as={SmallAddIcon} />
      {number}
    </Tag>
  );
};
