import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Icon } from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  FC,
} from "react";
import DatePicker from "react-datepicker";
import { IoChevronDownOutline } from "react-icons/io5";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styled-date-picker.css";

export interface StyledDatePickerProps {
  name: string;
  format?: string;
  showTime?: boolean;
  isReadOnly: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  value: Date | string | null;
  onChange?: (date: Date | null) => void;
}

export const StyledDatePicker: FC<StyledDatePickerProps> = ({
  value,
  format,
  showTime,
  onChange,
  placeholder,
  isDisabled,
  isReadOnly,
  name,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const themeClass = useColorModeValue("light-theme", "dark-theme");
  const datepickerRef = useRef<ReactDatePicker>(null);

  const handleDateChange = useCallback(
    (newValue: Date) => {
      if (onChange) onChange(newValue);
      else setSelectedDate(newValue);
    },
    [onChange]
  );

  const setDatepickerFocus = useCallback(() => {
    if (isDisabled) {
      return;
    }
    datepickerRef.current?.setFocus();
  }, [isDisabled]);

  useEffect(() => {
    if (!value) {
      setSelectedDate(null);
      return;
    }

    if (typeof value === "string") {
      setSelectedDate(new Date(value));
      return;
    }

    setSelectedDate(value);
  }, [value]);

  const dateFormat = useMemo(() => {
    if (format) {
      return format;
    }
    return showTime ? "MMM-dd-yyyy h:mm aa" : "MMM-dd-yyyy";
  }, [format, showTime]);

  return (
    <Box className={themeClass} borderRadius={4}>
      <DatePicker
        placeholderText={placeholder || "Select a date"}
        selected={selectedDate}
        onChange={handleDateChange}
        timeIntervals={15}
        showTimeSelect={showTime === true}
        dateFormat={dateFormat}
        disabled={isDisabled}
        autoComplete="off"
        name={name}
        id={name}
        ref={datepickerRef}
        readOnly={isReadOnly}
      />
      <Icon
        as={IoChevronDownOutline}
        position="relative"
        float="right"
        mt={-25}
        mr={4}
        zIndex={0}
        onClick={setDatepickerFocus}
      />
    </Box>
  );
};
