import { CheckIcon } from "@chakra-ui/icons";
import {
  Center,
  createIcon,
  Flex,
  MenuIcon,
  RecursiveCSSObject,
  useColorModeValue,
  useStyles,
} from "@chakra-ui/react";
import {
  DropdownIndicatorProps,
  OptionProps,
  GroupBase,
} from "chakra-react-select";
import { Size, SizeProps, SxProps } from "chakra-react-select/dist/types/types";
import React from "react";
import { CustomSelectComponetProp } from "./select-overrides-types";

const ChevronDown = createIcon({
  displayName: "ChevronDownIcon",
  d: "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z",
});

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { innerProps, selectProps } = props;
  const { size } = selectProps;

  const { addon } = useStyles();
  const iconSizes: SizeProps = {
    sm: 4,
    md: 5,
    lg: 6,
  };
  const iconSize = iconSizes[size as Size];

  return (
    <Center
      {...innerProps}
      sx={{
        ...addon,
        h: "100%",
        borderRadius: 0,
        borderWidth: 0,
        cursor: "pointer",
        bg: "transparent",
      }}
    >
      <ChevronDown h={iconSize} w={iconSize} />
    </Center>
  );
};

export function createOptionComponent<T>(
  InnerComponent: React.FC<{
    value: T;
  }>
) {
  return ({
    innerRef,
    innerProps,
    data,
    children,
    isFocused,
    isDisabled,
    isSelected,
    selectProps: {
      size,
      isMulti,
      hideSelectedOptions,
      selectedOptionStyle,
      selectedOptionColor,
    },
  }: OptionProps<T, false, GroupBase<T>>) => {
    const { item } = useStyles();

    const paddings: SizeProps = {
      sm: "0.3rem 0.6rem",
      md: "0.4rem 0.8rem",
      lg: "0.5rem 1rem",
    };

    const selectedBg = useColorModeValue(
      `${selectedOptionColor}.500`,
      `${selectedOptionColor}.300`
    );
    const selectedColor = useColorModeValue("white", "black");

    // Don't create exta space for the checkmark if using a multi select with
    // options that dissapear when they're selected
    const showCheckIcon: boolean =
      selectedOptionStyle === "check" &&
      (!isMulti || hideSelectedOptions === false);

    const shouldHighlight: boolean =
      selectedOptionStyle === "color" && isSelected;

    return (
      <Flex
        role="button"
        sx={{
          ...item,
          alignItems: "center",
          w: "100%",
          textAlign: "start",
          fontSize: size,
          p: paddings[size as Size],
          bg:
            isFocused && item
              ? (item as RecursiveCSSObject<SxProps>)._focus?.bg
              : "transparent",
          ...(shouldHighlight && {
            bg: selectedBg,
            color: selectedColor,
            _active: { bg: selectedBg },
          }),
          ...(isDisabled && (item as RecursiveCSSObject<SxProps>)._disabled),
        }}
        ref={innerRef}
        {...innerProps}
      >
        {showCheckIcon && (
          <MenuIcon
            fontSize="0.8em"
            marginEnd="0.75rem"
            opacity={isSelected ? 1 : 0}
          >
            <CheckIcon />
          </MenuIcon>
        )}
        <InnerComponent value={data} />
      </Flex>
    );
  };
}

export const getCustomSelectComponents = (): CustomSelectComponetProp => {
  const defaultComponents = {
    DropdownIndicator: (props: DropdownIndicatorProps) =>
      DropdownIndicator(props),
    IndicatorSeparator: null,
  };
  return defaultComponents;
};
