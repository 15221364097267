import {
  Box,
  Flex,
  HStack,
  Link,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiAccount,
  ApiScheduledRequest,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { DateBadge } from "../../components/badges/DateBadge";
import { Pager } from "../../components/pagination/Pager";
const PAGE_SIZE = 20;

export const Schedules = ({ account }: { account: ApiAccount }) => {
  const { apiClient } = useAuthentication();
  const [schedules, setSchedules] = useState<ApiScheduledRequest[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const stackItemBgColor = useColorModeValue("gray.50", "whiteAlpha.200");
  const isDesktop = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  });

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    apiClient
      .findScheduledRequests(account.id, {
        page,
        pageSize: PAGE_SIZE,
      })
      .then((pagedUsers) => {
        setSchedules(pagedUsers.data);
        setTotal(pagedUsers.total);
      });
  }, [apiClient, account, page]);

  return (
    <Box>
      <SchedulesHeader />
      {schedules.map((scheduledRequest, index) => {
        return (
          <Stack
            spacing={0}
            flexWrap="wrap"
            flexDir="row"
            key={scheduledRequest.id}
            mt={index === 0 ? 4 : 0}
            p={isDesktop ? 4 : 2}
            bgColor={index % 2 !== 0 ? stackItemBgColor : "transparent"}
          >
            <StackItem
              p={2}
              maxW={["90%", "90%", "90%", "25%"]}
              minW={["90%", "90%", "90%", "25%"]}
            >
              <Text fontWeight="bold">{scheduledRequest.name}</Text>

              <>
                {scheduledRequest.requestsGenerated > 0 && (
                  <Text justifySelf="flex-start">
                    {scheduledRequest.requestsGenerated} Requests
                  </Text>
                )}
                <Link
                  as={RouterLink}
                  to={`../requests/${scheduledRequest.lastRequestKey}`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  justifySelf="flex-start"
                >
                  <Text>{scheduledRequest.lastRequestKey}</Text>
                </Link>
              </>

              {!isDesktop && (
                <Box w="100%" fontWeight="500" mt={4}>
                  <Text isTruncated={true}>
                    {scheduledRequest.reason ? (
                      scheduledRequest.reason.name
                    ) : (
                      <i>no reason</i>
                    )}
                    {", "}
                    {scheduledRequest.reportingCategory ? (
                      scheduledRequest.reportingCategory.name
                    ) : (
                      <i>no category</i>
                    )}
                  </Text>
                </Box>
              )}
            </StackItem>

            {isDesktop && (
              <>
                <StackItem w="20%" p={2}>
                  {scheduledRequest.reportingCategory ? (
                    <Text>{scheduledRequest.reportingCategory.name}</Text>
                  ) : (
                    <i>no category</i>
                  )}
                </StackItem>

                <StackItem w="20%" p={2}>
                  {scheduledRequest.reason ? (
                    scheduledRequest.reason.name
                  ) : (
                    <i>no reason</i>
                  )}
                </StackItem>

                <StackItem w="20%" p={2}>
                  <Flex>
                    <Text>{scheduledRequest.location.name}</Text>
                  </Flex>
                </StackItem>
                <StackItem w="15%" p={2}>
                  <Box cursor="pointer">
                    <DateBadge
                      date={scheduledRequest.schedules[0]?.nextRuns.find(
                        (nextRun) => new Date(nextRun) > new Date()
                      )}
                    />
                  </Box>
                </StackItem>
              </>
            )}
          </Stack>
        );
      })}
      <Pager
        currentPage={page}
        totalPages={Math.ceil(total / PAGE_SIZE)}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

const SchedulesHeader: FC = () => {
  const headerBackground = useColorModeValue("gray.100", "whiteAlpha.200");
  return (
    <HStack
      p={2}
      w="100%"
      gap="4px"
      spacing={0}
      flexDir="row"
      flexWrap="wrap"
      bgColor={headerBackground}
      alignItems="center"
      d={["none", "none", "flex"]}
    >
      <StackItem w="25%" fontWeight="bold">
        Name
      </StackItem>
      <StackItem w="20%" fontWeight="bold">
        Category
      </StackItem>
      <StackItem w={["calc(20% - 6px)"]} fontWeight="bold">
        Reason
      </StackItem>
      <StackItem w={["calc(20% - 6px)"]} fontWeight="bold">
        Location
      </StackItem>
      <StackItem w={["calc(15% - 6px)"]} fontWeight="bold">
        Next Run
      </StackItem>
    </HStack>
  );
};
