import { Alert, AlertIcon, Button, Flex, VStack } from "@chakra-ui/react";
import { FC } from "react";

interface MergeUserConfirmationProps {
  onClose: () => void;
  onMerge: () => void;
}

export const MergeUserConfirmation: FC<MergeUserConfirmationProps> = ({
  onMerge,
  onClose,
}) => {
  return (
    <VStack gap={4}>
      <Alert status="warning">
        <AlertIcon />
        The selected user(s) will be merged in the target user, this action is
        not reversible, are you sure to continue?
      </Alert>

      <Flex w="100%" justifyContent="space-between">
        <Button onClick={onClose} variant="ghost">
          Cancel
        </Button>
        <Button onClick={onMerge} colorScheme="blue">
          Continue
        </Button>
      </Flex>
    </VStack>
  );
};
