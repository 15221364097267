import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  InputGroup,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { useField, useFormikContext } from "formik";
import React, { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import { useAuthentication } from "../auth/AuthProvider";

export interface AccountFileControlProps {
  label?: string;
  name: string;
  value: string | null;
  placeholder?: string;
  helperText?: string;
  labelFontWeight?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  accountId: string;
}

export const AccountFileControl: FC<AccountFileControlProps> = ({
  name,
  value,
  placeholder,
  label,
  helperText,
  isDisabled,
  isReadOnly,
  accountId,
}) => {
  const fileRef = useRef(null);

  const { apiClient } = useAuthentication();
  const { submitCount } = useFormikContext();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState<string | null>(null);
  // the array is positional so we have to destructure it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField({
    name,
    value: value === null ? undefined : value,
    placeholder,
  });

  const browseFiles = useCallback(() => {
    console.log("Browse files");
    //@ts-ignore
    console.log();

    //@ts-ignore
    fileRef.current.click();
  }, [fileRef]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        setFileName(null);
        setIsUploading(false);
        setProgress(0);
        helpers.setValue("");
        return;
      }
      const rawFile = event.target.files[0];
      setFileName(rawFile.name);
      setIsUploading(true);
      setProgress(0);
      console.log("Uploading", rawFile.name);
      apiClient.createUpload(accountId).then(async (upload) => {
        await axios.put(upload.url, rawFile, {
          headers: { "Content-Type": rawFile.type },
          onUploadProgress: (progressEvent) => {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        });
        // finalize upload state
        setIsUploading(false);
        setProgress(100);
        helpers.setValue({ name: rawFile.name, uploadId: upload.id });
      });
    },
    [apiClient, accountId, helpers]
  );

  const placeholderColor = useColorModeValue(
    "whiteAlpha.400",
    "whiteAlpha.600"
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && (
        <FormLabel htmlFor={name}>
          {label}
          {placeholder && (
            <Text
              fontSize="sm"
              color={placeholderColor}
              display={"inline-block"}
              pl={4}
            >
              {placeholder}
            </Text>
          )}
        </FormLabel>
      )}

      <InputGroup>
        <input
          id={name}
          type="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={handleOnChange}
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <HStack gap={4}>
          {fileName && (
            <HStack>
              {progress > 0 && (
                <CircularProgress
                  value={progress}
                  color={progress < 100 ? undefined : "green"}
                  size="1.4em"
                >
                  <CircularProgressLabel>
                    {progress < 100 ? (
                      `${progress}%`
                    ) : (
                      <CheckIcon color="green" fontSize="md" />
                    )}
                  </CircularProgressLabel>
                </CircularProgress>
              )}
              {fileName && <Text color="">{fileName}</Text>}
            </HStack>
          )}
          <Button
            size="sm"
            variant="outline"
            onClick={browseFiles}
            disabled={isUploading}
          >
            {fileName ? "Change File" : "Select File"}
          </Button>
        </HStack>
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
