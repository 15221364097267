import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ApiAccountSubscription } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik } from "formik";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { NumberInputControl } from "../../../components/form-helpers/NumberControl";
import { ProductSelectControl } from "../../../components/form-helpers/ProductSelectControl";
import { SubscriptionControl } from "../../../components/form-helpers/SubscriptionTypeControl";
import { useThunkDispatch } from "../../../store";
import { addAccountSubscription } from "../../../store/account/thunks/account-add-subscription.thunk";
import { updateAccountSubscription } from "../../../store/account/thunks/account-update-subscription.thunk";

const SubscriptionSchema = yup.object().shape({
  product: yup
    .object()
    .shape({ name: yup.string().max(255, "Field is too long").required() }),
  start: yup.string().required(),
  expires: yup
    .string()
    .typeError("Must be a date")
    .required("Expires is required"),
  pricing: yup.object().shape({
    amount: yup
      .number()
      .typeError("Must be a number")
      .required("Amount is required"),
    type: yup.string().required(),
  }),
});

export interface SubscriptionFormProps {
  subscription: ApiAccountSubscription;
  closeModal: () => void;
}

export const SubscriptionForm: FC<SubscriptionFormProps> = ({
  subscription,
  closeModal,
}) => {
  const params = useParams();
  const thunkDispatch = useThunkDispatch();
  const toast = useToast();
  const { apiInternal } = useAuthentication();

  const onSaveSubscription = useCallback(
    async (values) => {
      const { acctId } = params;
      if (!acctId) {
        return;
      }
      const formatedValue = {
        ...values,
        start: new Date(values.start).toISOString(),
        expires: new Date(values.expires).toISOString(),
      };

      const action = subscription.id
        ? updateAccountSubscription({
            apiInternal,
            acctId,
            subId: subscription.id,
            payload: formatedValue,
          })
        : addAccountSubscription({
            apiInternal,
            acctId,
            payload: formatedValue,
          });

      thunkDispatch(action)
        .then(unwrapResult)
        .then(() =>
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "success",
            title: "Subscription updated successfully",
          })
        )
        .catch(() =>
          toast({
            duration: 1500,
            isClosable: true,
            position: "top",
            status: "error",
            title: "Error saving subscription",
          })
        )
        .finally(() => {
          closeModal();
        });
    },
    [params, thunkDispatch, apiInternal, subscription.id, toast, closeModal]
  );

  return (
    <Formik
      initialValues={subscription}
      onSubmit={onSaveSubscription}
      validationSchema={SubscriptionSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Grid gap={4} templateColumns="repeat(6,1fr)">
            <GridItem colSpan={6}>
              <Flex w="full">
                <Box w="70%">
                  <ProductSelectControl
                    name="product.name"
                    label="Product"
                    placeholder="Select product"
                    value={values.product.name || undefined}
                    isReadOnly={subscription.id ? true : false}
                  />
                </Box>
                <Flex
                  pt={6}
                  gap={2}
                  flex={1}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Text fontWeight="semibold">Is Trial?</Text>
                  <Checkbox
                    isChecked={values.isTrial}
                    onChange={() => setFieldValue("isTrial", !values.isTrial)}
                  />
                </Flex>
              </Flex>
            </GridItem>

            <GridItem colSpan={6}>
              <DatePickerControl
                isReadOnly
                name="start"
                label="Start"
                value={values.start}
                format="yyyy-MMM-dd 'at' HH:mm:ss"
              />
            </GridItem>

            <GridItem colSpan={6}>
              <DatePickerControl
                isReadOnly={false}
                name="expires"
                label="Expires"
                value={values.expires}
                format="yyyy-MMM-dd 'at' HH:mm:ss"
              />
            </GridItem>

            <GridItem colSpan={3}>
              <NumberInputControl
                label="Amount"
                placeholder="e.g. "
                name="pricing.amount"
                value={values.pricing.amount}
              />
            </GridItem>

            <GridItem colSpan={3}>
              <SubscriptionControl
                label="Type"
                name="pricing.type"
                value={values.pricing.type}
              />
            </GridItem>

            <GridItem colSpan={6}>
              <Divider />
            </GridItem>

            <GridItem
              my={3}
              gap={2}
              colSpan={6}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                size="sm"
                variant="outline"
                onClick={closeModal}
                colorScheme="blue"
              >
                Cancel
              </Button>
              <Button colorScheme="blue" type="submit" size="sm">
                Save
              </Button>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
