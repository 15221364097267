import { useCallback, useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";
import { useAuthentication } from "../components/auth/AuthProvider";

export interface UseWebSocketOptions {
  namespace: string;
}

// TODO: ideally this is powered by a context provider
// that way we can have a single connection for the entire app
export function useWebSocket({ namespace }: UseWebSocketOptions) {
  const { token } = useAuthentication();
  const [isConnected, setIsConnected] = useState(false);

  const socket = useMemo(() => {
    if (!token.accessToken) {
      return null;
    }
    const URL = process.env.REACT_APP_API_INTERNAL || "";

    console.log("Connecting to", URL, namespace);

    const socket = io(URL + `/${namespace}`, {
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: 120,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,

      multiplex: false,
      closeOnBeforeunload: true,
      auth: {
        token: token.accessToken,
      },
      upgrade: false,
      transports: ["websocket"],
    });

    // sock;

    // socket.of(namespace).on("connect", () => {
    //   console.log("Connected to namespace", namespace);
    // });
    return socket;
  }, [token.accessToken, namespace]);

  const connect = useCallback(() => {
    if (!socket) {
      return;
    }
    socket.connect();
  }, [socket]);

  const disconnect = useCallback(() => {
    if (!socket) {
      return;
    }
    socket.disconnect();
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const onConnect = () => {
      console.log("Connected");
      setIsConnected(true);
    };

    const onDisconnect = () => {
      console.log("Disconnected");
      setIsConnected(false);
    };

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      if (!socket) {
        return;
      }
      socket.disconnect();
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, [socket]);

  return { isConnected, connect, disconnect, socket };
}
