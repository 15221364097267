import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { ApiFindAccountsOptions } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageHeader } from "../../components/account-header/PageHeader";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { useQueryStringFilter } from "../../hooks/useQueryStringFilter";
import { useThunkDispatch } from "../../store";
import {
  INITIAL_FILTERS_ACCOUNT,
  unloadAccounts,
} from "../../store/account/accounts.slice";
import { loadAccounts } from "../../store/account/thunks/account-load.thunk";
import { debounce } from "../../utils/debounce";
import { AccountCommonFilters, AccountQuickFilters } from "./AccountsFilters";
import { AccountList } from "./AccountsList";

export const Accounts: FC = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { apiInternal } = useAuthentication();

  const {
    filterInitialized,
    filter: filters,
    updateQueryString: updateFilters,
  } = useQueryStringFilter({
    defaultValue: INITIAL_FILTERS_ACCOUNT,
  });

  const [value, setValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    filters.search && !value && setValue(filters.search);
  }, [filters.search, value]);

  useEffect(() => {
    if (!filterInitialized) {
      return;
    }
    thunkDispatch(loadAccounts({ apiInternal, filters }));
  }, [filters, thunkDispatch, apiInternal, filterInitialized]);

  useEffect(() => {
    return () => {
      dispatch(unloadAccounts());
    };
  }, [dispatch]);

  const onSearchChange = useCallback(
    (search?: string) => {
      updateFilters({ ...filters, search, page: 1 });
    },
    [updateFilters, filters]
  );

  const debouncedOnSearchChange = debounce(onSearchChange, 400);

  const updateFilter = useCallback(
    (newValue: Partial<ApiFindAccountsOptions>) => {
      updateFilters({
        ...filters,
        ...newValue,
        page: newValue.page || 1,
      });
    },
    [filters, updateFilters]
  );

  return (
    <Flex w="100%" flexDir="column" gap={4}>
      <PageHeader
        pageTitle="Accounts"
        secondSectionComponent={
          <AccountCommonFilters
            debouncedOnSearchChange={debouncedOnSearchChange}
            filters={filters}
            updateFilter={updateFilter}
          />
        }
        firstSectionComponent={
          <AccountQuickFilters filters={filters} updateFilter={updateFilter} />
        }
        firstSectionInCollpase={isMobile}
        secondSectionInModal={isMobile}
      />

      <AccountList filters={filters} updateFilters={updateFilter} />
    </Flex>
  );
};
