import { Box, Flex } from "@chakra-ui/react";
import { ApiFindAccountsOptions } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { QuickFilterButton } from "../../components/buttons/QuickFilterButton";
import { SearchBox } from "../../components/inputs/Search";
import { CountryFilter } from "./CountryFilter";
import { DaysToExpireFilter } from "./DaysToExpireFilter";
import { StateFilter } from "./StateFilter";

interface AccountFiltersProps {
  filters: ApiFindAccountsOptions;
  updateFilter: (values: Partial<ApiFindAccountsOptions>) => void;
  debouncedOnSearchChange: (search?: string) => void;
}

export const AccountCommonFilters: FC<AccountFiltersProps> = ({
  filters,
  updateFilter,
  debouncedOnSearchChange,
}) => {
  return (
    <Flex gap={4} flexDir={["column", "column", "row"]} w="full">
      <Box w={["full", "full", "300px"]}>
        <SearchBox
          id="accountSearch"
          initialValue={filters.search}
          searchPlaceholder="Search by Name or State"
          onInputChange={debouncedOnSearchChange}
        />
      </Box>

      <Box w={["full", "full", "260px"]}>
        <CountryFilter
          value={filters.country as string[]}
          onChange={(value: string[]) =>
            updateFilter({ country: value, page: 1 })
          }
        />
      </Box>

      <Box w={["full", "full", "260px"]}>
        <StateFilter
          value={filters.state as string[]}
          onChange={(value: string[]) =>
            updateFilter({ state: value, page: 1 })
          }
          countries={
            filters.country
              ? typeof filters.country === "string"
                ? [filters.country]
                : [...filters.country]
              : undefined
          }
        />
      </Box>

      <Box w={["full", "full", "160px"]}>
        <DaysToExpireFilter
          value={filters.daysToExpire}
          onChange={(value?: number) => updateFilter({ daysToExpire: value })}
        />
      </Box>
    </Flex>
  );
};

interface AccountsQuickFiltersProps {
  filters: ApiFindAccountsOptions;
  updateFilter: (values: Partial<ApiFindAccountsOptions>) => void;
}
export const AccountQuickFilters: FC<AccountsQuickFiltersProps> = ({
  filters,
  updateFilter,
}) => {
  return (
    <Flex gap={2} flex={1} flexWrap="wrap" id="quick-filters">
      <QuickFilterButton
        label="Show Expired"
        isActive={!!filters.showExpired}
        onClick={() => updateFilter({ showExpired: !filters.showExpired })}
      />

      <QuickFilterButton
        label="Only Trial"
        isActive={filters.isTrial === true}
        onClick={() => {
          const value = filters.isTrial ? undefined : true;
          updateFilter({ isTrial: value });
        }}
      />

      <QuickFilterButton
        label="Only Users"
        isActive={filters.isTrial === false}
        onClick={() => {
          const value =
            filters.isTrial === true || filters.isTrial === undefined
              ? false
              : undefined;
          updateFilter({ isTrial: value });
        }}
      />

      <QuickFilterButton
        label="Include Inactive"
        isActive={filters.includeInactive === true}
        onClick={() => {
          updateFilter({ includeInactive: !filters.includeInactive });
        }}
      />
    </Flex>
  );
};
