import { Alert, AlertIcon, Button, Flex, VStack } from "@chakra-ui/react";
import { FC } from "react";

interface LogoutUserConfirmationProps {
  onClose: () => void;
  onLogout: () => void;
}

export const LogoutUserConfirmation: FC<LogoutUserConfirmationProps> = ({
  onLogout,
  onClose,
}) => {
  return (
    <VStack gap={4}>
      <Alert status="warning">
        <AlertIcon />
        The current user will be logged out, are you sure to continue?
      </Alert>

      <Flex w="100%" justifyContent="space-between">
        <Button onClick={onClose} variant="ghost">
          Cancel
        </Button>
        <Button onClick={onLogout} colorScheme="blue">
          Continue
        </Button>
      </Flex>
    </VStack>
  );
};
