import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Country, ICountry } from "country-state-city";
import FuzzySearch from "fuzzy-search";
import { useCallback, useMemo } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { AdditionalNumberBadge } from "../../components/badges/AdditionalNumberBadge";

export interface StateFilterProps {
  value: (string | string)[];
  onChange: (value: string[]) => void;
}

type ValueLabel = {
  value: string;
  label: string;
};

const ContentRenderer = ({ state }: SelectRenderer<ValueLabel>) => {
  const textColor = useColorModeValue("gray.600", "white");
  return (
    <Box p={1} d="flex" w="max-content" alignItems="center">
      {state.values.length === 0 && "Country"}
      {state.values.length === 1 && (
        <Box color={textColor}>
          <Text isTruncated>{state.values[0].label}</Text>
        </Box>
      )}
      {state.values.length > 1 && (
        <>
          <Text as="span" mr={1} color={textColor}>
            {state.values[0].label}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ValueLabel>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["label"], {
        sort: true,
      }),
    [props.options]
  );

  const items = useMemo(() => {
    return !state.search ? props.options : fuzzySearch.search(state.search);
  }, [state.search, props.options, fuzzySearch]);

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box backgroundColor={bgColor} p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search States"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.value}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.label}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const CountryFilter = ({ value, onChange }: StateFilterProps) => {
  const themeClass = useColorModeValue(
    "react-dropdown-light-theme",
    "react-dropdown-dark-theme"
  );

  const options = (
    [
      Country.getCountryByCode("US"),
      Country.getCountryByCode("CA"),
    ] as ICountry[]
  ).map((country: ICountry) => ({
    value: country.isoCode,
    label: `${country.isoCode} - ${country.name}`,
  }));

  const internalValues = useMemo(() => {
    return value && value.length > 0
      ? options.filter((x) => value.includes(x.value))
      : [];
  }, [value, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange]
  );

  return (
    <Select
      multi
      options={options}
      className={themeClass}
      values={internalValues}
      onChange={handleChange}
      searchable={true}
      searchBy="label"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      contentRenderer={(props) => ContentRenderer({ ...props })}
      dropdownRenderer={DropdownRenderer}
      dropdownHeight="350px"
    />
  );
};
