import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "../../components/account-header/PageHeader";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../store";
import { initUsers } from "../../store/user/thunks/user-init.thunk";
import { unloadUsers } from "../../store/user/users.slice";
import {
  FirstSectionUsersListFilters,
  SecondSectionUsersListFilters,
} from "./filters/UsersListFilters";
import { UserList } from "./UsersList";

export const Users: FC = () => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { apiInternal } = useAuthentication();

  const { filters } = useSelector((state: RootState) => state.users);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  useEffect(() => {
    thunkDispatch(initUsers({ apiInternal }));
  }, [
    apiInternal,
    thunkDispatch,
    filters.includeInactive,
    filters.page,
    filters.search,
    filters.accounts,
  ]);

  useEffect(() => {
    return () => {
      dispatch(unloadUsers());
    };
  }, [dispatch]);

  return (
    <Flex w="100%" flexDir="row" flexWrap="wrap">
      <PageHeader
        pageTitle="Users"
        firstSectionComponent={<FirstSectionUsersListFilters />}
        secondSectionComponent={<SecondSectionUsersListFilters />}
        firstSectionInCollpase={isMobile}
        secondSectionInModal={isMobile}
      />

      <UserList />
    </Flex>
  );
};
