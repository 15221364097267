import { Link } from "@chakra-ui/react";
import { FC } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

interface CustomLinkProps {
  to: string;
  children: React.ReactNode;
}

export const CustomLink: FC<CustomLinkProps> = ({ children, to }) => {
  return (
    <Link
      w="100%"
      to={to}
      as={ReactRouterLink}
      _hover={{ textDecoration: "none" }}
    >
      {children}
    </Link>
  );
};
