import Resizer from "react-image-file-resizer";

const resizeImage = (
  file: File,
  maxWidth: number,
  maxHeight: number,
  outputFormat: "JPEG" | "PNG" | "WEBP" = "PNG"
): Promise<File> => {
  const fileExtension =
    outputFormat === "JPEG" ? "jpg" : outputFormat.toLocaleLowerCase();
  const mimeType = `image/${outputFormat.toLowerCase()}`;

  return new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        outputFormat,
        100,
        0,
        async (uri: any) => {
          const resultFile = uri as Blob;
          const parts = file.name.split(".");
          parts[parts.length - 1] = fileExtension;
          resolve(new File([resultFile], parts.join("."), { type: mimeType }));
        },
        "blob"
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const useImageResizer = () => {
  return { resizeImage };
};
