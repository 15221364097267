import { Button } from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { MouseEvent, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  deactivateReport,
  loadReports,
  reactivateReport,
} from "../../../store/account/account-reports.slice";

type ReportActionsProps = {
  reportId: string;
  active: boolean;
};

export const ReportActions = ({ reportId, active }: ReportActionsProps) => {
  const showToast = useShowToast();
  const { apiInternal } = useAuthentication();
  const account = useSelector((x: RootState) => x.accounts.workingAccount);
  const thunkDispatch = useThunkDispatch();
  const [actionIsLoading, setActionIsLoading] = useState(false);

  const handleDeactivateReport = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (account) {
        setActionIsLoading(true);

        thunkDispatch(
          deactivateReport({
            accountId: account.id,
            apiInternal,
            reportId,
          })
        )
          .then(unwrapResult)
          .then(() => {
            thunkDispatch(loadReports({ apiInternal, filters: {} }));
            setActionIsLoading(false);

            showToast("success", "Report was deactivated successfully");
          })
          .catch(() => {
            setActionIsLoading(false);

            showToast("error", "something went wrong deactivating a Report");
          });
      }
    },
    [reportId, account, apiInternal, showToast, thunkDispatch]
  );

  const handleReactivateReport = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (account) {
        setActionIsLoading(true);
        thunkDispatch(
          reactivateReport({
            accountId: account.id,
            apiInternal,
            reportId,
          })
        )
          .then(unwrapResult)
          .then(() => {
            thunkDispatch(loadReports({ apiInternal, filters: {} }));
            setActionIsLoading(false);
            showToast("success", "Report was reactivated successfully");
          })
          .catch(() => {
            setActionIsLoading(false);
            showToast("error", "something went wrong reactivating a Report");
          });
      }
    },
    [reportId, account, apiInternal, showToast, thunkDispatch]
  );

  return (
    <>
      {active ? (
        <Button
          size="xs"
          variant="outline"
          colorScheme="red"
          onClick={handleDeactivateReport}
          isLoading={actionIsLoading}
        >
          Deactivate
        </Button>
      ) : (
        <Button
          size="xs"
          variant="solid"
          colorScheme="blue"
          onClick={handleReactivateReport}
          isLoading={actionIsLoading}
        >
          Reactivate
        </Button>
      )}
    </>
  );
};
