import { Grid, GridItem } from "@chakra-ui/react";
import axios from "axios";
import { Formik, FormikProps } from "formik";
import React, { FC, MutableRefObject, useCallback } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DashboardControl } from "../../../components/form-helpers/DashboardControl";
import { ProductSelectControl } from "../../../components/form-helpers/ProductSelectControlAll";
import { useShowToast } from "../../../hooks/showToast";

export interface FormValues {
  product: string;
  dashboard: string;
}

export interface ReportFormProps {
  formRef: MutableRefObject<FormikProps<FormValues> | null>;
  accountId: string;
}
export const ReportForm: FC<ReportFormProps> = ({ formRef, accountId }) => {
  const initialValues: FormValues = {
    product: "",
    dashboard: "",
  };

  const { apiInternal } = useAuthentication();
  const showToast = useShowToast();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await apiInternal.createReport(accountId, {
          dashboardId: values.dashboard,
          productId: values.product,
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 409) {
            showToast("error", "Duplicated report found");
          } else {
            showToast("error", "An error happened");
          }
        } else showToast("error", "An error happened");
      }
    },
    [apiInternal, accountId, showToast]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ values }) => (
        <form>
          <Grid>
            <GridItem>
              <ProductSelectControl
                name="product"
                label="Product"
                placeholder="Select the product to add"
                value={values.product || undefined}
              />
              <DashboardControl
                label="Luzmo dashboard"
                name="dashboard"
                value={values.dashboard || undefined}
              ></DashboardControl>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};
