import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../..";
import {
  FindOrganizationAccountThunkParams,
  OrganizationSliceState,
} from "../organizations-accounts.slice";

export const initOrganizationsAccountsToAdd = createAsyncThunk(
  "organizations-accounts/accounts-to-add/init",
  async ({ apiInternal }: FindOrganizationAccountThunkParams, thunkAPI) => {
    const { workingOrganization, filtersAccountsToAdd } = (
      thunkAPI.getState() as RootState
    ).organizationAccountSlice;
    if (!workingOrganization?.id) return Promise.reject();
    const accounts = await apiInternal.findAccounts({
      ...filtersAccountsToAdd,
      excludeOrgAccounts: true,
    });
    return { accounts };
  }
);

export const organizationsAccountsToAddInitHandlers = (
  builder: ActionReducerMapBuilder<OrganizationSliceState>
) => {
  builder.addCase(initOrganizationsAccountsToAdd.fulfilled, (state, action) => {
    const {
      accounts: { data, options, total },
    } = action.payload;
    state.accountsToAdd = data;
    state.filtersAccountsToAdd.page = options.page || 1;
    state.filtersAccountsToAdd.total = total;
  });
};
