import { ApiAccountSubscription } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiInternalService } from "../../../services/api-internal/internal-service";
import { AccountSliceState } from "../accounts.slice";

export interface UpdateAccountSubscriptionProps {
  apiInternal: ApiInternalService;
  acctId: string;
  subId: string;
  payload: Partial<ApiAccountSubscription>;
}

export const updateAccountSubscription = createAsyncThunk(
  "accounts/update-subscription",
  async (
    { apiInternal, acctId, subId, payload }: UpdateAccountSubscriptionProps,
    thunkAPI
  ) => {
    const response = await apiInternal.updateSubscription(
      acctId,
      subId,
      payload
    );
    return { subId, response };
  }
);

export const updateAccountSubscriptionHandlers = (
  builder: ActionReducerMapBuilder<AccountSliceState>
) => {
  builder.addCase(updateAccountSubscription.fulfilled, (state, action) => {
    const subIndex = state.workingAccount?.subscriptions.findIndex(
      (sub) => sub.id === action.payload.subId
    );

    if (subIndex !== undefined && subIndex !== -1 && state.workingAccount) {
      state.workingAccount.subscriptions[subIndex] = action.payload.response;
    }
  });
};
