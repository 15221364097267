import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiAccountSubscription,
  ApiProduct,
} from "@operations-hero/lib-api-client";
import { addDays, format } from "date-fns";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UseTableCellProps } from "react-table";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/data-table/DataTable";
import { RootState } from "../../../store";
import { formatCurrency } from "../../../utils/formatCurrency";
import { AccountFormProps } from "../AccountForm";
import { SubscriptionActions } from "./SubscriptionActions";
import { SubscriptionModal } from "./SubscriptionModal";

export const SubscriptionList: FC<
  AccountFormProps & { products: ApiProduct[] }
> = ({ account, products }) => {
  const subscriptions = useSelector(
    (x: RootState) => x.accounts.workingAccount!.subscriptions
  );

  const [subscription, setSubscription] =
    useState<ApiAccountSubscription | null>(null);

  const tableHeaderBg = useColorModeValue("blue.50", "transparent");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const canAddProduct = useMemo(() => {
    const subscribedProducts = subscriptions.map((s) => s.product.name);
    return products.some((p) => !subscribedProducts.includes(p.name));
  }, [subscriptions, products]);

  const onClickSubscription = useCallback(
    (sub: ApiAccountSubscription) => {
      setSubscription(sub);
      onOpen();
    },
    [onOpen]
  );

  const onNewSubscription = useCallback(() => {
    const productNames = account.subscriptions.map((s) => s.product.name);
    const firstAvailableProduct = products.find(
      (x) => !productNames.includes(x.name)
    );

    if (!firstAvailableProduct) {
      return;
    }

    setSubscription({
      id: "",
      product: firstAvailableProduct,
      start: new Date().toISOString(),
      expires: addDays(new Date(), 30).toISOString(),
      pricing: {
        amount: 0.0,
        currency: "USD",
        type: "yearly",
      },
      isTrial: false,
      active: true,
    });
    onOpen();
  }, [onOpen, account, products]);

  const closeModal = useCallback(() => {
    setSubscription(null);
    onClose();
  }, [onClose]);

  const dateFormat = useBreakpointValue({
    base: "yyyy-MM-dd",
    sm: "yyyy-MM-dd",
    md: "yyyy-MMM-dd 'at' HH:mm",
  });

  const columns = useMemo<DataTableColumn<ApiAccountSubscription>[]>(
    () => [
      {
        Header: "Name",
        Cell: (data: UseTableCellProps<ApiAccountSubscription>) => {
          return (
            <Text isTruncated fontWeight="semibold">
              {data.row.original.product.name}
            </Text>
          );
        },
      },
      {
        Header: "Start",
        Cell: (data: UseTableCellProps<ApiAccountSubscription>) => {
          return (
            <Text isTruncated>
              {format(new Date(data.row.original.start), `${dateFormat}`)}
            </Text>
          );
        },
      },
      {
        Header: "Expires",
        Cell: (data: UseTableCellProps<ApiAccountSubscription>) => {
          return (
            <Text isTruncated>
              {format(new Date(data.row.original.expires), `${dateFormat}`)}
            </Text>
          );
        },
      },
      {
        Header: "Price",
        Cell: (data: UseTableCellProps<ApiAccountSubscription>) => (
          <Text>{formatCurrency(data.row.original.pricing.amount)}</Text>
        ),
      },
      {
        Header: "Trial",
        Cell: (data: UseTableCellProps<ApiAccountSubscription>) => (
          <Text>{data.row.original.isTrial ? "true" : "false"}</Text>
        ),
      },
      {
        Header: "Actions",
        maxWidth: 100,
        Cell: (data: UseTableCellProps<ApiAccountSubscription>) => (
          <Flex justifyContent="flex-end">
            <SubscriptionActions
              active={data.row.original.active}
              subscriptionId={data.row.original.id}
            />
          </Flex>
        ),
      },
    ],
    [dateFormat]
  );

  return (
    <Box>
      <HStack py={4} justifyContent="space-between">
        <Heading size="lg">Subscriptions</Heading>

        {canAddProduct && (
          <Button size="sm" onClick={onNewSubscription} colorScheme="blue">
            Add Subscription
          </Button>
        )}
      </HStack>

      <DataTable
        columns={columns}
        rest={{ w: "100%" }}
        data={account.subscriptions}
        onRowClick={onClickSubscription}
        tableHeaderProps={{ p: 2, bgColor: tableHeaderBg, borderRadius: 6 }}
      />

      <SubscriptionModal
        isOpen={isOpen}
        onClose={closeModal}
        subscription={subscription}
      />
    </Box>
  );
};
