import { Text } from "@chakra-ui/react";
import { ApiAccountUser } from "@operations-hero/lib-api-client";
import { FC, useMemo } from "react";

export interface RoleBadgeProps {
  user: ApiAccountUser;
  product: string;
}

export const RoleBadge: FC<RoleBadgeProps> = ({ user, product }) => {
  const role = useMemo(() => {
    const productRole = user.products.find((item) => item.name === product);
    const role = productRole
      ? productRole.enabled
        ? productRole.role
        : "disabled"
      : "no role";
    return role;
  }, [user, product]);
  return <Text>{role}</Text>;
};
