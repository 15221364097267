import { Text, TextProps, useColorModeValue } from "@chakra-ui/react";
import { FC, useMemo } from "react";

interface PageDataProps {
  currentPage?: number;
  pageSize?: number;
  total?: number;
  textProps?: TextProps;
}

export const PageData: FC<PageDataProps> = ({
  total,
  pageSize,
  currentPage,
  textProps,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const pageStart = useMemo(() => {
    return ((currentPage || 1) - 1) * (pageSize || 1);
  }, [currentPage, pageSize]);

  const showingTotal = useMemo(() => {
    const result = pageStart + (pageSize || 1);
    return total && result > total ? total : result;
  }, [pageSize, pageStart, total]);

  return (
    <>
      <Text color={textColor} {...textProps}>
        Showing {pageStart + 1}-{showingTotal} of {total || 1}
      </Text>
    </>
  );
};
