import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  HStack,
  Input,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { Country, State } from "country-state-city";
import FuzzySearch from "fuzzy-search";
import { FC, useCallback, useMemo, useState } from "react";
import Select, { SelectMethods, SelectRenderer } from "react-dropdown-select";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AdditionalNumberBadge } from "../../components/badges/AdditionalNumberBadge";

export interface StateFilterProps {
  value: (string | string)[];
  onChange: (value: string[]) => void;
  countries?: string[];
}

type ValueLabel = {
  value: string;
  label: string;
  countryCode?: string;
};

interface SelectOptions {
  [key: string]: ValueLabel[];
}

const ContentRenderer = ({ state }: SelectRenderer<ValueLabel>) => {
  const textColor = useColorModeValue("gray.600", "white");
  return (
    <Box p={1} d="flex" w="max-content" alignItems="center">
      {state.values.length === 0 && "State"}
      {state.values.length === 1 && (
        <Box color={textColor}>
          <Text isTruncated>{state.values[0].label}</Text>
        </Box>
      )}
      {state.values.length > 1 && (
        <>
          <Text as="span" mr={1} color={textColor}>
            {state.values[0].label}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
  countries,
}: SelectRenderer<ValueLabel> & { countries?: string[] }) => {
  const bgColor = useColorModeValue(undefined, "gray.700");
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["label"], {
        sort: true,
      }),
    [props.options]
  );

  const items = useMemo(() => {
    return !state.search ? props.options : fuzzySearch.search(state.search);
  }, [state.search, props.options, fuzzySearch]);

  const groupedItems: SelectOptions = {};
  if (countries) {
    countries.forEach((country) => {
      const countryName = Country.getCountryByCode(country);

      countryName &&
        (groupedItems[countryName.name] = items.filter(
          (item) => item.countryCode === country
        ));
    });
  }

  return (
    <Box backgroundColor={bgColor} p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search States"
        />
      </Box>
      <VStack gap={2} divider={<Divider />} alignItems="flex-start" mb={2}>
        {Object.entries(groupedItems).map(([title, options]) => (
          <CustomStateOptions title={title} items={options} methods={methods} />
        ))}
      </VStack>
    </Box>
  );
};

export const StateFilter = ({
  value,
  onChange,
  countries,
}: StateFilterProps) => {
  const themeClass = useColorModeValue(
    "react-dropdown-light-theme",
    "react-dropdown-dark-theme"
  );

  const options = useMemo(() => {
    if (!countries) return [];

    let newOptions: ValueLabel[] = [];
    countries.forEach((country) => {
      const response = State.getStatesOfCountry(country);
      if (response) {
        const result = response.map((state) => ({
          value: state.isoCode,
          label: `${state.isoCode} - ${state.name}`,
          countryCode: state.countryCode,
        }));
        newOptions.push(...result);
      }
    });

    return newOptions;
  }, [countries]);

  const internalValues = useMemo(() => {
    return value && value.length > 0
      ? options.filter((x) => value.includes(x.value))
      : [];
  }, [value, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange]
  );

  return (
    <Select
      multi
      options={options}
      className={themeClass}
      values={internalValues}
      onChange={handleChange}
      searchable={true}
      searchBy="label"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      contentRenderer={(props) => ContentRenderer({ ...props })}
      dropdownRenderer={(props) => DropdownRenderer({ ...props, countries })}
      dropdownHeight="350px"
      disabled={!countries || countries.length === 0}
    />
  );
};

interface CustomStateOptionsProps {
  title: string;
  items: ValueLabel[];
  methods: SelectMethods<ValueLabel>;
}

const CustomStateOptions: FC<CustomStateOptionsProps> = ({
  title,
  items,
  methods,
}) => {
  const [show, setShow] = useState(true);
  const handleToggle = () => setShow(!show);
  return (
    <Box>
      <Flex onClick={handleToggle} alignItems="center" gap={1}>
        <Text as="span" fontWeight="semibold" px={2}>
          {title}
        </Text>
        {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </Flex>
      <Collapse in={show}>
        <Box>
          {items.map((item) => (
            <Checkbox
              key={`${title}:${item.value}`}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.label}</Text>
            </Checkbox>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};
