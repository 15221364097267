import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiAccount } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../components/badges/AdditionalNumberBadge";
import { useShowToast } from "../../../hooks/showToast";

export interface AccountFilterProps {
  value: string[];
  onChange: (value: ApiAccount[]) => void;
}

const ContentRenderer = ({ state }: SelectRenderer<ApiAccount>) => {
  const textColor = useColorModeValue("gray.600", "white");
  return (
    <Box p={1}>
      {state.values.length === 0 && "Account"}
      {state.values.length === 1 && (
        <Box maxW="100px" color={textColor}>
          <Text isTruncated>{state.values[0].name}</Text>
        </Box>
      )}
      {state.values.length > 1 && (
        <>
          <Text as="span" mr={1} color={textColor}>
            {state.values[0].name}
          </Text>
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiAccount>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["name"], {
        sort: true,
      }),
    [props.options]
  );

  const items = useMemo(() => {
    return !state.search ? props.options : fuzzySearch.search(state.search);
  }, [state.search, props.options, fuzzySearch]);

  return (
    <Box p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Accounts"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={`item:${item.id}`}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <Text>{item.name}</Text>
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const AccountFilter = ({ value, onChange }: AccountFilterProps) => {
  const [accounts, setAccounts] = useState<ApiAccount[]>([]);

  const themeClass = useColorModeValue(
    "react-dropdown-light-theme",
    "react-dropdown-dark-theme"
  );

  const { apiInternal } = useAuthentication();
  const showToast = useShowToast();

  const fetchAccounts = useCallback(async () => {
    let totalAccounts = 0;
    let currentPage = 1;
    do {
      try {
        const response = await apiInternal.findAccounts({
          page: currentPage,
          pageSize: 100,
        });
        const accountSet = new Set(accounts);
        response.data.forEach(accountSet.add, accountSet);
        setAccounts(Array.from(accountSet));
        totalAccounts = response.total;
        currentPage++;
      } catch (error) {
        showToast(
          "error",
          "Something went wrong loading accounts, please try again"
        );
      }
    } while (currentPage <= Math.ceil(totalAccounts / 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiInternal, showToast]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const memorizedValue = useMemo(() => {
    return accounts.filter((account) =>
      value.some((item) => item === account.id)
    );
  }, [value, accounts]);

  return (
    <>
      {accounts && (
        <Select
          multi
          options={accounts}
          className={themeClass}
          values={memorizedValue}
          onChange={(values) => onChange(values)}
          searchable={true}
          searchBy="name"
          valueField="id"
          labelField="name"
          dropdownGap={0}
          keepSelectedInList
          contentRenderer={ContentRenderer}
          dropdownRenderer={DropdownRenderer}
          dropdownHeight="350px"
        />
      )}
    </>
  );
};
