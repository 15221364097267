import { ApiReport, FindReportsOptions } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ApiInternalService } from "../../services/api-internal/internal-service";

export interface AccountReportState {
  reports: ApiReport[];
  total: number;
  filters: FindReportsOptions;
}

export interface FindAccountReportsThunkParams {
  apiInternal: ApiInternalService;
  filters: FindReportsOptions;
}

export const PAGE_SIZE = 10;

export const INITIAL_FILTERS_REPORTS: FindReportsOptions = {
  type: undefined,
  page: 1,
  pageSize: 10,
  includeInactive: true,
};

export const loadReports = createAsyncThunk(
  "reports/load",
  async ({ apiInternal, filters }: FindAccountReportsThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const workingAccount = state.accounts.workingAccount;
    if (workingAccount) {
      const response = await apiInternal.findReports(
        workingAccount?.id,
        filters
      );
      return {
        reports: response.data,
        filters: response.options,
        total: response.total,
      };
    }
    return { reports: [], filters: INITIAL_FILTERS_REPORTS, total: 0 };
  }
);

export const initializeReports = createAsyncThunk(
  "reports/init",
  async ({ apiInternal, filters }: FindAccountReportsThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const workingAccount = state.accounts.workingAccount;
    if (workingAccount) {
      const response = await apiInternal.findReports(
        workingAccount?.id,
        INITIAL_FILTERS_REPORTS
      );
      return {
        reports: response.data,
        filters: response.options,
        total: response.total,
      };
    }
    return { reports: [], filters: INITIAL_FILTERS_REPORTS, total: 0 };
  }
);

export interface DeactivateAccountReportProps {
  apiInternal: ApiInternalService;
  accountId: string;
  reportId: string;
}

export const deactivateReport = createAsyncThunk(
  "accounts/deactivate-report",
  async ({
    apiInternal,
    accountId,
    reportId,
  }: DeactivateAccountReportProps) => {
    const response = await apiInternal.deactivateReport(accountId, reportId);
    return { reportId, response };
  }
);

export const reactivateReport = createAsyncThunk(
  "accounts/reactivate-report",
  async ({
    apiInternal,
    accountId,
    reportId,
  }: DeactivateAccountReportProps) => {
    const response = await apiInternal.reactivateReport(accountId, reportId);
    return response;
  }
);

export const reportsSlice = createSlice({
  name: "account/reports",
  initialState: {
    reports: [],
    total: 0,
    filters: INITIAL_FILTERS_REPORTS,
  } as AccountReportState,
  reducers: {
    updateFilters: (
      state: AccountReportState,
      action: PayloadAction<Partial<FindReportsOptions>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    unloadReports: (state: AccountReportState) => {
      state.filters = INITIAL_FILTERS_REPORTS;
      state.reports = [];
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      initializeReports.fulfilled,
      (state: AccountReportState, action) => {
        state.reports = action.payload.reports;
        state.total = action.payload.total;
      }
    );
    builder.addCase(
      loadReports.fulfilled,
      (state: AccountReportState, action) => {
        state.reports = action.payload.reports;
        state.total = action.payload.total;
      }
    );
  },
});

export const { updateFilters, unloadReports } = reportsSlice.actions;

export default reportsSlice.reducer;
