import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import chalk from "chalk";
import { Form, Formik } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaDownload } from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ImportStatusBadge } from "../../../components/badges/ImportStatusBadge";
import { AccountFileControl } from "../../../components/form-helpers/FileUploadControl";
import { SwitchControl } from "../../../components/form-helpers/SwitchControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { XTerm } from "../../../components/xterm/XTerm";
import { useShowToast } from "../../../hooks/showToast";
import { useWebSocket } from "../../../hooks/useWebSocket";
import {
  ApiImportDescriptor,
  ApiImportJob,
} from "../../../services/api-internal/models/Imports";

export interface ImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  job?: ApiImportJob;
  accountId: string;
}

interface ImportLogEntry {
  timestamp: string;
  message: string;
  level: string;
}

export const ImportModal: React.FC<ImportModalProps> = ({
  isOpen,
  onClose,
  job,
  accountId,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { apiInternal } = useAuthentication();
  const { socket } = useWebSocket({ namespace: "importer" });
  const xtermRef = useRef<XTerm | null>(null);

  const descriptionColor = useColorModeValue(
    "whiteAlpha.400",
    "whiteAlpha.600"
  );

  const showToast = useShowToast();
  const [activeJob, setActiveJob] = useState<ApiImportJob | undefined>(job);
  const [progress, setProgress] = useState<number>(0);
  const progressColor = useMemo(() => {
    if (!activeJob?.status) {
      return "blue";
    }
    switch (activeJob.status) {
      case "completed":
        return "green";
      case "failed":
        return "red";
      case "pending":
        return "orange";
      case "processing":
        return "blue";
      default:
        return "blue";
    }
  }, [activeJob?.status]);

  /* state for the ne import */
  const [imports, setImports] = useState<ApiImportDescriptor[]>([]);
  const [selectedImport, setSelectedImport] = useState<ApiImportDescriptor>();

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const validationSchema = useMemo(() => {
    if (!selectedImport) {
      return null;
    }

    const shape = selectedImport.options.reduce((acc, option) => {
      const validation =
        option.type === "boolean"
          ? yup.boolean()
          : option.type === "file"
          ? option.optional
            ? yup.object().shape({
                name: yup.string(),
                uploadId: yup.string(),
              })
            : yup.object().shape({
                name: yup.string().required(),
                uploadId: yup.string().required(),
              })
          : yup.string();

      acc[option.name] = option.optional
        ? validation.optional()
        : validation.required();
      return acc;
    }, {} as any);

    return yup.object().shape(shape);
  }, [selectedImport]);

  const initialValues = useMemo(() => {
    if (!selectedImport) {
      return null;
    }

    const result = selectedImport.options.reduce((acc, option) => {
      acc[option.name] = "";
      return acc;
    }, {} as Record<string, any>);

    return result;
  }, [selectedImport]);

  const handleOnSubmit = useCallback(
    async (values: any) => {
      if (!selectedImport) {
        return;
      }
      try {
        const job = await apiInternal.createImport(accountId, {
          type: selectedImport.type,
          options: values,
        });
        setActiveJob(job);

        showToast("success", "Import Job created successfully");
      } catch (e) {
        console.log(e);
        showToast("error", "Something happened while creating the Import Job");
      }
    },
    [showToast, apiInternal, accountId, selectedImport]
  );

  const retryImport = useCallback(async () => {
    if (!activeJob) {
      return;
    }

    try {
      const newImport = await apiInternal.retryImport(accountId, activeJob.id);
      setActiveJob(newImport);
      xtermRef.current?.terminal?.clear();
      showToast("success", "Retrying Import Job queued successfully");
    } catch (e) {
      console.log(e);
      showToast(
        "error",
        "Something happened while queueing a retry for the Import Job"
      );
    }
  }, [apiInternal, accountId, showToast, activeJob, xtermRef]);

  /* effects */
  useEffect(() => {
    if (!isFirstRender || !job || !job.logUrl) {
      return;
    }
    setIsFirstRender(false);

    console.log("Downloading log", job.logUrl);
    // load existing log
    downloadFileAndProcessLines(job.logUrl, (line) => {
      xtermRef.current?.terminal.writeln(formatLine(line));
    }).catch((error) => {
      console.error("Failed to download and process file", error);
    });
  }, [job, isFirstRender]);

  // Load import definitions
  useEffect(() => {
    if (!isOpen || activeJob) {
      return;
    }
    apiInternal.findImportDefinitions().then((imports) => {
      console.log(imports);
      setImports(imports);
    });
  }, [isOpen, apiInternal, activeJob]);

  useEffect(() => {
    if (!socket || !activeJob?.id) {
      return;
    }

    socket.emit("join_channel", `${accountId}:${activeJob.id}`);

    socket.on("import:log", (data: ImportLogEntry) => {
      xtermRef.current?.terminal.writeln(formatLine(data));
      console.log("event", data);
    });

    socket.on("import:progress", (data: { percent: number }) => {
      setProgress((progress) =>
        progress > data.percent ? progress : data.percent
      );
    });

    socket.on("import:completed", (data: ApiImportJob) => {
      setActiveJob(data);
      setProgress(100);
    });

    socket.on("import:processing", (data: ApiImportJob) => {
      setActiveJob(data);
    });

    socket.on("import:retry", (data: ApiImportJob) => {
      setActiveJob(data);
    });

    return () => {
      socket.off("import:log");
      socket.off("import:progress");
      socket.off("import:completed");
      socket.off("import:processing");
      socket.off("import:retry");
    };
  }, [socket, activeJob?.id, accountId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={activeJob ? "8xl" : "4xl"}
        minHeight={activeJob ? "90%" : undefined}
      >
        <ModalHeader>
          <HStack spacing={2} w="100%">
            <Text>
              {activeJob
                ? "Import Log"
                : selectedImport
                ? "Configure Import"
                : "Select Import"}
            </Text>

            {activeJob && (
              <>
                <ImportStatusBadge mx={2} status={activeJob.status} />
                {activeJob.logUrl && (
                  <Link href={activeJob.logUrl} target="_blank">
                    <IconButton
                      size="xs"
                      icon={<Icon as={FaDownload} />}
                      aria-label={"Download"}
                    />
                  </Link>
                )}
                {activeJob.status === "failed" && activeJob.importedRows === 0 && (
                  <Button size="xs" onClick={retryImport}>
                    <Icon
                      as={HiOutlineRefresh}
                      aria-label={"Retry Import"}
                      mr={2}
                    />
                    Retry Import
                  </Button>
                )}
              </>
            )}
          </HStack>
          {activeJob && (
            <HStack spacing={2} w="100%">
              {Object.entries(activeJob.options).map(([key, value]) => (
                <Box fontSize="sm">
                  <Text color="whiteAlpha" display="inline" mr={1}>
                    {key}:
                  </Text>
                  {typeof value !== "object" && (
                    <Text display="inline">{value.toString()}</Text>
                  )}
                  {typeof value === "object" && (
                    <Link href={value.url} target="_blank">
                      {value.name}
                    </Link>
                  )}
                </Box>
              ))}
            </HStack>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} display="flex">
          {!activeJob && !imports.length && <Spinner />}
          {!activeJob && !selectedImport && imports.length > 0 && (
            <VStack w="100%" divider={<Divider />} gap={2}>
              {imports.map((importDef) => (
                <Box
                  key={`import-def:${importDef.type}`}
                  w="100%"
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedImport(importDef);
                  }}
                >
                  <Text size="md">{importDef.name}</Text>
                  <Text size="sm" color={descriptionColor}>
                    {importDef.notes}
                  </Text>
                </Box>
              ))}
            </VStack>
          )}
          {!activeJob && selectedImport && validationSchema && (
            <VStack w="100%" divider={<Divider />} gap={2}>
              <Box w="100%">
                <Text size="md">{selectedImport.name}</Text>
                <Text size="sm" color={descriptionColor}>
                  {selectedImport.notes}
                </Text>
              </Box>
              <Box w="100%">
                <Text size="md">Import Options:</Text>
                <Formik
                  initialValues={initialValues || {}}
                  validationSchema={validationSchema}
                  onSubmit={handleOnSubmit}
                  validateOnMount={true}
                >
                  {({ values, isValid }) => {
                    return (
                      <Form>
                        <VStack
                          w="100%"
                          gap={6}
                          alignContent={"flex-start"}
                          py={6}
                        >
                          {selectedImport.options.map((option) => (
                            <Box key={`option-form:${option.name}`} w="100%">
                              {option.type === "file" && (
                                <AccountFileControl
                                  accountId={accountId}
                                  label={`${option.name}${
                                    option.optional ? " (optional)" : ""
                                  }`}
                                  name={option.name}
                                  placeholder={option.description}
                                  value={values[option.name]}
                                />
                              )}
                              {option.type === "string" && (
                                <TextInputControl
                                  label={`${option.name}${
                                    option.optional ? " (optional)" : ""
                                  }`}
                                  name={option.name}
                                  placeholder={option.description}
                                  value={values[option.name]}
                                />
                              )}
                              {option.type === "boolean" && (
                                <SwitchControl
                                  label={`${option.name}${
                                    option.optional ? " (optional)" : ""
                                  } - ${option.description}`}
                                  name={option.name}
                                  value={values[option.name]}
                                />
                              )}
                            </Box>
                          ))}
                        </VStack>
                        <Divider py={4} />
                        <Box
                          w="100%"
                          pt={4}
                          display="flex"
                          justifyContent={"flex-end"}
                        >
                          <HStack gap={4}>
                            <Button
                              variant="outline"
                              onClick={() => setSelectedImport(undefined)}
                            >
                              Cancel
                            </Button>
                            <Button
                              colorScheme="blue"
                              isDisabled={!isValid}
                              type="submit"
                            >
                              Run Import
                            </Button>
                          </HStack>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </VStack>
          )}
          {activeJob && (
            <Box flex={1} display="block" pt={0} m={0} minH="100%" w="100%">
              <Box w="100%">
                <Progress
                  value={
                    activeJob.status === "completed" ||
                    activeJob.status === "failed"
                      ? 100
                      : progress
                  }
                  size="xs"
                  colorScheme={progressColor}
                  isIndeterminate={activeJob.status === "pending"}
                  m={0}
                />
              </Box>
              <XTerm ref={xtermRef} />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

async function downloadFileAndProcessLines(
  url: string,
  processLine: (line: string) => void
) {
  // Fetch the file as a stream
  const response = await fetch(url);
  const reader = response.body?.getReader();
  if (!reader) {
    throw new Error("Failed to get reader from response body");
  }

  // Set up a TextDecoder to decode the stream chunks as text
  const decoder = new TextDecoder("utf-8");
  let data = "";

  while (true) {
    // Read a chunk from the stream
    const { value, done } = await reader.read();
    if (done) {
      break;
    }

    // Decode the chunk as text and add it to the data string
    data += decoder.decode(value, { stream: true });

    // Process complete lines and leave any remaining partial line in the data string
    let newlineIndex;
    while ((newlineIndex = data.indexOf("\n")) !== -1) {
      const line = data.slice(0, newlineIndex).replace("\r", "");
      processLine(line);
      data = data.slice(newlineIndex + 1);
    }
  }

  // Process any remaining data as a line
  if (data) {
    processLine(data);
  }
}

function formatLine(line: string | ImportLogEntry) {
  if (typeof line !== "string") {
    return prettyUpMessage(line);
  }

  try {
    console.log(line);
    const parsed = JSON.parse(line);

    return prettyUpMessage(parsed);
  } catch (e) {
    return line;
  }
}

function prettyUpMessage(entry: ImportLogEntry) {
  const timestamp = chalk.gray(entry.timestamp);
  const logLevel = (entry.level === "error" ? chalk.red : chalk.blue)(
    `[${entry.level}]`
  );

  return `${timestamp} ${logLevel} ${entry.message}`;
}
