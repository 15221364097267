import {
  ApiAccount,
  ApiFindAccountsOptions,
  ApiUserSummary,
} from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiInternalService } from "../../services/api-internal/internal-service";
import { AccountsData } from "../../services/api-internal/models/AccountData";
import { addAccountSubscriptionHandlers } from "./thunks/account-add-subscription.thunk";
import {
  deactivateAccountSubscriptionHandlers,
  reactivateAccountSubscriptionHandlers,
} from "./thunks/account-deactivate-subscription.thunk";
import { accountsLoadHandlers } from "./thunks/account-load.thunk";
import { updateAccountSubscriptionHandlers } from "./thunks/account-update-subscription.thunk";
import { accountsInitHandlers } from "./thunks/accounts-init.thunk";

export interface AccountSliceState {
  accounts: AccountsData[];
  total: number;
  workingAccount: AccountsData | null;
  filters: ApiFindAccountsOptions;
  initialized: boolean;
}

export interface FindAccountsThunkParams {
  apiInternal: ApiInternalService;
  filters?: ApiFindAccountsOptions;
}

export const INITIAL_FILTERS_ACCOUNT: ApiFindAccountsOptions = {
  page: 1,
  search: "",
  pageSize: 50,
  showExpired: undefined,
  state: [],
  country: [],
  includeInactive: false,
  organization: undefined,
  isTrial: undefined,
  daysToExpire: undefined,
};

export const accountSlice = createSlice({
  name: "accounts",
  initialState: {
    accounts: [],
    total: 0,
    initialized: false,
    workingAccount: null,
    filters: INITIAL_FILTERS_ACCOUNT,
  } as AccountSliceState,
  reducers: {
    updateFilters: (
      state,
      action: PayloadAction<Partial<ApiFindAccountsOptions>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    setWorkingAccount: (state, action: PayloadAction<AccountsData | null>) => {
      state.workingAccount = action.payload;
    },
    unloadAccounts: (state) => {
      state.accounts = [];
      state.total = 0;
      state.initialized = false;
      state.filters = { ...INITIAL_FILTERS_ACCOUNT };
    },

    updateAccount: (state, action: PayloadAction<ApiAccount | null>) => {
      state.workingAccount = state.workingAccount
        ? { ...state.workingAccount, ...action.payload }
        : null;
    },

    updateWorkingAccountOwner: (
      state,
      action: PayloadAction<ApiUserSummary>
    ) => {
      if (!state.workingAccount) return;
      state.workingAccount.owner = action.payload;
    },

    unloadFilters: (state) => {
      state.filters = { ...state.filters, page: 1, search: "" };
    },
  },

  extraReducers: (builder) => {
    accountsInitHandlers(builder);
    accountsLoadHandlers(builder);
    addAccountSubscriptionHandlers(builder);
    updateAccountSubscriptionHandlers(builder);
    deactivateAccountSubscriptionHandlers(builder);
    reactivateAccountSubscriptionHandlers(builder);
  },
});

export const {
  setWorkingAccount,
  updateFilters,
  unloadAccounts,
  updateAccount,
  unloadFilters,
  updateWorkingAccountOwner,
} = accountSlice.actions;

export default accountSlice.reducer;
