import {
  Flex,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiOrganization } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { FaCircle, FaUserPlus, FaUsers } from "react-icons/fa";
import { MdEdit, MdOutlineFormatListBulleted } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseTableCellProps } from "react-table";
import { useAuthentication } from "../../components/auth/AuthProvider";
import {
  DataTable,
  DataTableColumn,
} from "../../components/data-table/DataTable";
import { CustomModal } from "../../components/modal/CustomModal";
import { PageData } from "../../components/pagination/PageData";
import { Pager } from "../../components/pagination/Pager";
import { RootState, useThunkDispatch } from "../../store";
import {
  setWorkingOrganization,
  uloadOrganizationUsers,
  unload,
} from "../../store/organization/accounts/organizations-accounts.slice";
import { loadOrganizationUsers } from "../../store/organization/accounts/thunks/organization-users.thunk";
import { initOrganizationsAccounts } from "../../store/organization/accounts/thunks/organizations-accounts-init.thunk";
import { updateFilters } from "../../store/organization/organizations.slice";
import { OrganizationForm } from "../organizations/OrganizationForm";
import { AddOrganizationUserRole } from "./AddOrganizationUserRole";
import { OrganizationUsersList } from "./OrganizationUsersList";
const DATE_FORMAT = "yyyy-MM-dd";

export type UserView = "add" | "list";

export const OrganizationsList = () => {
  const { organizations, filters } = useSelector(
    (state: RootState) => state.organizations
  );

  const { apiInternal } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const [organization, setOrganization] = useState<ApiOrganization>();
  const [usersView, setUsersView] = useState<UserView>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { total, page, pageSize } = filters;

  const tableHeaderBg = useColorModeValue("blue.50", "transparent");
  const iconColor = useColorModeValue("gray.600", "white");
  const selectedIconColor = useColorModeValue("blue.500", "blue.300");

  const {
    isOpen: isOpenUsersModal,
    onOpen: openUsersModal,
    onClose: closeUsersModal,
  } = useDisclosure();

  const dispatch = useDispatch();
  const history = useNavigate();

  const handleOpenEditModal = useCallback(
    (e: React.MouseEvent, organization: ApiOrganization) => {
      e.preventDefault();
      e.stopPropagation();
      setOrganization(organization);
      onOpen();
    },
    [onOpen]
  );

  const onPageChange = useCallback(
    (newPage: number) => {
      dispatch(updateFilters({ page: newPage }));
    },
    [dispatch]
  );

  const handleRedirection = useCallback(
    (org: ApiOrganization) => {
      dispatch(setWorkingOrganization(org));
      history(`/organizations/${org.id}`);
      return;
    },
    [dispatch, history]
  );

  const handleSeeUsers = useCallback(
    async (e: React.MouseEvent, org: ApiOrganization) => {
      e.preventDefault();
      e.stopPropagation();

      await thunkDispatch(
        initOrganizationsAccounts({ apiInternal, orgId: org.id })
      );
      await thunkDispatch(
        loadOrganizationUsers({ apiInternal, orgId: org.id, options: {} })
      );
      setOrganization(org);
      setUsersView("list");
      openUsersModal();
    },
    [apiInternal, openUsersModal, thunkDispatch]
  );

  const handleCloseUsersModal = useCallback(() => {
    setUsersView(undefined);
    dispatch(uloadOrganizationUsers());
    dispatch(unload());
    setOrganization(undefined);
    closeUsersModal();
  }, [closeUsersModal, dispatch]);

  const changeUsersView = useCallback((view?: UserView) => {
    setUsersView(view);
  }, []);

  const columns = useMemo<DataTableColumn<ApiOrganization>[]>(
    () => [
      {
        Header: "Name",
        Cell: (data: UseTableCellProps<ApiOrganization>) => {
          return (
            <Flex gap={2} flexDir="column">
              <Text isTruncated fontWeight="semibold">
                {data.row.original.name}
              </Text>
              <Text fontSize="sm" display="flex" alignItems="center" gap={2}>
                {data.row.original.active ? "Active" : "Inactive"}
                <Icon
                  boxSize="10px"
                  as={FaCircle}
                  color={data.row.original.active ? "green.500" : "red.500"}
                />
              </Text>
            </Flex>
          );
        },
      },
      {
        Header: "Created",
        Cell: (data: UseTableCellProps<ApiOrganization>) => {
          return (
            <Text isTruncated>
              {format(new Date(data.row.original.created), DATE_FORMAT)}
            </Text>
          );
        },
      },
      {
        Header: "Updated",
        Cell: (data: UseTableCellProps<ApiOrganization>) => {
          return (
            <Text isTruncated>
              {format(new Date(data.row.original.updated), DATE_FORMAT)}
            </Text>
          );
        },
      },
      {
        Header: "# Accounts",
        Cell: (data: UseTableCellProps<ApiOrganization>) => (
          <Text>{data.row.original.totalAccounts}</Text>
        ),
      },
      {
        Header: "Actions",
        maxWidth: 100,
        Cell: (data: UseTableCellProps<ApiOrganization>) => (
          <Flex justifyContent="flex-end">
            <IconButton
              mr={2}
              size="sm"
              colorScheme="blue"
              variant="outline"
              icon={<FaUsers />}
              aria-label="Search database"
              onClick={(e) => handleSeeUsers(e, data.row.original)}
            />
            <IconButton
              mr={2}
              size="sm"
              colorScheme="blue"
              variant="outline"
              icon={<MdEdit />}
              aria-label="Search database"
              onClick={(e) => handleOpenEditModal(e, data.row.original)}
            />
          </Flex>
        ),
      },
    ],
    [handleOpenEditModal, handleSeeUsers]
  );

  return (
    <>
      <PageData total={total} currentPage={page} pageSize={pageSize} />
      <DataTable
        columns={columns}
        data={organizations}
        rest={{ w: "100%" }}
        onRowClick={handleRedirection}
        tableHeaderProps={{ p: 2, bgColor: tableHeaderBg, borderRadius: 6 }}
      />

      <Flex flexWrap="wrap" justifyContent="space-between">
        <PageData total={total} currentPage={page} pageSize={pageSize} />
        <Pager
          currentPage={page}
          totalPages={Math.ceil(total / pageSize)}
          onPageChange={onPageChange}
        />
      </Flex>
      <OrganizationForm
        organization={organization}
        isOpen={isOpen}
        onClose={onClose}
      />

      {isOpenUsersModal && organization && usersView && (
        <CustomModal
          isOpen={isOpenUsersModal}
          onClose={handleCloseUsersModal}
          title={
            <Flex alignItems="center" justifyContent="space-between" gap={2}>
              <Text isTruncated>{`${organization?.name} Users`}</Text>
              <Flex mr={10} gap={2}>
                <Icon
                  cursor="pointer"
                  boxSize="24px"
                  as={MdOutlineFormatListBulleted}
                  onClick={() => changeUsersView("list")}
                  color={usersView === "list" ? selectedIconColor : iconColor}
                />
                <Icon
                  as={FaUserPlus}
                  cursor="pointer"
                  boxSize="24px"
                  onClick={() => changeUsersView("add")}
                  color={usersView === "add" ? selectedIconColor : iconColor}
                />
              </Flex>
            </Flex>
          }
          content={
            usersView === "list" ? (
              <OrganizationUsersList
                organization={organization}
                onClose={handleCloseUsersModal}
              />
            ) : (
              <AddOrganizationUserRole
                organization={organization}
                onClose={handleCloseUsersModal}
                changeUsersView={changeUsersView}
              />
            )
          }
        />
      )}
    </>
  );
};
