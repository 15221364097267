import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ApiAccountEventSummary } from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useRef } from "react";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { NumberInputControl } from "../../../components/form-helpers/NumberControl";
import { useShowToast } from "../../../hooks/showToast";

const EventNumberSchema = yup.object().shape({
  eventKeyCounter: yup.number().required("Event number is required"),
});
export interface UpdateCounterModalProps {
  isOpen: boolean;
  onClose: () => void;
  event: ApiAccountEventSummary;
  accountId: string;
}

export const UpdateCounterModal: React.FC<UpdateCounterModalProps> = ({
  isOpen,
  onClose,
  event,
  accountId,
}) => {
  const { apiInternal } = useAuthentication();
  const showToast = useShowToast();
  const modalRef = useRef<FormikProps<ApiAccountEventSummary>>(null);

  const handleOnSubmit = useCallback(() => {
    if (modalRef.current) modalRef.current.handleSubmit();
  }, []);

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const updateEventKeyCounter = useCallback(
    async (values: ApiAccountEventSummary) => {
      try {
        await apiInternal.updateEventCounter(accountId, values.eventKeyCounter);
        showToast("success", "Current Invoice Number was updated succesfully");
        event.eventKeyCounter = values.eventKeyCounter
          ? values.eventKeyCounter
          : event.eventKeyCounter;
        handleOnClose();
      } catch {
        showToast(
          "error",
          "Something happened while updating Current Invoice Number "
        );
      }
    },
    [accountId, apiInternal, handleOnClose, event, showToast]
  );

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} closeOnEsc={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{"Edit Event Key Counter"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="100%">
            <Formik
              initialValues={event}
              validationSchema={EventNumberSchema}
              onSubmit={updateEventKeyCounter}
              innerRef={modalRef}
            >
              {({ values }) => {
                return (
                  <Form>
                    <Grid templateColumns={"repeat(12, 1fr)"} gap={5}>
                      <GridItem colSpan={12}>
                        <NumberInputControl
                          name="eventKeyCounter"
                          label="Current Event Number"
                          value={
                            values.eventKeyCounter
                              ? values.eventKeyCounter
                              : null
                          }
                        />
                      </GridItem>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ModalBody>
        <ModalFooter flexDir={"row"} justifyContent="space-between">
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button onClick={handleOnSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
