import { ApiAccountUser } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FindOrganizationUsersParams,
  OrganizationSliceState,
} from "../organizations-accounts.slice";

export const loadOrganizationUsers = createAsyncThunk(
  "organizations-users/load",
  async (params: FindOrganizationUsersParams, thunkAPI) => {
    const { orgId, apiInternal, options } = params;

    try {
      const users = (await apiInternal.getOrganizationUsers(orgId, options))
        .data;
      const usersHash = users.reduce((hash, user) => {
        hash[user.id] = user;
        return hash;
      }, {} as Record<string, ApiAccountUser>);
      return { usersHash, options };
    } catch (error) {
      return Promise.reject();
    }
  }
);

export const organizationsUsersLoadHandlers = (
  builder: ActionReducerMapBuilder<OrganizationSliceState>
) => {
  builder.addCase(loadOrganizationUsers.fulfilled, (state, action) => {
    state.organizationUsers.usersHash = action.payload.usersHash;
    state.organizationUsers.filters = action.payload.options;
  });
};
