import { IState, State } from "country-state-city";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StyledSelect } from "./StyledSelect";

interface LabelValue {
  label: string;
  value: string;
}

export interface StateSelectProps {
  onChange: (value: string | null) => void;
  value?: string;
  allowEmpty?: boolean;
  country?: string;
}

export const StateSelect = ({
  allowEmpty,
  value,
  onChange,
  country,
}: StateSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<LabelValue>();

  const availableStates = useMemo(
    () =>
      State.getStatesOfCountry(country || "US")
        .filter((state) => state.isoCode.length <= 2)
        .map((state: IState) => ({
          value: state.isoCode,
          label: `${state.isoCode} - ${state.name}`,
        })),
    [country]
  );
  const handleOnChange = useCallback(
    (selected: LabelValue) => {
      onChange(selected?.value || null);
    },
    [onChange]
  );

  useEffect(() => {
    setSelectedValue(
      availableStates.find(
        (x) => x.value.toLowerCase() === value?.toLowerCase()
      )
    );
  }, [value, availableStates]);

  return (
    <StyledSelect
      value={selectedValue}
      onChange={handleOnChange}
      options={availableStates}
      isClearable={allowEmpty}
      placeholder="State"
    />
  );
};
