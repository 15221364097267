import { Divider, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { FC, SetStateAction } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AccountsData } from "../../../services/api-internal/models/AccountData";
import { ApiAccountUserSession } from "./AccountUsers";
import { LogoutUserTrigger } from "./LogoutUserTrigger";
import { MakeOwnerTrigger } from "./MakeOwner";
import { MergeUsersTrigger } from "./MergeUsersTrigger";

interface UserMenuProps {
  account: AccountsData;
  users: ApiAccountUserSession[];
  rowUser: ApiAccountUserSession;
  workingUser: ApiUserSummary | null;
  setWorkingUser: (value: SetStateAction<ApiUserSummary | null>) => void;
  handleUpdateUsers: (values: ApiAccountUserSession[]) => void;
}

export const UserMenu: FC<UserMenuProps> = ({
  users,
  rowUser,
  account,
  workingUser,
  setWorkingUser,
  handleUpdateUsers,
}) => {
  const { currentUser } = useAuthentication();

  return (
    <Menu>
      <MenuButton>
        <BsThreeDotsVertical />
      </MenuButton>

      <MenuList>
        <MergeUsersTrigger
          label="Merge"
          trigger="menuItem"
          account={account}
          workingUser={rowUser}
          setWorkingUser={setWorkingUser}
        />

        <Divider />

        {account.owner.id !== rowUser.id && (
          <MakeOwnerTrigger
            account={account}
            workingUser={rowUser}
            setWorkingUser={setWorkingUser}
          />
        )}

        <Divider />

        {rowUser.isActiveSession && rowUser.id !== currentUser.id && (
          <LogoutUserTrigger
            label="Logout"
            trigger="menuItem"
            workingUser={rowUser}
            setWorkingUser={setWorkingUser}
            buttonProps={{ maxW: "80px" }}
          />
        )}
      </MenuList>
    </Menu>
  );
};
