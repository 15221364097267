import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiInternalService } from "../../../services/api-internal/internal-service";
import { AccountSliceState } from "../accounts.slice";

export interface DeactivateAccountSubscriptionProps {
  apiInternal: ApiInternalService;
  acctId: string;
  subId: string;
}

export const deactivateSubscription = createAsyncThunk(
  "accounts/deactivate-subscription",
  async ({
    apiInternal,
    acctId,
    subId,
  }: DeactivateAccountSubscriptionProps) => {
    const response = await apiInternal.deactivateSubscription(acctId, subId);
    return { subId, response };
  }
);

export const reactivateSubscription = createAsyncThunk(
  "accounts/reactivate-subscription",
  async ({
    apiInternal,
    acctId,
    subId,
  }: DeactivateAccountSubscriptionProps) => {
    const response = await apiInternal.reactivateSubscription(acctId, subId);
    return response;
  }
);

export const deactivateAccountSubscriptionHandlers = (
  builder: ActionReducerMapBuilder<AccountSliceState>
) => {
  builder.addCase(deactivateSubscription.fulfilled, (state, action) => {
    const subIndex = state.workingAccount?.subscriptions.findIndex(
      (sub) => sub.id === action.payload.subId
    );

    if (subIndex !== undefined && subIndex !== -1 && state.workingAccount) {
      state.workingAccount.subscriptions[subIndex] = {
        ...state.workingAccount.subscriptions[subIndex],
        active: false,
      };
    }
  });
};

export const reactivateAccountSubscriptionHandlers = (
  builder: ActionReducerMapBuilder<AccountSliceState>
) => {
  builder.addCase(reactivateSubscription.fulfilled, (state, action) => {
    const subIndex = state.workingAccount?.subscriptions.findIndex(
      (sub) => sub.id === action.payload.id
    );

    if (subIndex !== undefined && subIndex !== -1 && state.workingAccount) {
      state.workingAccount.subscriptions[subIndex] = action.payload;
    }
  });
};
