import { ChevronDownIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  StackItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  ApiAccountDetail,
  ApiEventSettings,
  ApiUserSummary,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdLogin } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { LogoEditor } from "../../components/files/LogoEditor";
import { useShowToast } from "../../hooks/showToast";
import { AccountsData } from "../../services/api-internal/models/AccountData";
import { RootState } from "../../store";
import { setWorkingAccount } from "../../store/account/accounts.slice";
import { AccountUsers } from "./account-users/AccountUsers";
import { MergeUsersTrigger } from "./account-users/MergeUsersTrigger";
import { AccountForm } from "./AccountForm";
import { AccountOwnerCard } from "./AccountOwnerCard";
import { EventSummary } from "./events/EventSummary";
import { Imports } from "./imports/Imports";
import { Invoices } from "./invoices/Invoices";
import { Reports } from "./reports/Reports";
import { Schedules } from "./Schedules";
import { SubscriptionList } from "./subscriptions/Subscriptions";
import { Workflows } from "./workflows/Workflows";

const UNPROCESSABLE_ENTITY = 422;

export const Account: FC = () => {
  const params = useParams();
  const [workingUser, setWorkingUser] = useState<ApiUserSummary | null>(null);
  const { apiClient, apiInternal, currentUser } = useAuthentication();
  const { workingAccount } = useSelector((state: RootState) => state.accounts);
  const { products } = useSelector((state: RootState) => state.auth);
  const [eventSettings, setEventSettings] = useState<ApiEventSettings>();

  const iconColor = useColorModeValue("blue.500", "blue.100");
  const linksSectionBg = useColorModeValue("blue.50", "blue.900");

  const [isLoading, setIsLoading] = useState(true);
  const [isITCreated, setIsITCreated] = useState(false);
  const showToast = useShowToast();

  const { acctId } = params;
  const toast = useToast();
  const dispatch = useDispatch();

  const handleOnClickAddITCategories = useCallback(() => {
    if (!acctId) return;
    const shouldCreate = window.confirm(
      "Are you sure you want to create IT Categories?"
    );
    if (!shouldCreate) return;
    apiInternal
      .createITCategories(acctId)
      .then(() => {
        showToast("success", "IT Categories Succesfully Created");
        setIsITCreated(true);
      })
      .catch(() =>
        showToast("error", "Something happened while creating IT Categories")
      );
  }, [acctId, apiInternal, showToast]);

  const getEventSettings = useCallback(
    (accountId: string) => {
      apiClient
        .getEventSettings(accountId)
        .then((settings) => {
          setEventSettings(settings);
        })
        .catch((error) => {
          const { status } = error.response;
          if (status !== UNPROCESSABLE_ENTITY) {
            showToast("error", "Something went wrong loading event settings");
          }
        });
    },
    [apiClient, showToast]
  );

  const getAccountById = useCallback(async () => {
    if (acctId) {
      try {
        const [acct, subscriptions] = await Promise.all([
          apiClient.getAccountDetail(acctId),
          apiClient.getAccountSubscriptions(acctId, { includeInactive: true }),
        ]);
        const accountData: AccountsData = {
          ...acct,
          subscriptions: subscriptions,
        };
        getEventSettings(acctId);
        dispatch(setWorkingAccount(accountData));
        setIsLoading(false);
      } catch (error) {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Error loading account",
        });
      }
    }
  }, [acctId, apiClient, dispatch, getEventSettings, toast]);

  const checkITCategories = useCallback(async () => {
    if (!acctId) return;
    try {
      setIsITCreated(await apiInternal.checkITCategories(acctId));
    } catch (error) {
      showToast("error", "Error loading for IT categories");
    }
  }, [apiInternal, showToast, acctId]);
  const handleChangeLogo = useCallback(
    (logo: string) => {
      if (workingAccount) {
        const newWorkingAcct: AccountsData = { ...workingAccount, logo: logo };
        dispatch(setWorkingAccount(newWorkingAcct));
      }
    },
    [dispatch, workingAccount]
  );

  const copyAccountId = useCallback(() => {
    if (!workingAccount) {
      return;
    }
    navigator.clipboard.writeText(workingAccount.id);
    toast({
      duration: 1500,
      isClosable: true,
      position: "top",
      status: "info",
      title: "Copied account id",
    });
  }, [workingAccount, toast]);

  const handleLocationClearCache = useCallback(() => {
    if (!workingAccount) return;
    apiInternal
      .clearLocationCache(workingAccount.id)
      .then(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "success",
          title: "Locations cache cleared",
        });
      })
      .catch(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Failed to clear Location Cache",
        });
      });
  }, [workingAccount, toast, apiInternal]);

  const handleWorkflowPoliciesClearCache = useCallback(() => {
    if (!workingAccount) return;
    apiInternal
      .clearWorkflowPoliciesCache(workingAccount.id)
      .then(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "success",
          title: "Workflow Policies cache cleared",
        });
      })
      .catch(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Failed to clear Workflow Policies cache",
        });
      });
  }, [workingAccount, toast, apiInternal]);

  const handleEventPortalClearCache = useCallback(() => {
    if (!workingAccount) return;
    apiInternal
      .clearEventsPortalCache(workingAccount.id)
      .then(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "success",
          title: "Events Portal cache cleared",
        });
      })
      .catch(() => {
        toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "error",
          title: "Failed to clear Events Portal cache",
        });
      });
  }, [workingAccount, toast, apiInternal]);

  const handleReportsClearCache = useCallback(() => {
    if (!workingAccount) return;
    apiInternal
      .clearReportsCache(workingAccount.id)
      .then(() => {
        showToast("success", "Reports cache cleared");
      })
      .catch(() => {
        showToast("error", "Failed to clear Reports cache");
      });
  }, [workingAccount, apiInternal, showToast]);

  const handleVenuesClearCache = useCallback(() => {
    if (!workingAccount) return;
    apiInternal
      .clearVenuesCache(workingAccount.id)
      .then(() => {
        showToast("success", "Venues cache cleared");
      })
      .catch(() => {
        showToast("error", "Failed to clear Venues cache");
      });
  }, [workingAccount, apiInternal, showToast]);

  useEffect(() => {
    getAccountById();
    checkITCategories();
  }, [getAccountById, checkITCategories]);

  useEffect(() => {
    return () => {
      dispatch(setWorkingAccount(null));
    };
  }, [dispatch]);

  const accountLinks = useMemo(() => {
    if (!workingAccount) return [];

    const accountLink = `${process.env.REACT_APP_AUTH_SERVER}/login?accountId=${workingAccount.id}`;
    const data = [{ label: "Account Login Page", url: accountLink }];

    const hasEvents = workingAccount.subscriptions.some(
      (sub) => sub.product.name === "Events"
    );
    if (hasEvents && eventSettings && eventSettings.domain) {
      const protocol = window.location.protocol;

      data.push({
        label: "Public Portal",
        url: `${protocol}//${eventSettings.domain}${
          protocol === "http:" ? ":3000" : ""
        }`,
      });
    }
    return data;
  }, [eventSettings, workingAccount]);

  const handleOnCopyAccountLink = useCallback(
    (url: string) => {
      if (!workingAccount) {
        return;
      }
      navigator.clipboard.writeText(url);
      showToast("success", "Link copied in clipboard!");
    },
    [showToast, workingAccount]
  );

  const handleOnOpenAccountLink = useCallback(
    (url: string) => {
      if (!workingAccount) {
        return;
      }
      window.open(url, "_blank");
    },
    [workingAccount]
  );

  return (
    <Box w="100%" gap={4}>
      <Stack w="100%" mb={8}>
        {isLoading || !workingAccount ? (
          <Center alignSelf="center" h="80vh">
            <Spinner color="blue.500" size="lg" />
          </Center>
        ) : (
          <>
            <StackItem w={"100%"}>
              <Stack
                flexDirection={["column", "column", "row"]}
                justifyContent={"space-between"}
              >
                <Box>
                  <Heading>{workingAccount.name}</Heading>
                  <Box display="flex" alignItems="center">
                    <Text
                      as="span"
                      color="gray"
                      fontSize="xs"
                      _hover={{ cursor: "pointer" }}
                      onClick={copyAccountId}
                    >
                      {workingAccount.id}
                    </Text>
                    <CopyIcon
                      ml={1}
                      w={2}
                      h={2}
                      color="gray"
                      _hover={{ cursor: "pointer" }}
                      onClick={copyAccountId}
                    />
                  </Box>
                </Box>
                {workingAccount !== null && (
                  <Box display="flex" gap={2} flexWrap="wrap">
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        size="sm"
                      >
                        Clear Cache
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={handleLocationClearCache}>
                          Location Cache
                        </MenuItem>
                        <MenuItem onClick={handleWorkflowPoliciesClearCache}>
                          Policy Cache
                        </MenuItem>
                        <MenuItem onClick={handleEventPortalClearCache}>
                          Portal Cache
                        </MenuItem>
                        <MenuItem onClick={handleReportsClearCache}>
                          Reports Cache
                        </MenuItem>
                        <MenuItem onClick={handleVenuesClearCache}>
                          Venues Cache
                        </MenuItem>
                      </MenuList>
                    </Menu>

                    <MergeUsersTrigger
                      trigger="button"
                      label="Merge Users"
                      account={workingAccount}
                      setWorkingUser={setWorkingUser}
                      workingUser={workingUser}
                    />

                    {!isITCreated && (
                      <Button
                        size="sm"
                        onClick={() => handleOnClickAddITCategories()}
                      >
                        Add IT Categories
                      </Button>
                    )}

                    <Button
                      size="sm"
                      colorScheme={"blue"}
                      as={Link}
                      _hover={{ textDecoration: "none" }}
                      href={`${process.env.REACT_APP_HEROHQ_URL}/?accountId=${workingAccount.id}`}
                      isExternal
                    >
                      Login to Account
                    </Button>
                  </Box>
                )}
              </Stack>
            </StackItem>

            <Stack w="100%" flexDir="row" flexWrap="wrap" gap={4}>
              <Box w={{ base: "100%", lg: "70%" }}>
                <Heading size="md" w="max-content" py={4}>
                  Owner
                </Heading>
                <AccountOwnerCard
                  workingUser={workingUser}
                  user={workingAccount.owner}
                  setWorkingUser={setWorkingUser}
                  currentUserId={currentUser.id}
                />

                <Heading size="md" w="max-content" py={4}>
                  Account Details
                </Heading>
                <AccountForm account={workingAccount} />
              </Box>

              <Flex
                gap={4}
                px={[0, 0, 4]}
                flexDir="column"
                w={{ base: "100%", lg: "28%" }}
              >
                <LogoEditor
                  account={workingAccount as ApiAccountDetail}
                  onLogoChange={handleChangeLogo}
                />

                <Flex
                  p={4}
                  flexDir="column"
                  borderRadius={8}
                  bgColor={linksSectionBg}
                >
                  <List spacing={3} fontWeight="semibold">
                    <Text fontWeight="bold" mb={4}>
                      Account Links
                    </Text>
                    {accountLinks.map((item) => (
                      <ListItem
                        gap={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        key={`${item.label}::${item.url}`}
                      >
                        <Text>{item.label}</Text>
                        <Flex alignItems="center" gap={2}>
                          <Icon
                            as={MdLogin}
                            color={iconColor}
                            cursor="pointer"
                            onClick={() => handleOnOpenAccountLink(item.url)}
                          />
                          <Icon
                            as={CopyIcon}
                            color={iconColor}
                            cursor="pointer"
                            onClick={() => handleOnCopyAccountLink(item.url)}
                          />
                        </Flex>
                      </ListItem>
                    ))}
                  </List>
                </Flex>
              </Flex>
            </Stack>

            <StackItem paddingTop={8}>
              <Tabs isLazy>
                <TabList overflowX="auto" overflowY="hidden">
                  <Tab>Subscriptions</Tab>
                  <Tab>Users</Tab>
                  <Tab>Workflows</Tab>
                  <Tab>Schedules</Tab>
                  <Tab>Invoices</Tab>
                  <Tab>Events</Tab>
                  <Tab>Reports</Tab>
                  <Tab>Imports</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <SubscriptionList
                      account={workingAccount}
                      products={products}
                    />
                  </TabPanel>
                  <TabPanel>
                    <AccountUsers account={workingAccount} />
                  </TabPanel>
                  <TabPanel>
                    <Workflows account={workingAccount} />
                  </TabPanel>
                  <TabPanel>
                    <Schedules account={workingAccount} />
                  </TabPanel>
                  <TabPanel>
                    <Invoices account={workingAccount} />
                  </TabPanel>
                  <TabPanel>
                    <EventSummary account={workingAccount} />
                  </TabPanel>
                  <TabPanel>
                    <Reports />
                  </TabPanel>
                  <TabPanel>
                    <Imports account={workingAccount} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </StackItem>
          </>
        )}
      </Stack>
    </Box>
  );
};
