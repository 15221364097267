import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Progress,
  Spinner,
  Stack,
  StackItem,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ImportStatusBadge } from "../../../components/badges/ImportStatusBadge";
import { Pager } from "../../../components/pagination/Pager";
import { useWebSocket } from "../../../hooks/useWebSocket";
import { ApiImportJob } from "../../../services/api-internal/models/Imports";
import { AccountFormProps } from "../AccountForm";
import { ImportModal } from "./ImportModal";

const PAGE_SIZE = 20;

export const Imports: FC<AccountFormProps> = ({ account }) => {
  const { apiInternal } = useAuthentication();

  const { isConnected, disconnect, connect, socket } = useWebSocket({
    namespace: "importer",
  });

  const [progressHash, setProgressHash] = useState<
    Record<string, { processed: number; total: number; percent: number }>
  >({});

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [imports, setImports] = useState<ApiImportJob[]>([]);
  const [activeImport, setActiveImport] = useState<ApiImportJob>();

  const headerBg = useColorModeValue("gray.100", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const loadImports = useCallback(
    (page: number) => {
      apiInternal
        .findImports(account.id, { page: page, pageSize: PAGE_SIZE })
        .then((imports) => {
          setImports(imports.data);
          setTotal(imports.total);
          setIsLoading(false);
        });
    },
    [apiInternal, account.id]
  );

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const closeModal = useCallback(() => {
    setActiveImport(undefined);
    onClose();
  }, [onClose]);

  useEffect(() => {
    loadImports(page);
  }, [loadImports, page]);

  useEffect(() => {
    if (!socket || !isConnected) {
      return;
    }

    socket.emit("join_channel", account.id);

    socket.on("import:created", (data) => {
      setImports((imports) => [data, ...imports]);
    });
    const updateImport = (data: any) => {
      setImports((imports) => {
        const idx = imports.findIndex((i) => i.id === data.id);
        if (idx === -1) {
          return imports;
        }
        imports[idx] = data;
        return [...imports];
      });
    };
    socket.on("import:processing", updateImport);
    socket.on("import:completed", (data) => {
      updateImport(data);
      setProgressHash((progressHash) => {
        const newHash = { ...progressHash };
        delete newHash[data.id];
        return newHash;
      });
    });

    socket.on("import:progress", (data) => {
      setProgressHash((progressHash) => {
        progressHash[data.id] = data;
        return { ...progressHash };
      });
    });

    socket.on("import:retry", updateImport);

    socket.onAny((event, ...args) => {
      console.log("event", event, args);
    });
    return () => {
      socket.off("import:created");
      socket.off("import:completed");
      socket.off("import:processing");
      socket.off("import:progress");
      socket.off("import:retry");
      socket.offAny();
    };
  }, [socket, isConnected, account]);

  return (
    <Box pb={8}>
      <Stack
        py={4}
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
      >
        <Box flex={1}>
          <Heading mb={2} size="lg">
            Imports
          </Heading>
        </Box>
        <HStack gap={2}>
          <Button
            size="sm"
            onClick={() => {
              isConnected ? disconnect() : connect();
            }}
          >
            {isConnected ? "Connected" : "Disconnected"}
          </Button>
          <Button size="sm" colorScheme="blue" onClick={() => onOpen()}>
            New Import
          </Button>
        </HStack>
      </Stack>

      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}

      {!imports.length && !isLoading && (
        <Center>
          <Heading size="md">No imports found</Heading>
        </Center>
      )}
      {!isLoading && imports.length > 0 && (
        <>
          <HStack
            p={2}
            w="100%"
            gap="4px"
            spacing={0}
            flexDir="row"
            flexWrap="wrap"
            bgColor={headerBg}
            alignItems="center"
            d={["none", "none", "flex"]}
          >
            <StackItem w={["calc(10% - 4px)"]} fontWeight="bold">
              Import
            </StackItem>
            <StackItem w={["calc(10% - 4px)"]} fontWeight="bold">
              Status
            </StackItem>
            <StackItem w={["calc(10% - 4px)"]} fontWeight="bold">
              Imported
            </StackItem>
            <StackItem w={["calc(30% - 4px)"]} fontWeight="bold">
              Options
            </StackItem>

            <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
              Created
            </StackItem>
            <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
              Completed
            </StackItem>
          </HStack>

          <VStack w="100%" gap={1} mt={1} divider={<Divider />}>
            {imports.map((importJob) => (
              <HStack
                key={`import-${importJob.id}`}
                p={2}
                w="100%"
                gap="4px"
                spacing={0}
                flexDir="row"
                flexWrap="wrap"
                cursor="pointer"
                alignItems="flex-start"
                onClick={() => {
                  setActiveImport(importJob);
                  onOpen();
                }}
              >
                <StackItem w={["calc(10% - 4px)"]} isTruncated>
                  {importJob.type}
                </StackItem>

                <StackItem w={["calc(10% - 4px)"]} isTruncated>
                  <ImportStatusBadge status={importJob.status} />
                </StackItem>

                <StackItem w={["calc(10% - 4px)"]} isTruncated>
                  {importJob.importedRows}
                </StackItem>

                <StackItem w={["calc(30% - 4px)"]}>
                  <VStack gap={1} w="100%">
                    {Object.entries(importJob.options).map(([key, value]) => (
                      <HStack key={`${importJob.id}:${key}`} w="100%">
                        <Box>
                          <Text color="whiteAlpha">{key}:</Text>
                        </Box>
                        <Box flex={1}>
                          {typeof value === "object"
                            ? value.name
                            : value.toString()}
                        </Box>
                      </HStack>
                    ))}
                  </VStack>
                </StackItem>

                <StackItem w={["calc(20% - 4px)"]}>
                  {importJob.created
                    ? new Date(importJob.created).toLocaleString()
                    : null}
                </StackItem>

                <StackItem w={["calc(20% - 4px)"]}>
                  {importJob.status === "pending" && (
                    <Progress
                      size="xs"
                      isIndeterminate
                      colorScheme={"orange"}
                    />
                  )}
                  {importJob.status === "processing" && (
                    <Progress
                      size="xs"
                      value={progressHash[importJob.id]?.percent || 0}
                      colorScheme={
                        progressHash[importJob.id]?.percent !== 100
                          ? "blue"
                          : "green"
                      }
                    />
                  )}

                  {importJob.completed
                    ? new Date(importJob.completed).toLocaleString()
                    : null}
                </StackItem>
              </HStack>
            ))}
          </VStack>
          {total > PAGE_SIZE && (
            <Pager
              currentPage={page}
              totalPages={Math.ceil(total / PAGE_SIZE)}
              onPageChange={onPageChange}
            />
          )}
        </>
      )}

      {isOpen && (
        <ImportModal
          isOpen={isOpen}
          onClose={closeModal}
          job={activeImport}
          accountId={account.id}
        />
      )}
    </Box>
  );
};
