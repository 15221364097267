import { Box, Flex } from "@chakra-ui/react";
import { ApiAccount } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QuickFilterButton } from "../../../components/buttons/QuickFilterButton";
import { SearchBox } from "../../../components/inputs/Search";
import { RootState } from "../../../store";
import { updateFilters } from "../../../store/user/users.slice";
import { debounce } from "../../../utils/debounce";
import { AccountFilter } from "./AccountFilter";

export const FirstSectionUsersListFilters: FC = () => {
  const { filters } = useSelector((state: RootState) => state.users);

  const dispatch = useDispatch();
  const handleIncludeInactive = useCallback(
    (includeInactive: boolean) => {
      dispatch(updateFilters({ includeInactive }));
    },
    [dispatch]
  );

  return (
    <Flex w="full" gap={2}>
      <QuickFilterButton
        label="Include Inactive"
        isActive={filters.includeInactive}
        onClick={() => handleIncludeInactive(!filters.includeInactive)}
        rest={{ mr: 2 }}
      />
    </Flex>
  );
};

export const SecondSectionUsersListFilters: FC = () => {
  const { filters } = useSelector((state: RootState) => state.users);

  const dispatch = useDispatch();
  const updateAccountFilter = useCallback(
    (values: ApiAccount[]) => {
      dispatch(updateFilters({ accounts: [...values.map((x) => x.id)] }));
    },
    [dispatch]
  );

  const onSearchChange = useCallback(
    (value: string | undefined) => {
      dispatch(updateFilters({ search: value, page: 1 }));
    },
    [dispatch]
  );

  const debouncedOnSearchChange = debounce(onSearchChange, 400);

  return (
    <Flex w="full" gap={4} flexDir={["column", "column", "row"]}>
      <Box w={["full", "full", "300px"]}>
        <SearchBox
          initialValue={filters.search}
          searchPlaceholder="Search"
          onInputChange={debouncedOnSearchChange}
        />
      </Box>

      <Box w={["full", "full", "260px"]}>
        <AccountFilter
          value={filters.accounts}
          onChange={updateAccountFilter}
        />
      </Box>
    </Flex>
  );
};
