import { Button, ButtonProps, Icon, useColorModeValue } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { MdCheck } from "react-icons/md";

interface QuickButtonProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  rest?: ButtonProps;
}

export const QuickFilterButton: FC<QuickButtonProps> = ({
  isActive,
  label,
  onClick,
  rest,
}) => {
  const buttonBgColor = useColorModeValue("transparent", "whiteAlpha.300");
  const borderColor = useColorModeValue("gray.300", "whiteAlpha.600");
  const textColor = useColorModeValue("blue.500", "white");
  const blueBg = useColorModeValue("blue.500", "blue.300");

  const quickButtonStyles = useMemo(() => {
    return {
      borderRadius: 50,
      border: "1px solid",
      color: isActive ? "white" : textColor,
      borderColor: isActive ? "transparent" : borderColor,
      backgroundColor: isActive ? blueBg : buttonBgColor,
      _hover: {
        bgColor: isActive ? blueBg : buttonBgColor,
        borderColor: blueBg,
      },
    };
  }, [buttonBgColor, borderColor, isActive, textColor, blueBg]);

  return (
    <Button
      onClick={onClick}
      sx={quickButtonStyles}
      size="sm"
      maxH="28px"
      {...rest}
    >
      {isActive && <Icon as={MdCheck} mr={2} />}
      {label}
    </Button>
  );
};
