import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiAccountUser } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { useCallback, useMemo } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { AdditionalNumberBadge } from "../badges/AdditionalNumberBadge";
import { UserBadge } from "../badges/UserBadge";
import "./react-dropdown-styles.css";

export interface StateFilterProps {
  values: (string | string)[];
  options?: ApiAccountUser[];
  onDropdownOpen?: () => void;
  onDropdownClose?: () => void;
  onChange: (value: ApiAccountUser[]) => void;
}

const ContentRenderer = ({ state }: SelectRenderer<ApiAccountUser>) => {
  return (
    <Box p={1} d="flex" w="max-content" alignItems="center">
      {state.values.length === 0 && "Select User(s)"}
      {state.values.length === 1 && (
        <Box>
          <UserBadge value={state.values[0]} />
        </Box>
      )}
      {state.values.length > 1 && (
        <>
          <UserBadge value={state.values[0]} />
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiAccountUser>) => {
  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(props.options), ["firstName", "lastName"], {
        sort: true,
      }),
    [props.options]
  );

  const items = useMemo(() => {
    return !state.search ? props.options : fuzzySearch.search(state.search);
  }, [state.search, props.options, fuzzySearch]);

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box backgroundColor={bgColor} p={2}>
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search States"
        />
      </Box>
      <Box>
        {items.map((item) => {
          return (
            <Checkbox
              key={item.id}
              isChecked={methods.isSelected(item)}
              onChange={() => methods.addItem(item)}
              w="100%"
              p={2}
            >
              <UserBadge value={item} />
            </Checkbox>
          );
        })}
      </Box>
    </Box>
  );
};

export const UserMultiCheckboxSelect = ({
  values,
  onChange,
  options,
  onDropdownOpen,
  onDropdownClose,
}: StateFilterProps) => {
  const themeClass = useColorModeValue(
    "react-dropdown-light-theme",
    "react-dropdown-dark-theme"
  );

  const handleChange = useCallback(
    (values: ApiAccountUser[]) => {
      onChange(values);
    },
    [onChange]
  );

  return (
    <Select
      multi
      options={options || []}
      className={themeClass}
      onChange={handleChange}
      searchable={true}
      searchBy="label"
      valueField="id"
      dropdownGap={2}
      keepSelectedInList
      dropdownHeight="350px"
      onDropdownOpen={onDropdownOpen}
      onDropdownClose={onDropdownClose}
      dropdownRenderer={DropdownRenderer}
      contentRenderer={(props) => ContentRenderer({ ...props })}
      values={options?.filter((opt) => values.includes(opt.id)) || []}
    />
  );
};
