import {
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { ApiOrganization } from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { TextInputControl } from "../../components/form-helpers/TextInputControl";
import { useShowToast } from "../../hooks/showToast";
import { useThunkDispatch } from "../../store";
import { updateOrganizationName } from "../../store/organization/organizations.slice";
import { initOrganizations } from "../../store/organization/thunks/organizations-init.thunk";

interface OrganizationFormProps {
  organization?: ApiOrganization;
  isOpen: boolean;
  onClose: () => void;
}

export const OrganizationForm: FC<OrganizationFormProps> = ({
  organization,
  isOpen,
  onClose,
}) => {
  const { apiInternal } = useAuthentication();
  const showToast = useShowToast();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const createOrganization = useCallback(
    (values: { name: string }) => {
      if (organization) {
        apiInternal
          .updateOrganization(organization.id, values.name)
          .then((response) => {
            showToast("success", "Organization was updated successfully");
            dispatch(updateOrganizationName(response));
            onClose();
          })
          .catch(() =>
            showToast(
              "error",
              "Something happened while updating current organization"
            )
          );
        return;
      }
      apiInternal
        .createOrganization(values.name)
        .then(() => {
          onClose();
          showToast("success", "Organization was created successfully");
          thunkDispatch(initOrganizations({ apiInternal }));
        })
        .catch(() =>
          showToast("error", "Something happened while creating organization")
        );
      return;
    },
    [apiInternal, dispatch, onClose, organization, showToast, thunkDispatch]
  );

  const OrganizationFormSchema = yup.object().shape({
    name: yup.string().required("Name is required").nullable(),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize="3xl">
            {organization ? "Edit Organization" : "Create Organization"}
          </Heading>
          <Divider mt={4} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              name: organization ? organization.name : "",
            }}
            onSubmit={createOrganization}
            validationSchema={OrganizationFormSchema}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <VStack gap={4} p={2}>
                  <TextInputControl
                    name="name"
                    value={values.name}
                    label="Name:"
                  />
                  <HStack justifyContent="space-between" width="100%">
                    <Button onClick={onClose} variant="outline">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={isSubmitting}
                    >
                      Submit
                    </Button>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
