import {
  Badge,
  Box,
  Divider,
  Heading,
  HStack,
  StackItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiWorkflowInternal } from "@operations-hero/lib-api-client";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { QuickFilterButton } from "../../../components/buttons/QuickFilterButton";
import { SearchBox } from "../../../components/inputs/Search";
import { Pager } from "../../../components/pagination/Pager";
import { debounce } from "../../../utils/debounce";
import { AccountFormProps } from "../AccountForm";
import { WorkflowModal } from "./WorkflowModal";

const PAGE_SIZE = 10;
export const Workflows: FC<AccountFormProps> = ({ account }) => {
  const { apiInternal } = useAuthentication();

  const [workflows, setWorkflows] = useState<ApiWorkflowInternal[]>([]);
  const [search, setSearch] = useState("");
  const [includeInactive, setIncludeInactive] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [workingWorkflow, setWorkingWorkflow] = useState<ApiWorkflowInternal>();

  const headerBg = useColorModeValue("gray.100", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onSearchChange = useCallback((value: string | undefined) => {
    setSearch(value || "");
    setPage(1);
  }, []);

  const onClickWorkingWorkflow = useCallback(
    async (sub: ApiWorkflowInternal) => {
      setWorkingWorkflow(sub);
      onOpen();
    },
    [onOpen]
  );

  const handleIncludeInactive = useCallback(
    (includeInactive: boolean) => {
      setIncludeInactive(includeInactive);
    },
    [setIncludeInactive]
  );

  const closeModal = useCallback(() => {
    setWorkingWorkflow(undefined);
    onClose();
  }, [onClose]);

  const debouncedSearchChange = debounce(onSearchChange, 300);

  useEffect(() => {
    apiInternal
      .findNextRequestWorkflows(account.id, {
        page,
        search,
        includeInactive,
        pageSize: PAGE_SIZE,
      })
      .then((work) => {
        setWorkflows(work.data);
        setTotal(work.total);
      });
  }, [apiInternal, account, page, search, includeInactive]);

  return (
    <Box>
      <HStack py={4} justifyContent="space-between">
        <Box>
          <Heading mb={2} size="lg">
            Worflows
          </Heading>
          <QuickFilterButton
            label="Inactive"
            isActive={includeInactive}
            onClick={() => handleIncludeInactive(!includeInactive)}
          />
        </Box>
        <Box>
          <SearchBox
            searchPlaceholder="Search"
            onInputChange={debouncedSearchChange}
          />
        </Box>
      </HStack>

      <HStack
        p={2}
        w="100%"
        gap="4px"
        spacing={0}
        flexDir="row"
        flexWrap="wrap"
        bgColor={headerBg}
        alignItems="center"
        d={["none", "none", "flex"]}
      >
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          Workflow
        </StackItem>
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          Slug
        </StackItem>
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          Last Request Number
        </StackItem>
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          Current Request Number
        </StackItem>
        <StackItem w={["calc(20% - 4px)"]} fontWeight="bold">
          Total Request
        </StackItem>
      </HStack>
      {workflows.map((workflow, idx) => (
        <Fragment key={workflow.id + idx}>
          <HStack
            py={[2, 2, 4]}
            w="100%"
            gap="4px"
            spacing={0}
            flexDir="row"
            flexWrap="wrap"
            cursor="pointer"
            alignItems="center"
            onClick={() => onClickWorkingWorkflow(workflow)}
          >
            <StackItem w={["calc(20% - 4px)"]} order={1} isTruncated>
              {workflow.name}
              {!workflow.active && (
                <Badge colorScheme="gray" ml={2} py={1}>
                  Inactive
                </Badge>
              )}
            </StackItem>

            <StackItem
              w={["calc(20% - 4px)"]}
              order={2}
              textAlign={["right", "right", "left"]}
            >
              {workflow.requestSlug}
            </StackItem>

            <StackItem
              w={["calc(20% - 4px)"]}
              order={[3, 3, 2]}
              textAlign={["right", "right", "left"]}
            >
              {workflow.requestCurrent}
            </StackItem>
            <StackItem
              w={["calc(20% - 4px)"]}
              order={[3, 3, 2]}
              textAlign={["right", "right", "left"]}
            >
              {workflow.currentRequestNumber}
            </StackItem>
            <StackItem w={["calc(20% - 4px)"]} order={[2, 2, 3]}>
              {workflow.totalRequest}
            </StackItem>

            <Divider order={5} />
          </HStack>
        </Fragment>
      ))}
      {isOpen && workingWorkflow && (
        <WorkflowModal
          isOpen={isOpen}
          onClose={closeModal}
          workingWorkflow={workingWorkflow}
          accountId={account.id}
        />
      )}
      <Pager
        currentPage={page}
        totalPages={Math.ceil(total / PAGE_SIZE)}
        onPageChange={onPageChange}
      />
    </Box>
  );
};
