import { Modal } from "@chakra-ui/modal";
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormikProps } from "formik";
import React, { FC, useCallback, useRef, useState } from "react";
import { FormValues, ReportForm } from "./ReportForm";

export interface ReportFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<any>;
  accountId: string;
}
export const ReportFormModal: FC<ReportFormModalProps> = ({
  isOpen,
  onClose,
  accountId,
  onSubmit,
}) => {
  const formRef = useRef<FormikProps<FormValues>>(null);

  const [isSaving, setIsSaving] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    if (formRef.current) {
      setIsSaving(true);
      await formRef.current?.submitForm();
      await onSubmit();
      setIsSaving(false);
      onClose();
    }
  }, [onClose, onSubmit]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{"Add Report"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReportForm formRef={formRef} accountId={accountId}></ReportForm>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" onClick={onClose} isDisabled={isSaving}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleOnSubmit}
            isLoading={isSaving}
            loadingText="Saving..."
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
