import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import {
  ApiAccountUser,
  ApiOrganization,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { UserMultiCheckboxSelect } from "../../components/selects/UserMultiCheckboxSelect";
import { useShowToast } from "../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../store";
import { loadOrganizationUsers } from "../../store/organization/accounts/thunks/organization-users.thunk";
import { UserView } from "./OrganizationsList";

interface OrganizationUsersProps {
  organization: ApiOrganization;
  onClose?: () => void;
  changeUsersView?: (view: UserView) => void;
}

export const AddOrganizationUserRole: FC<OrganizationUsersProps> = ({
  onClose,
  organization,
  changeUsersView,
}) => {
  const [accountsUsers, setAccountsUsers] = useState<ApiAccountUser[]>([]);
  const [usersToAdd, setUsersToAdd] = useState<ApiAccountUser[]>([]);

  const { organizationUsers, accounts } = useSelector(
    (state: RootState) => state.organizationAccountSlice
  );

  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { apiClient, apiInternal } = useAuthentication();

  const getAllAccountsUsers = useCallback(async () => {
    if (accounts.length === 0) return;

    const productsRoles = await apiClient.findProductRoles(accounts[0].id);
    // const orgRoles = productsRoles.filter((rol) =>
    //   rol.name.toLowerCase().includes("admin")
    // );

    // setRoles(orgRoles);
    const rolesIds = productsRoles
      .filter((rol) => rol.product.name !== "PortalHQ")
      .map((rol) => rol.id);

    const result: ApiAccountUser[] = [];
    await Promise.all(
      accounts.map(async (account) => {
        const users = (
          await apiClient.findAccountUsers(account.id, {
            productRoles: rolesIds,
            pageSize: 100,
          })
        ).data;
        result.push(...users);
      })
    );

    const filteredUsers = result.filter(
      (user) => !organizationUsers.usersHash[user.id]
    );
    setAccountsUsers(filteredUsers);
  }, [accounts, apiClient, organizationUsers.usersHash]);

  const handleOnChangeUsers = useCallback((users: ApiAccountUser[]) => {
    setUsersToAdd(users);
  }, []);

  const handleSaveUsers = useCallback(async () => {
    const usersIdsAndProducts = usersToAdd.map((u) => ({
      id: u.id,
      products: u.products,
    }));
    try {
      await Promise.all(
        usersIdsAndProducts.map((item) =>
          apiInternal.updateOrganizationUserRole(
            organization.id,
            item.id,
            item.products
          )
        )
      );

      thunkDispatch(
        loadOrganizationUsers({
          apiInternal,
          orgId: organization.id,
          options: {},
        })
      );
      changeUsersView && changeUsersView("list");
    } catch {
      showToast("error", "Something went wrong please try again!");
    }
  }, [
    apiInternal,
    changeUsersView,
    organization.id,
    showToast,
    thunkDispatch,
    usersToAdd,
  ]);

  useEffect(() => {
    getAllAccountsUsers();
  }, [getAllAccountsUsers]);

  return (
    <Flex w="100%" flexDir="column" gap={4}>
      <Flex w="full" flexDir="column" gap={4}>
        <Box w="100%">
          <UserMultiCheckboxSelect
            options={accountsUsers}
            onChange={handleOnChangeUsers}
            values={usersToAdd.map((u) => u.id)}
          />
        </Box>
      </Flex>
      <Divider />
      <Flex gap={4} justifyContent="flex-end">
        <Button
          size="sm"
          colorScheme="blue"
          variant="outline"
          onClick={() => onClose && onClose()}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={handleSaveUsers}
          disabled={usersToAdd.length === 0}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
