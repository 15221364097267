import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import React, { FC } from "react";

export interface NumberInputControlProps {
  name: string;
  label?: string;
  prefix?: string;
  helperText?: string;
  value: number | null;
  placeholder?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  labelFontWeight?: string;
}

export const NumberInputControl: FC<NumberInputControlProps> = ({
  name,
  value,
  placeholder,
  label,
  helperText,
  prefix,
  isDisabled,
  isReadOnly,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta] = useField({
    name,
    value: value === null ? undefined : value,
    placeholder,
  });

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        {prefix && <InputLeftAddon children={prefix} />}
        <NumberInput
          w="100%"
          name={name}
          precision={2}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          value={field.value}
          placeholder={placeholder}
        >
          <NumberInputField {...field} />
        </NumberInput>
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
