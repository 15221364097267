import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { ApiProduct } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { SYSTEM_MULTI_PRODUCT_REPORT } from "../../utils/reports";
import { StyledSelect } from "../selects/StyledSelect";

interface ProductSelectControlProps {
  label: string;
  name: string;
  value?: string;
  placeholder?: string;
  isReadOnly?: boolean;
}

export const ProductSelectControl: FC<ProductSelectControlProps> = ({
  label,
  name,
  value,
  placeholder,
  isReadOnly,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({ name, value });

  const { products } = useSelector((state: RootState) => state.auth);
  const { workingAccount } = useSelector((state: RootState) => state.accounts);

  const ProductsOptions = useMemo(() => {
    const multi: ApiProduct = {
      id: SYSTEM_MULTI_PRODUCT_REPORT.id,
      name: "Misc.",
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      active: true,
    };

    let finalProducts: ApiProduct[] = [...products];

    if (workingAccount?.subscriptions) {
      finalProducts = products.filter((prod) =>
        workingAccount.subscriptions.find(
          (sub) => sub.product.name === prod.name && sub.active
        )
      );
    }

    finalProducts = [...finalProducts, multi];

    const options = Object.values(finalProducts).map((prod) => ({
      label: prod.name,
      value: prod.id,
    }));

    return options;
  }, [products, workingAccount]);

  const handleOnChange = useCallback(
    (value: { label: string; value: string } | null) => {
      helper.setTouched(true);
      helper.setValue(value?.value);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {ProductsOptions.length > 0 ? (
        <StyledSelect
          {...field}
          onChange={handleOnChange}
          options={ProductsOptions}
          placeholder={placeholder}
          isDisabled={isReadOnly}
        />
      ) : (
        <Text color="gray.500">No Options</Text>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
