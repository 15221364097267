import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { combineReducers } from "redux";
import reports from "./account/account-reports.slice";
import accounts from "./account/accounts.slice";
import auth from "./auth.slice";
import organizationAccountSlice from "./organization/accounts/organizations-accounts.slice";
import organizations from "./organization/organizations.slice";
import users from "./user/users.slice";
import keys from "./keys/keys.slice";

const reducer = combineReducers({
  auth,
  accounts,
  users,
  organizations,
  organizationAccountSlice,
  reports,
  keys,
});

const store = configureStore({ reducer });

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();

export default store;
