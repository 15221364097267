import { ApiAccountSubscription } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiInternalService } from "../../../services/api-internal/internal-service";
import { AccountSliceState } from "../accounts.slice";

export interface AddAccountSubscriptionProps {
  apiInternal: ApiInternalService;
  acctId: string;
  payload: Partial<ApiAccountSubscription>;
}

export const addAccountSubscription = createAsyncThunk(
  "accounts/add-subscription",
  async (
    { apiInternal, acctId, payload }: AddAccountSubscriptionProps,
    thunkAPI
  ) => {
    const response = await apiInternal.addSubscription(acctId, payload);
    return { response };
  }
);

export const addAccountSubscriptionHandlers = (
  builder: ActionReducerMapBuilder<AccountSliceState>
) => {
  builder.addCase(addAccountSubscription.fulfilled, (state, action) => {
    const { response } = action.payload;
    state.workingAccount?.subscriptions.push(response);
  });
};
