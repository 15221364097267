import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ApiInvoiceInternal } from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { NumberInputControl } from "../../../components/form-helpers/NumberControl";
import { useShowToast } from "../../../hooks/showToast";

const InvoiceSchema = yup.object().shape({
  currentInvoiceNumber: yup.number().required("Current Invoice is required"),
});
export interface InvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  invoice: ApiInvoiceInternal;
  accountId: string;
}

export const InvoiceModal: React.FC<InvoiceModalProps> = ({
  isOpen,
  onClose,
  invoice,
  accountId,
}) => {
  const { apiInternal } = useAuthentication();
  const showToast = useShowToast();

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleOnSubmit = useCallback(
    async (values: ApiInvoiceInternal) => {
      try {
        await apiInternal.updateInvoiceNumberInternal(accountId, {
          invoiceNumber: values.currentInvoiceNumber,
        });
        showToast("success", "Current Invoice Number was updated succesfully");
        invoice.currentInvoiceNumber = values.currentInvoiceNumber
          ? values.currentInvoiceNumber
          : invoice.currentInvoiceNumber;
        handleOnClose();
      } catch {
        showToast(
          "error",
          "Something happened while updating Current Invoice Number "
        );
      }
    },
    [accountId, apiInternal, handleOnClose, invoice, showToast]
  );

  return (
    <>
      {invoice ? (
        <Modal isOpen={isOpen} onClose={handleOnClose} closeOnEsc={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{"Edit Invoice"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box w="100%">
                <Formik
                  initialValues={invoice}
                  validationSchema={InvoiceSchema}
                  onSubmit={handleOnSubmit}
                >
                  {({ values, isValid }) => {
                    return (
                      <Form>
                        <Grid templateColumns={"repeat(12, 1fr)"} gap={5}>
                          <GridItem colSpan={12}>
                            <NumberInputControl
                              name="currentInvoiceNumber"
                              label="Current Invoice"
                              value={
                                values.currentInvoiceNumber
                                  ? values.currentInvoiceNumber
                                  : null
                              }
                            />
                          </GridItem>
                          <GridItem colSpan={6}>
                            <Button onClick={handleOnClose}>Cancel</Button>
                          </GridItem>
                          <GridItem colSpan={6}>
                            <Button
                              float="right"
                              type="submit"
                              isDisabled={!isValid}
                            >
                              Save
                            </Button>
                          </GridItem>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};
