import { Button } from "@chakra-ui/react";
import React, { MouseEvent, useCallback } from "react";
import { useShowToast } from "../../../hooks/showToast";
import {
  deactivateSubscription,
  reactivateSubscription,
} from "../../../store/account/thunks/account-deactivate-subscription.thunk";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

type SubscriptionActionsProps = {
  subscriptionId: string;
  active: boolean;
};

export const SubscriptionActions = ({
  subscriptionId,
  active,
}: SubscriptionActionsProps) => {
  const showToast = useShowToast();
  const { apiInternal } = useAuthentication();
  const account = useSelector((x: RootState) => x.accounts.workingAccount);

  const thunkDispatch = useThunkDispatch();

  const handleDeactivateSubscription = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (account) {
        thunkDispatch(
          deactivateSubscription({
            acctId: account.id,
            apiInternal,
            subId: subscriptionId,
          })
        )
          .then(unwrapResult)
          .then(() => {
            showToast("success", "Subscription was deactivated successfully");
          })
          .catch(() => {
            showToast(
              "error",
              "something went wrong deactivating a subscription"
            );
          });
      }
    },
    [subscriptionId, account, apiInternal, showToast, thunkDispatch]
  );

  const handleReactivateSubscription = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (account) {
        thunkDispatch(
          reactivateSubscription({
            acctId: account.id,
            apiInternal,
            subId: subscriptionId,
          })
        )
          .then(unwrapResult)
          .then(() => {
            showToast("success", "Subscription was reactivated successfully");
          })
          .catch(() => {
            showToast(
              "error",
              "something went wrong reactivating a subscription"
            );
          });
      }
    },
    [subscriptionId, account, apiInternal, showToast, thunkDispatch]
  );

  return (
    <>
      {active ? (
        <Button
          size="xs"
          variant="outline"
          colorScheme="red"
          onClick={handleDeactivateSubscription}
        >
          Deactivate
        </Button>
      ) : (
        <Button
          size="xs"
          variant="solid"
          colorScheme="blue"
          onClick={handleReactivateSubscription}
        >
          Reactivate
        </Button>
      )}
    </>
  );
};
