import { HStack, Icon, Link, Stack } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { Dispatch, SetStateAction, useMemo } from "react";
import { GoMail } from "react-icons/go";
import { ApiUserSummarySession } from "../../components/badges/UserBadge";
import { Card } from "../../components/card-with-tags/Card";
import { CardContent } from "../../components/card-with-tags/CardContent";
import { CardHeader } from "../../components/card-with-tags/CardHeader";
import { UserAvatar } from "../../components/card-with-tags/UserAvatar";
import { LogoutUserTrigger } from "./account-users/LogoutUserTrigger";

export type AccountOwnerCardProps = {
  user: ApiUserSummarySession;
  currentUserId: string;
  workingUser: ApiUserSummary | null;
  setWorkingUser: Dispatch<SetStateAction<ApiUserSummary | null>>;
};

export const AccountOwnerCard = ({
  user,
  currentUserId,
  workingUser,
  setWorkingUser,
}: AccountOwnerCardProps) => {
  const userFullName = useMemo(
    () => `${user.firstName} ${user.lastName}`,
    [user]
  );

  return (
    <Card>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "4", md: "10" }}
      >
        <UserAvatar name={userFullName} src={user.profileImage} />
        <CardContent>
          <CardHeader title={userFullName} action={<></>} />
          <Stack spacing="1" mt="2">
            <HStack fontSize="sm">
              <Icon as={GoMail} color="gray.500" />
              <Link href={`mailto:${user.email}`} colorScheme={"blue"}>
                {user.email}
              </Link>
            </HStack>
            {user.isActiveSession !== undefined &&
              user.isActiveSession &&
              user.id !== currentUserId && (
                <LogoutUserTrigger
                  workingUser={user}
                  setWorkingUser={setWorkingUser}
                  trigger="button"
                  label="Logout"
                  buttonProps={{ maxW: "80px" }}
                />
              )}
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
};
