import {
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalContentProps,
} from "@chakra-ui/react";
import { FC } from "react";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  content: React.ReactNode;
  contentProps?: ModalContentProps;
}

export const CustomModal: FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  content,
  contentProps,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} onEsc={onClose}>
      <ModalOverlay />
      <ModalContent {...contentProps}>
        <ModalHeader display="flex" flexDir="column" gap={4}>
          {typeof title === "string" ? (
            <Heading size="lg">{title}</Heading>
          ) : (
            title
          )}
          <Divider />
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody mb={4}>{content}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
