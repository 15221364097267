import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiLogoutBoxRLine, RiMoonLine, RiSunLine } from "react-icons/ri";
import { useAuthentication } from "../../auth/AuthProvider";
import { UserBadge } from "../../badges/UserBadge";

export const AccountMenu = () => {
  const { currentUser: user } = useAuthentication();

  const { colorMode, toggleColorMode } = useColorMode();
  const showUserName = useBreakpointValue({ base: false, md: true });

  const handleColorModeChange = useCallback(() => {
    toggleColorMode();
  }, [toggleColorMode]);

  return (
    <>
      <Menu closeOnBlur={true} offset={[0, 0]} closeOnSelect={true}>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          <UserBadge value={user} hideName={!showUserName} />
        </MenuButton>
        <MenuList zIndex={10}>
          <MenuItem onClick={handleColorModeChange}>
            {colorMode === "light" ? (
              <>
                <Icon as={RiMoonLine} mr={2} />
                <Text>Switch to dark mode</Text>
              </>
            ) : (
              <>
                <Icon as={RiSunLine} mr={2} />
                <Text>Switch to light mode</Text>
              </>
            )}
          </MenuItem>

          <MenuDivider />

          <MenuItem as="a" href={`${process.env.REACT_APP_AUTH_SERVER}/logout`}>
            <Icon as={RiLogoutBoxRLine} mr={2} />
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
