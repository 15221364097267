import { Button, Grid, GridItem, useToast } from "@chakra-ui/react";
import {
  RESTRICTED_DOMAINS,
  UpdateApiAccount,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikHelpers } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { CountrySelectControl } from "../../components/form-helpers/CountrySelectControl";
import { StateSelectControl } from "../../components/form-helpers/StateControl";
import { TextInputControl } from "../../components/form-helpers/TextInputControl";
import { TextInputControlGroup } from "../../components/form-helpers/TextInputControlGroup";
import { TimezoneSelectControl } from "../../components/form-helpers/TimezoneSelectControl";
import MetadataSection from "../../components/metadata-section/MetadataSection";
import { AccountsData } from "../../services/api-internal/models/AccountData";
import { updateAccount } from "../../store/account/accounts.slice";

export const DOMAIN_REGEX =
  /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/;

export interface AccountFormProps {
  account: AccountsData;
}

type AccountInitialValues = {
  name: string;
  fiscalStartMonth: number;
  autoJoinDomains: string[];
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  };
  metadata: Record<string, string>;
  timeZone: string | null;
};

export const AccountForm: FC<AccountFormProps> = ({ account }) => {
  const { apiClient } = useAuthentication();

  const toast = useToast();
  const dispatch = useDispatch();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required().max(100),
        fiscalStartMonth: yup
          .number()
          .optional()
          .oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
          .nullable(),
        autoJoinDomains: yup.array().of(
          yup
            .string()
            .matches(DOMAIN_REGEX, "Invalid domain format")
            .test(
              "notRestricted",
              "Change domain, this is a Restricted domain",
              (value) =>
                !RESTRICTED_DOMAINS.map((domain) =>
                  domain.toLowerCase()
                ).includes(value ? value.toLowerCase() : "")
            )
        ),
      }),
    []
  );

  const initialValues = useMemo(
    () => ({
      name: account.name || "",
      fiscalStartMonth: account.fiscalStartMonth || 0,
      autoJoinDomains:
        account.autoJoinDomains.length === 0 ? [""] : account.autoJoinDomains,
      address: {
        address1: account.address.address1 || "",
        address2: account.address.address2 || "",
        city: account.address.city || "",
        state: account.address.state || "",
        country: account.address.country || "",
        zip: account.address.zip || "",
      },
      metadata: account.metadata,
      timeZone: account.timeZone || null,
    }),
    [account]
  );

  const handleFormikSubmit = useCallback(
    (
      values: UpdateApiAccount,
      { setFieldValue }: FormikHelpers<AccountInitialValues>
    ) => {
      return apiClient
        .updateAccount(account.id, {
          ...values,
          fiscalStartMonth: values.fiscalStartMonth || null,
        })
        .then(
          (account) => {
            setFieldValue("autoJoinDomains", account.autoJoinDomains);
            dispatch(updateAccount(account));
            toast({
              duration: 1500,
              isClosable: true,
              position: "top",
              status: "success",
              title: "Account Profile Updated",
            });
          },
          () => {
            toast({
              duration: 1500,
              isClosable: true,
              position: "top",
              status: "error",
              title: "Error updating account",
            });
          }
        );
    },
    [apiClient, account, toast, dispatch]
  );

  return (
    <Formik
      onSubmit={handleFormikSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem
              colSpan={12}
              display="grid"
              gridTemplateColumns="1fr"
              gap={3}
            >
              <TextInputControlGroup
                addText="Add domain"
                label="Auto Join Domains"
                values={values.autoJoinDomains ? values.autoJoinDomains : []}
                name="autoJoinDomains"
                prefix="@"
                helperText="Users who login via Google or Microsoft with an email from this domain are automatically added as standard users"
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <TimezoneSelectControl
                name="timeZone"
                label="Time Zone"
                placeholder="Select the account timezone"
                value={values.timeZone || undefined}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 6]}>
              <TextInputControl
                name="fiscalStartMonth"
                label="Start Month For Fiscal Year"
                value={
                  values.fiscalStartMonth === 0
                    ? null
                    : values.fiscalStartMonth?.toString() || null
                }
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6]}>
              <TextInputControl
                name="address.address1"
                label="Address"
                value={values.address?.address1 || null}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6]}>
              <TextInputControl
                name="address.address2"
                label="Second Address"
                value={values.address?.address2 || null}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6]}>
              <CountrySelectControl
                label="Country"
                name="address.country"
                value={values.address ? values.address.country : null}
                allowEmpty
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6]}>
              <StateSelectControl
                name="address.state"
                value={values.address?.state ? values.address.state : null}
                country={
                  values.address?.country ? values.address.country : "US"
                }
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6]}>
              <TextInputControl
                name="address.city"
                label="City"
                value={values.address?.city || null}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6]}>
              <TextInputControl
                name="address.zip"
                label="Zip"
                value={values.address?.zip || null}
              />
            </GridItem>

            <GridItem colSpan={12}>
              <MetadataSection
                metadata={values.metadata || {}}
                setFieldValue={setFieldValue}
              />
            </GridItem>

            <GridItem colSpan={12}>
              <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                Update Account
              </Button>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
