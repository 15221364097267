import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  OrganizationSliceState,
  FindOrganizationsThunkParams,
} from "../organizations.slice";

export const initOrganizations = createAsyncThunk(
  "organizations/init",
  async ({ apiInternal }: FindOrganizationsThunkParams, thunkAPI) => {
    const { filters } = (thunkAPI.getState() as RootState).organizations;
    const [organizations] = await Promise.all([
      apiInternal.findOrganizations(filters),
    ]);
    return { organizations };
  }
);

export const organizationsInitHandlers = (
  builder: ActionReducerMapBuilder<OrganizationSliceState>
) => {
  builder.addCase(initOrganizations.fulfilled, (state, action) => {
    const {
      organizations: { data, options, total },
    } = action.payload;
    state.organizations = data;
    state.filters.page = options.page;
    state.filters.total = total;
  });
};
