import {
  Button,
  Divider,
  Flex,
  Icon,
  MenuItem,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { FC, SetStateAction, useCallback } from "react";
import { IoStar } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { UserBadge } from "../../../components/badges/UserBadge";
import { CustomModal } from "../../../components/modal/CustomModal";
import { useShowToast } from "../../../hooks/showToast";
import { AccountsData } from "../../../services/api-internal/models/AccountData";
import { updateWorkingAccountOwner } from "../../../store/account/accounts.slice";

const DEFAULT_LABEL = "Make Owner";

interface MakeOwnerTriggerProps {
  label?: string;
  account: AccountsData;
  workingUser: ApiUserSummary | null;
  setWorkingUser: (value: SetStateAction<ApiUserSummary | null>) => void;
}

export const MakeOwnerTrigger: FC<MakeOwnerTriggerProps> = ({
  label,
  account,
  workingUser,
  setWorkingUser,
}) => {
  const disatch = useDispatch();
  const showToast = useShowToast();
  const { apiInternal } = useAuthentication();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const updateCurrentAccounrOwner = useCallback(() => {
    if (!workingUser) return;
    disatch(updateWorkingAccountOwner(workingUser));
  }, [disatch, workingUser]);

  const handleMakeOwner = useCallback(() => {
    if (!workingUser) return;

    apiInternal
      .updateAccountOwner(account.id, workingUser.id)
      .then(() => {
        showToast("success", `${account.name} owner was updated successfully`);
        updateCurrentAccounrOwner();
        setWorkingUser(null);
      })
      .catch(() => {
        showToast(
          "error",
          "Something went wrong updating account owner, please try again! "
        );
      })
      .finally(() => onClose());
  }, [
    account.id,
    account.name,
    apiInternal,
    onClose,
    setWorkingUser,
    showToast,
    updateCurrentAccounrOwner,
    workingUser,
  ]);

  const handleOnClose = useCallback(() => {
    setWorkingUser(null);
    onClose();
  }, [onClose, setWorkingUser]);

  return (
    <>
      <MenuItem onClick={onOpen}>
        <Icon as={IoStar} mr={2} />
        {label || DEFAULT_LABEL}
      </MenuItem>
      {isOpen && (
        <MakeOwnerModal
          isOpen={isOpen}
          account={account}
          onClose={handleOnClose}
          workingUser={workingUser}
          handleMakeOwner={handleMakeOwner}
        />
      )}
    </>
  );
};

interface MakeOwnerProps
  extends Omit<MakeOwnerTriggerProps, "label" | "setWorkingUser"> {
  isOpen: boolean;
  onClose: () => void;
  handleMakeOwner: () => void;
}

const MakeOwnerModal: FC<MakeOwnerProps> = ({
  account,
  workingUser,
  isOpen,
  onClose,
  handleMakeOwner,
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      content={
        <VStack gap={4} alignItems="flex-start" divider={<Divider />}>
          <Flex flexDir="column" minW="100%" gap={2}>
            <Flex flexDir="column">
              <Text fontWeight="semibold">Old</Text>
              <UserBadge value={account.owner} />
            </Flex>

            <Divider />

            {workingUser && (
              <Flex flexDir="column">
                <Text fontWeight="semibold">New</Text>
                <UserBadge value={workingUser} />
              </Flex>
            )}
          </Flex>

          <Flex w="100%" justifyContent="space-between">
            <Button onClick={onClose} variant="ghost">
              Cancel
            </Button>
            <Button onClick={handleMakeOwner} colorScheme="blue">
              Continue
            </Button>
          </Flex>
        </VStack>
      }
      title="Make Owner"
      onClose={onClose}
    />
  );
};
