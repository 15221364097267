import { Box, BoxProps } from "@chakra-ui/react";
import * as React from "react";

export const Card = (props: BoxProps) => (
  <Box
    maxWidth="2xl"
    p={{ base: "6", md: "8" }}
    rounded={{ sm: "lg" }}
    {...props}
  />
);
