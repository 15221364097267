import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AccountSliceState, FindAccountsThunkParams } from "../accounts.slice";

export const initAccounts = createAsyncThunk(
  "accounts/init",
  async ({ apiInternal, filters }: FindAccountsThunkParams, thunkAPI) => {
    const response = await apiInternal.findAccounts(filters);
    const data = response.data.map((acct) => ({
      ...acct,
      linkTo: `/accounts/${acct.id}`,
    }));
    return { ...response, data };
  }
);

export const accountsInitHandlers = (
  builder: ActionReducerMapBuilder<AccountSliceState>
) => {
  builder.addCase(initAccounts.fulfilled, (state, action) => {
    const { data, options, total } = action.payload;
    state.accounts = data;
    state.filters.page = options.page;
    state.total = total;
    !state.initialized && (state.initialized = true);
  });
};
