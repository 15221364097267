import {
  ApiAccountDetail,
  ApiAccountUser,
  ApiOrganization,
  ApiOrganizationOptions,
  FindOrganizationUserOptions,
} from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiInternalService } from "../../../services/api-internal/internal-service";
import { AccountsData } from "../../../services/api-internal/models/AccountData";
import { organizationsUsersLoadHandlers } from "./thunks/organization-users.thunk";
import { organizationsAccountsInitHandlers } from "./thunks/organizations-accounts-init.thunk";
import { organizationsAccountsToAddInitHandlers } from "./thunks/organizations-accounts-to-add-init.thunk";

export interface OrganizationAccountListFilters {
  page: number;
  pageSize: number;
  total: number;
  search: string;
}

export interface OrganizationSliceState {
  workingOrganization?: ApiOrganization;
  accounts: ApiAccountDetail[];
  filters: OrganizationAccountListFilters;
  accountsToAdd: AccountsData[];
  filtersAccountsToAdd: OrganizationAccountListFilters;
  organizationUsers: {
    usersHash: Record<string, ApiAccountUser>;
    filters: FindOrganizationUserOptions;
  };
}

export interface FindOrganizationAccountThunkParams {
  apiInternal: ApiInternalService;
  orgId?: string;
}

export interface FindOrganizationUsersParams {
  orgId: string;
  apiInternal: ApiInternalService;
  options: FindOrganizationUserOptions;
}

const INITIAL_FILTERS = {
  page: 1,
  total: 0,
  search: "",
  pageSize: 50,
};

export const organizationAccountSlice = createSlice({
  name: "organizations-accounts",
  initialState: {
    workingOrganization: undefined,
    accounts: [],
    filters: INITIAL_FILTERS,
    accountsToAdd: [],
    filtersAccountsToAdd: INITIAL_FILTERS,
    organizationUsers: {
      usersHash: {},
      filters: {
        includeInactive: false,
      },
    },
  } as OrganizationSliceState,
  reducers: {
    updateFilters: (
      state,
      action: PayloadAction<Partial<ApiOrganizationOptions>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    updateFiltersAccountsToAdd: (
      state,
      action: PayloadAction<Partial<ApiOrganizationOptions>>
    ) => {
      state.filtersAccountsToAdd = {
        ...state.filtersAccountsToAdd,
        ...action.payload,
      };
    },
    setWorkingOrganization: (state, action: PayloadAction<ApiOrganization>) => {
      state.workingOrganization = action.payload;
    },
    unload: (state) => {
      state.accounts = [];
      state.filters = INITIAL_FILTERS;
    },
    uloadOrganizationUsers: (state) => {
      state.organizationUsers = {
        usersHash: {},
        filters: { includeInactive: false },
      };
    },
    unloadModal: (state) => {
      state.accountsToAdd = [];
      state.filtersAccountsToAdd = INITIAL_FILTERS;
    },
  },

  extraReducers: (builder) => {
    organizationsAccountsInitHandlers(builder);
    organizationsAccountsToAddInitHandlers(builder);
    organizationsUsersLoadHandlers(builder);
  },
});

export const {
  updateFilters,
  setWorkingOrganization,
  updateFiltersAccountsToAdd,
  unload,
  unloadModal,
  uloadOrganizationUsers,
} = organizationAccountSlice.actions;

export default organizationAccountSlice.reducer;
