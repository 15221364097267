import { Tooltip, Text, TextProps } from "@chakra-ui/react";
import { format, formatRelative } from "date-fns";

export interface DateBadgeProps {
  date?: null | string | Date;
  showRelative?: boolean;
  hideTime?: boolean;
  showTimeTooltip?: boolean;
  rest?: TextProps;
}

export const DateBadge = ({
  date,
  showRelative = true,
  hideTime = false,
  showTimeTooltip = false,
  rest,
}: DateBadgeProps) => {
  let dateToShow: string | null = null;
  if (date) {
    dateToShow = showRelative
      ? formatRelative(new Date(date), new Date())
      : format(new Date(date), hideTime ? "MMMM d y" : "MMMM d y 'at' h:mmaaa");
  }
  let timeToShow: string | null = null;
  if (showTimeTooltip && date) {
    timeToShow = format(new Date(date), "h:mmaaa");
  }
  return !date ? null : (
    <>
      {showTimeTooltip ? (
        <Tooltip label={timeToShow}>{dateToShow}</Tooltip>
      ) : (
        <Text {...rest}>{dateToShow}</Text>
      )}
    </>
  );
};
