import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UseTableCellProps } from "react-table";
import { AccountModal } from "../../components/account-modal/AccountModal";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { UserBadge } from "../../components/badges/UserBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../components/data-table/DataTable";
import { SearchBox } from "../../components/inputs/Search";
import { PageData } from "../../components/pagination/PageData";
import { Pager } from "../../components/pagination/Pager";
import { useShowToast } from "../../hooks/showToast";
import { AccountsData } from "../../services/api-internal/models/AccountData";
import { RootState, useThunkDispatch } from "../../store/index";
import {
  unload,
  updateFilters,
} from "../../store/organization/accounts/organizations-accounts.slice";
import { initOrganizationsAccounts } from "../../store/organization/accounts/thunks/organizations-accounts-init.thunk";
import { debounce } from "../../utils/debounce";
import { OrganizationAccounts } from "./OrganizationAccounts";
export const Organization: FC = () => {
  const { orgId } = useParams();

  const { apiInternal } = useAuthentication();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const showToast = useShowToast();
  const {
    isOpen: isRemoveModalOpen,
    onOpen: openRemoveModal,
    onClose: closeRemoveModal,
  } = useDisclosure();
  const {
    isOpen: isOpenAccounts,
    onOpen: onOpenAccounts,
    onClose: onCloseAccounts,
  } = useDisclosure();

  const { filters, accounts, workingOrganization } = useSelector(
    (state: RootState) => state.organizationAccountSlice
  );
  const { page, pageSize, total } = filters;

  const [workingAccount, setWorkingAccount] = useState<AccountsData>();
  const productBadgeColor = useColorModeValue("blue", "gray");

  const dateFormat = useBreakpointValue({
    base: "yyyy-MM-dd",
    sm: "yyyy-MM-dd",
    md: "yyyy-MMM-dd 'at' HH:mm",
  });

  const onPageChange = useCallback(
    (page: number) => {
      dispatch(updateFilters({ ...filters, page }));
    },
    [filters, dispatch]
  );

  const handleRemoveModal = useCallback(
    (account: AccountsData) => {
      if (!orgId) return;
      setWorkingAccount(account);
      openRemoveModal();
    },
    [orgId, setWorkingAccount, openRemoveModal]
  );

  const onSearchChange = useCallback(
    (value?: string) => {
      dispatch(updateFilters({ search: value, page: 1 }));
    },
    [dispatch]
  );

  const handleOnCloseModal = useCallback(() => {
    setWorkingAccount(undefined);
    closeRemoveModal();
  }, [setWorkingAccount, closeRemoveModal]);

  const handleRemoveOrganizationAccount = useCallback(async () => {
    if (!orgId || !workingAccount) return;
    await apiInternal
      .removeOrganizationAccounts(orgId, [workingAccount.id])
      .then(() => {
        showToast("success", "Account(s) successfully removed");
        thunkDispatch(initOrganizationsAccounts({ apiInternal }));
        handleOnCloseModal();
      })
      .catch(() =>
        showToast("error", "Something happened while removing account(s)")
      );
  }, [
    apiInternal,
    handleOnCloseModal,
    orgId,
    showToast,
    thunkDispatch,
    workingAccount,
  ]);

  const debouncedSearchChange = debounce(onSearchChange, 300);

  const columns = useMemo<DataTableColumn<AccountsData>[]>(
    () => [
      {
        Header: "Name",
        Cell: (data: UseTableCellProps<AccountsData>) => {
          return <Text isTruncated>{data.row.original.name}</Text>;
        },
      },
      {
        Header: "State",
        Cell: (data: UseTableCellProps<AccountsData>) => {
          return <Text isTruncated>{data.row.original.address.state}</Text>;
        },
      },
      {
        Header: "Owner",
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <UserBadge value={data.row.original.owner} />
        ),
      },
      {
        Header: "Created",
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <Text wordBreak="break-word" isTruncated>
            {format(new Date(data.row.original.created), dateFormat || "")}
          </Text>
        ),
      },
      {
        Header: "Products/Subscriptions",
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <Wrap w="100%">
            {data.row.original.subscriptions.slice(0, 4).map((sub) => (
              <WrapItem
                key={`account:${data.row.original.id}::subscription:${sub.product.name}`}
              >
                <Badge
                  colorScheme={
                    new Date(sub.expires).getTime() > new Date().getTime()
                      ? productBadgeColor
                      : "red"
                  }
                  borderRadius={8}
                  variant="subtle"
                >
                  {sub.product.name} - exp:
                  {new Date(sub.expires).toLocaleDateString()}
                </Badge>
              </WrapItem>
            ))}
            {data.row.original.subscriptions.length > 4 && (
              <WrapItem>
                <Badge colorScheme={productBadgeColor} borderRadius={8}>
                  {data.row.original.subscriptions.length - 4} more...
                </Badge>
              </WrapItem>
            )}
          </Wrap>
        ),
      },
      {
        Header: "Actions",
        maxWidth: 100,
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <Box w="100%" display="flex" justifyContent="flex-end" gap={2}>
            <Button
              size="xs"
              colorScheme="red"
              onClick={() => handleRemoveModal(data.row.original)}
            >
              Remove
            </Button>
          </Box>
        ),
      },
    ],
    [dateFormat, productBadgeColor, handleRemoveModal]
  );

  useEffect(() => {
    if (!orgId) return;
    thunkDispatch(initOrganizationsAccounts({ apiInternal }));
  }, [thunkDispatch, apiInternal, orgId, filters]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch]);

  return (
    <Stack w="100%">
      <Flex w="100%" justifyContent="space-between">
        <Heading size="lg" my={2}>
          {workingOrganization?.name}
        </Heading>
        <Flex justifyContent="flex-end" alignItems="center">
          <SearchBox
            searchPlaceholder="Search by Name or State"
            onInputChange={debouncedSearchChange}
          />
          <Button
            w="50%"
            size="md"
            mt={2}
            mr={4}
            ml={2}
            colorScheme="blue"
            onClick={() => onOpenAccounts()}
          >
            <Icon as={MdAdd} mr={1} /> Add Accounts
          </Button>
        </Flex>
      </Flex>
      <DataTable columns={columns} data={accounts} rest={{ w: "100%" }} />
      <Flex flexWrap="wrap" justifyContent="space-between">
        <PageData total={total} currentPage={page} pageSize={pageSize} />
        <Pager
          currentPage={page}
          totalPages={Math.ceil(total / pageSize)}
          onPageChange={onPageChange}
        />
      </Flex>
      {workingAccount && isRemoveModalOpen && (
        <AccountModal
          isOpen={isRemoveModalOpen}
          title={`${workingAccount.name}`}
          onClose={handleOnCloseModal}
          contentProps={{ maxW: "2xl" }}
          content={
            <Box>
              <Text>Are you sure to remove this account?</Text>
              <Flex w="100%" mt={4} justifyContent="space-between">
                <Button
                  onClick={handleRemoveOrganizationAccount}
                  colorScheme="red"
                >
                  Remove
                </Button>
                <Button onClick={handleOnCloseModal}>Cancel</Button>
              </Flex>
            </Box>
          }
        />
      )}
      {workingOrganization && isOpenAccounts && (
        <OrganizationAccounts
          organizationId={workingOrganization.id}
          isOpen={isOpenAccounts}
          onClose={onCloseAccounts}
        />
      )}
    </Stack>
  );
};
