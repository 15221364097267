import { ApiAccount, ApiKey } from "@operations-hero/lib-api-client";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ApiInternalService } from "../../services/api-internal/internal-service";
import { AccountsData } from "../../services/api-internal/models/AccountData";

export interface KeyListFilters {
  page: number;
  pageSize: number;
  total: number;
  search: string;
  includeInactive: boolean;
}

export interface KeyAccountListFilters {
  page: number;
  pageSize: number;
  total: number;
  search: string;
}

export interface KeySliceState {
  workingKey?: ApiKey;
  accounts: ApiAccount[];
  accountsToAdd: AccountsData[];
  apiKeys: ApiKey[];
  filtersAccountsToAdd: KeyAccountListFilters;
  filters: KeyListFilters;
}

export interface ApiKeySliceState {
  data: ApiKey[];
  filters: KeyAccountListFilters;
}

export interface FindKeyThunkParams {
  apiInternal: ApiInternalService;
}

export interface FindKeyAccountThunkParams {
  apiInternal: ApiInternalService;
  keyId?: string;
}

const INITIAL_FILTERS = {
  page: 1,
  total: 0,
  search: "",
  pageSize: 20,
  includeInactive: false,
};

const ACCOUNT_INITIAL_FILTERS = {
  page: 1,
  total: 0,
  search: "",
  pageSize: 50,
};

export const initKeysAccountsToAdd = createAsyncThunk(
  "keys-accounts/accounts-to-add/init",
  async ({ apiInternal }: FindKeyAccountThunkParams, thunkAPI) => {
    const { workingKey, filtersAccountsToAdd } = (
      thunkAPI.getState() as RootState
    ).keys;

    if (!workingKey?.id) return Promise.reject();
    const accounts = await apiInternal.findAccounts({
      ...filtersAccountsToAdd,
    });
    return { accounts };
  }
);

export const loadKeys = createAsyncThunk(
  "keys-accounts/load-keys",
  async ({ apiInternal }: FindKeyAccountThunkParams, thunkAPI) => {
    const { workingKey, filtersAccountsToAdd } = (
      thunkAPI.getState() as RootState
    ).keys;

    if (!workingKey?.id) return Promise.reject();
    const keys = await apiInternal.findApiKeys({
      ...filtersAccountsToAdd,
    });
    return { keys };
  }
);

export const keysLoadHandlers = createAsyncThunk(
  "keys-accounts/accounts-to-add/added",
  async (params: FindKeyAccountThunkParams) => {
    let accounts: ApiAccount[] = [];

    if (params?.keyId) {
      const keyId = await params.apiInternal.getApiKeyDetail(params.keyId);
      accounts = keyId.accounts;
    }
    return { accounts };
  }
);

export const reloadApiKey = createAsyncThunk(
  "keys-accounts/accounts-to-add/reload",
  async ({ apiInternal, keyId }: FindKeyAccountThunkParams, thunkAPI) => {
    if (!keyId) return Promise.reject();
    const apiKey = await apiInternal.getApiKeyId(keyId);
    return apiKey;
  }
);

export const initApiKeys = createAsyncThunk(
  "keys-accounts/api-keys/init",
  async ({ apiInternal }: FindKeyAccountThunkParams, thunkAPI) => {
    const { filters } = (thunkAPI.getState() as RootState).keys;

    const keyArray = await apiInternal.findApiKeys({
      search: filters.search,
      page: filters.page,
      pageSize: filters.pageSize,
    });
    return { keyArray };
  }
);

export const keySlice = createSlice({
  name: "keys",
  initialState: {
    workingKey: undefined,
    accounts: [],
    filters: INITIAL_FILTERS,
    filtersAccountsToAdd: ACCOUNT_INITIAL_FILTERS,
    accountsToAdd: [],
    apiKeys: [],
  } as KeySliceState,
  reducers: {
    updateFilters: (state, action: PayloadAction<Partial<KeyListFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setKey: (state, action: PayloadAction<ApiKey>) => {
      state.workingKey = action.payload;
    },
    updateFiltersAccountsToAdd: (
      state,
      action: PayloadAction<Partial<KeyAccountListFilters>>
    ) => {
      state.filtersAccountsToAdd = {
        ...state.filtersAccountsToAdd,
        ...action.payload,
      };
    },
    unload: (state) => {
      state.accounts = [];
      state.filters = INITIAL_FILTERS;
    },
    unloadModal: (state) => {
      state.accountsToAdd = [];
      state.filtersAccountsToAdd = INITIAL_FILTERS;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(initKeysAccountsToAdd.fulfilled, (state, action) => {
      const {
        accounts: { data, options, total },
      } = action.payload;
      state.accountsToAdd = data;
      state.filters.page = options.page || 1;
      state.filters.total = total;
    });
    builder.addCase(reloadApiKey.fulfilled, (state, action) => {
      const apiKey = action.payload;
      state.workingKey = apiKey;
    });
    builder.addCase(keysLoadHandlers.fulfilled, (state, action) => {
      state.accounts = action.payload.accounts;
    });
    builder.addCase(initApiKeys.fulfilled, (state, action) => {
      const {
        keyArray: { data, options, total },
      } = action.payload;
      state.apiKeys = data;
      state.filters.page = options.page || 1;
      state.filters.total = total;
    });
  },
});

export const {
  updateFilters,
  setKey,
  updateFiltersAccountsToAdd,
  unload,
  unloadModal,
} = keySlice.actions;

export default keySlice.reducer;
