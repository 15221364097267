import {
  ApiOrganization,
  ApiOrganizationOptions,
} from "@operations-hero/lib-api-client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiInternalService } from "../../services/api-internal/internal-service";
import { organizationsLoadHandlers } from "./thunks/organization-load.thunk";
import { organizationsInitHandlers } from "./thunks/organizations-init.thunk";

export interface OrganizationListFilters {
  page: number;
  pageSize: number;
  total: number;
  search: string;
  includeInactive: boolean;
}

export interface OrganizationSliceState {
  organizations: ApiOrganization[];
  filters: OrganizationListFilters;
}

export interface FindOrganizationsThunkParams {
  apiInternal: ApiInternalService;
}

const INITIAL_FILTERS = {
  page: 1,
  total: 0,
  search: "",
  pageSize: 50,
  includeInactive: false,
};

export const oranizationSlice = createSlice({
  name: "organizations",
  initialState: {
    organizations: [],
    filters: INITIAL_FILTERS,
  } as OrganizationSliceState,
  reducers: {
    updateFilters: (
      state,
      action: PayloadAction<Partial<ApiOrganizationOptions>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    updateOrganizationName: (state, action: PayloadAction<ApiOrganization>) => {
      const newOrganization = state.organizations.find(
        (org) => org.id === action.payload.id
      );
      if (newOrganization?.name) newOrganization.name = action.payload.name;
    },
    updateOrganizations: (state, action: PayloadAction<ApiOrganization>) => {
      state.organizations.push(action.payload);
    },
    unloadOrganizations: (state) => {
      state.organizations = [];
      state.filters = INITIAL_FILTERS;
    },
  },

  extraReducers: (builder) => {
    organizationsInitHandlers(builder);
    organizationsLoadHandlers(builder);
  },
});

export const {
  updateFilters,
  updateOrganizationName,
  updateOrganizations,
  unloadOrganizations,
} = oranizationSlice.actions;

export default oranizationSlice.reducer;
