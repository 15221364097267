import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useField, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { getCustomSelectComponents } from "../selects/select-overrides";

const OPTIONS = [
  { value: "yearly", label: "Yearly" },
  { value: "monthly", label: "Monthly" },
];

export interface SubscriptionControlProps {
  label: string;
  name: string;
  value: string | null;
  isDisabled?: boolean;
}

export const SubscriptionControl: FC<SubscriptionControlProps> = ({
  name,
  value,
  label,
  isDisabled,
}) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const components = {
    ...getCustomSelectComponents(),
    IndicatorSeparator: null,
  };

  const handleOnChange = useCallback(
    (value: string | null) => {
      helper.setTouched(true);
      helper.setValue(value || null);
    },
    [helper]
  );

  const handleOnBlur = useCallback(() => {
    helper.setTouched(true);
  }, [helper]);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        {...field}
        name={name}
        options={OPTIONS}
        onBlur={handleOnBlur}
        isDisabled={isDisabled}
        components={components}
        value={OPTIONS.find((opt) => opt.value === field.value)}
        onChange={(newValue) => handleOnChange(newValue?.value || null)}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
