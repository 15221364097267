import { HStack, StackItem } from "@chakra-ui/react";
import { ApiReport } from "@operations-hero/lib-api-client";
import React, { FC } from "react";
import { ReportActions } from "./ReportActions";

export type ReportItemProps = {
  report: ApiReport;
};
export const ReportItem: FC<ReportItemProps> = ({ report }) => (
  <HStack
    py={[2, 2, 4]}
    px={2}
    w="100%"
    gap="4px"
    spacing={0}
    display="grid"
    gridTemplateAreas={[
      "'product action' 'dashboard dashboard'",
      "'product action' 'dashboard dashboard'",
      "'product dashboard action'",
    ]}
    gridTemplateColumns={["50% 50%", "50% 50%", "20% 50% 1fr"]}
  >
    <StackItem gridArea="product">{report.product.name}</StackItem>
    <StackItem gridArea="dashboard">{report.dashboard.name}</StackItem>
    <StackItem
      gridArea="action"
      display="flex"
      justifyContent={["flex-end", "flex-end", "flex-start"]}
    >
      <ReportActions active={report.active} reportId={report.id} />
    </StackItem>
  </HStack>
);
