import { FC, useCallback, useMemo } from "react";
import {
  SelectLabelValue,
  StyledSelect,
} from "../../components/selects/StyledSelect";

const DAYS_EXPIRE = ["30", "60", "90", "120"];

interface DaysToExpireFilterProps {
  value?: number;
  onChange: (value?: number) => void;
}

export const DaysToExpireFilter: FC<DaysToExpireFilterProps> = ({
  value,
  onChange,
}) => {
  const options = useMemo(
    () => DAYS_EXPIRE.map((item) => ({ value: item, label: item })),
    []
  );

  const handleOnChangeFilter = useCallback(
    (selectValue: SelectLabelValue | null) => {
      if (selectValue === null) {
        onChange(undefined);
        return;
      }
      const newValue = parseInt(selectValue.value);
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <StyledSelect
      isClearable
      options={options}
      placeholder="Expire in"
      onChange={handleOnChangeFilter}
      value={value ? `${value}` : null}
    />
  );
};
