import {
  Button,
  ButtonProps,
  Icon,
  MenuItem,
  MenuItemProps,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { FC, SetStateAction, useCallback } from "react";
import { IoGitMerge } from "react-icons/io5";
import { CustomModal } from "../../../components/modal/CustomModal";
import { AccountsData } from "../../../services/api-internal/models/AccountData";
import { MergeUsers } from "../../users/MergeUsers";

interface MergeUsersTriggerProps {
  account: AccountsData;
  label: string;
  buttonProps?: ButtonProps;
  menuItemProps?: MenuItemProps;
  trigger: "button" | "menuItem";
  workingUser: ApiUserSummary | null;
  setWorkingUser: (value: SetStateAction<ApiUserSummary | null>) => void;
}

export const MergeUsersTrigger: FC<MergeUsersTriggerProps> = ({
  label,
  trigger,
  account,
  workingUser,
  setWorkingUser,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnOpen = useCallback(
    (user: ApiUserSummary | null) => {
      user && setWorkingUser(user);
      onOpen();
    },
    [onOpen, setWorkingUser]
  );

  const handleOnClose = useCallback(() => {
    workingUser && setWorkingUser(null);
    onClose();
  }, [onClose, setWorkingUser, workingUser]);

  return (
    <>
      {trigger === "button" && (
        <Button onClick={() => handleOnOpen(null)} size="sm">
          {label}
        </Button>
      )}

      {trigger === "menuItem" && (
        <MenuItem onClick={() => handleOnOpen(null)}>
          <Icon as={IoGitMerge} mr={2} />
          {label}
        </MenuItem>
      )}

      {isOpen && (
        <MergeUsersModal
          isOpen={isOpen}
          account={account}
          onClose={handleOnClose}
          workingUser={workingUser}
        />
      )}
    </>
  );
};

interface MergeUserModalProps
  extends Omit<
    MergeUsersTriggerProps,
    "trigger" | "setWorkingUser" | "onOpen" | "label"
  > {
  isOpen: boolean;
  onClose: () => void;
}

const MergeUsersModal: FC<MergeUserModalProps> = ({
  account,
  isOpen,
  onClose,
  workingUser,
}) => {
  return (
    <>
      {isOpen && (
        <CustomModal
          title={`Merge ${account.name} users`}
          isOpen={isOpen}
          onClose={onClose}
          contentProps={{ maxW: "2xl" }}
          content={
            <MergeUsers
              accountId={account.id}
              selectedUser={workingUser}
              onCloseMergeModal={onClose}
            />
          }
        />
      )}
    </>
  );
};
