import {
  Badge,
  Box,
  Flex,
  HStack,
  Link,
  StackItem,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiAccountUser,
  ApiUserSummary,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RoleBadge } from "../../../components/badges/RoleBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { QuickFilterButton } from "../../../components/buttons/QuickFilterButton";
import { SearchBox } from "../../../components/inputs/Search";
import { Pager } from "../../../components/pagination/Pager";
import { AccountsData } from "../../../services/api-internal/models/AccountData";
import { UserMenu } from "./UserMenu";

const PAGE_SIZE = 10;

export interface ApiAccountUserSession extends ApiAccountUser {
  isActiveSession?: boolean;
}

enum Products {
  EventsHQ = "Events",
  HeroHQ = "HeroHQ",
  Inventory = "InventoryHQ",
}

export const AccountUsers = ({ account }: { account: AccountsData }) => {
  const { apiClient } = useAuthentication();
  const [users, setUsers] = useState<ApiAccountUserSession[]>([]);
  const [workingUser, setWorkingUser] = useState<ApiUserSummary | null>(null);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const linkColor = useColorModeValue("blue.500", "blue.300");
  const { HeroHQ, Inventory, EventsHQ } = Products;

  const hasSubscription = useMemo(() => {
    const subscriptions: Record<string, boolean> = {};
    account.subscriptions.forEach((subscription) => {
      subscriptions[subscription.product.name] = true;
    });
    return subscriptions;
  }, [account]);
  const totalSubscriptions = account.subscriptions.length;

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onSearchChange = useCallback((value: string | undefined) => {
    setSearch(value || "");
    setPage(1);
  }, []);

  const handleIncludeInactive = useCallback(
    (includeInactive: boolean) => {
      setIncludeInactive(includeInactive);
    },
    [setIncludeInactive]
  );

  const handleUpdateUsers = useCallback((values: ApiAccountUserSession[]) => {
    setUsers(values);
  }, []);

  useEffect(() => {
    apiClient
      .findAccountUsers(account.id, { page, search, includeInactive })
      .then((pagedUsers) => {
        setUsers(pagedUsers.data);
        setTotal(pagedUsers.total);
      });
  }, [apiClient, account, page, search, includeInactive]);

  // when page or search changes, this will reload users
  useEffect(() => {
    // page and search are passed as options to the API call
    apiClient
      .findAccountUsers(account.id, {
        page,
        search,
        pageSize: PAGE_SIZE,
        includeInactive,
      })
      .then((result) => {
        return setUsers(result.data);
      });
  }, [apiClient, account, page, search, includeInactive]);

  return (
    <Box>
      <Flex flexWrap="wrap" justifyContent={"space-between"}>
        <Box>{total} users</Box>

        <Flex
          minW={["100%", "100%", "50%"]}
          justifyContent="flex-end"
          alignItems="center"
        >
          <QuickFilterButton
            label="Inactive"
            isActive={includeInactive}
            onClick={() => handleIncludeInactive(!includeInactive)}
          />
          <Box ml={2}>
            <SearchBox
              searchPlaceholder="Search"
              onInputChange={onSearchChange}
            />
          </Box>
        </Flex>
      </Flex>

      <Box overflowX={["auto", null, null]}>
        <Box minWidth="860px">
          <HStack
            p={2}
            w="100%"
            display="grid"
            gridTemplateColumns={`18% 2fr ${"14% ".repeat(
              totalSubscriptions
            )} 12% 8%`}
          >
            <StackItem fontWeight="bold">Name</StackItem>
            <StackItem fontWeight="bold">Email</StackItem>
            {hasSubscription[HeroHQ] && (
              <StackItem fontWeight="bold">HeroHQ</StackItem>
            )}
            {hasSubscription[EventsHQ] && (
              <StackItem fontWeight="bold">EventHQ</StackItem>
            )}
            {hasSubscription[Inventory] && (
              <StackItem fontWeight="bold">Inventory</StackItem>
            )}
            <StackItem fontWeight="bold">Created</StackItem>
            <StackItem fontWeight="bold" textAlign="right">
              Action
            </StackItem>
          </HStack>

          {users.map((user) => (
            <HStack
              p={2}
              w="100%"
              display="grid"
              gridTemplateColumns={`18% 1.5fr ${"14% ".repeat(
                totalSubscriptions
              )} 12% 8%`}
              _odd={{ backgroundColor: "rgba(215,215,215, 0.1)" }}
              key={`AccountUser::${user.id}`}
            >
              <StackItem d="flex" flexWrap="wrap" alignItems="center">
                <UserBadge value={user} />
                {!user.products.find((x) => x.name === "HeroHQ")?.enabled &&
                  !user.products.find((x) => x.name === "Events")?.enabled && (
                    <Badge colorScheme="gray" ml={1}>
                      Inactive
                    </Badge>
                  )}
              </StackItem>
              <StackItem isTruncated>
                <Link href={`mailto:${user.email}`} color={linkColor}>
                  {user.email}
                </Link>
              </StackItem>
              {hasSubscription[HeroHQ] && (
                <StackItem>
                  <RoleBadge user={user} product="HeroHQ" />
                </StackItem>
              )}
              {hasSubscription[EventsHQ] && (
                <StackItem>
                  <RoleBadge user={user} product="Events" />
                </StackItem>
              )}
              {hasSubscription[Inventory] && (
                <StackItem>
                  <RoleBadge user={user} product="InventoryHQ" />
                </StackItem>
              )}
              <StackItem>
                {format(new Date(user.created), `yyyy-MM-dd`)}
              </StackItem>

              <StackItem
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <UserMenu
                  users={users}
                  rowUser={user}
                  account={account}
                  workingUser={workingUser}
                  setWorkingUser={setWorkingUser}
                  handleUpdateUsers={handleUpdateUsers}
                />
              </StackItem>
            </HStack>
          ))}
        </Box>
      </Box>

      <Pager
        currentPage={page}
        totalPages={Math.ceil(total / PAGE_SIZE)}
        onPageChange={onPageChange}
      />
    </Box>
  );
};
