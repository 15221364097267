import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiAccountDetail } from "@operations-hero/lib-api-client";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { PageData } from "../../components/pagination/PageData";
import { Pager } from "../../components/pagination/Pager";
import { useShowToast } from "../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../store";
import {
  unloadModal,
  updateFiltersAccountsToAdd,
} from "../../store/organization/accounts/organizations-accounts.slice";
import { initOrganizationsAccounts } from "../../store/organization/accounts/thunks/organizations-accounts-init.thunk";
import { initOrganizationsAccountsToAdd } from "../../store/organization/accounts/thunks/organizations-accounts-to-add-init.thunk";
import { debounce } from "../../utils/debounce";

export interface OrganizationAccountsProperties {
  organizationId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const OrganizationAccounts: FC<OrganizationAccountsProperties> = ({
  organizationId,
  isOpen,
  onClose,
}) => {
  const { apiInternal } = useAuthentication();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const { filtersAccountsToAdd, accountsToAdd } = useSelector(
    (state: RootState) => state.organizationAccountSlice
  );
  const { page, pageSize, total } = filtersAccountsToAdd;

  const [selectedAccounts, setSelectedAccounts] = useState<ApiAccountDetail[]>(
    []
  );

  const buttonBgColor = useColorModeValue("blue.500", "whiteAlpha.300");

  const accountsToAddIds = useMemo(
    () => accountsToAdd.map((ac) => ac.id),
    [accountsToAdd]
  );
  const handleOnClose = useCallback(() => {
    dispatch(unloadModal());
    onClose();
  }, [dispatch, onClose]);

  const AddRemoveAccounts = useCallback(() => {
    const accountsToAdd: string[] = selectedAccounts.map((sa) => sa.id);

    accountsToAdd.length > 0 &&
      apiInternal
        .addOrganizationAccounts(organizationId, accountsToAdd)
        .then(() => {
          showToast("success", "Account(s) successfully added");
          thunkDispatch(initOrganizationsAccounts({ apiInternal }));
        })
        .catch(() =>
          showToast("error", "Something happened while adding account(s)")
        );
    handleOnClose();
  }, [
    apiInternal,
    organizationId,
    showToast,
    thunkDispatch,
    selectedAccounts,
    handleOnClose,
  ]);

  const onSearchChange = useCallback(
    (value?: string) => {
      dispatch(updateFiltersAccountsToAdd({ search: value, page: 1 }));
    },
    [dispatch]
  );

  const debouncedSearchChange = debounce(onSearchChange, 300);

  const handleAddAccount = useCallback(
    (account) => {
      const newAccounts = [...selectedAccounts];
      newAccounts.push(account);
      setSelectedAccounts(newAccounts);
    },
    [selectedAccounts]
  );

  const handleRemoveAccount = useCallback(
    (account) => {
      const newAccounts = [...selectedAccounts];
      const index = newAccounts.indexOf(account);
      newAccounts.splice(index, 1);
      setSelectedAccounts(newAccounts);
    },
    [selectedAccounts]
  );

  const onPageChange = useCallback(
    (page: number) => {
      dispatch(updateFiltersAccountsToAdd({ page }));
    },
    [dispatch]
  );

  useEffect(() => {
    thunkDispatch(initOrganizationsAccountsToAdd({ apiInternal }));
  }, [thunkDispatch, apiInternal, filtersAccountsToAdd]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize="3xl">Add Accounts</Heading>
          <Divider py={4} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Fragment>
            <StackItem d="inline-flex" flexDir="column" width="100%">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={FaSearch} color="gray.300" />}
                />
                <Input
                  placeholder="Search accounts"
                  onChange={(e) => debouncedSearchChange(e.target.value)}
                />
              </InputGroup>
            </StackItem>

            <Divider pt={2} />

            <Box pt={4}>
              {accountsToAdd.map(
                (account) =>
                  !selectedAccounts.find((sa) => sa.id === account.id) && (
                    <StackItem
                      py={1}
                      w="100%"
                      d="flex"
                      fontSize="sm"
                      flexWrap="wrap"
                      key={`accounts:${account.id}`}
                      alignItems="flex-end"
                    >
                      <Text
                        as="span"
                        mb={["8px", "8px", 0]}
                        w="70%"
                        wordBreak="break-word"
                      >
                        {account.name}
                      </Text>
                      <Text
                        as="span"
                        mb={["8px", "8px", 0]}
                        w={["30%", "30%", "calc(30% - 128px)"]}
                      >
                        {account.address.state}
                      </Text>
                      <Button
                        ml={[0, 0, "8px"]}
                        w="120px"
                        size="xs"
                        color="white"
                        bgColor={buttonBgColor}
                        justifyContent="flex-end"
                        _hover={{ backgroundColor: "none" }}
                        onClick={() => handleAddAccount(account)}
                      >
                        <Icon as={FaPlus} mr={2} />
                        Add Account
                      </Button>
                    </StackItem>
                  )
              )}
            </Box>
            <Divider pt={4} />
            {selectedAccounts
              .filter((sa) => accountsToAddIds.includes(sa.id))
              .map((account) => (
                <Box pt={2} key={`selectedAccounts:${account.id}`}>
                  <StackItem w="70%">{account.name}</StackItem>
                  <StackItem w="calc(30% - 70px)">
                    {account.address.state}
                  </StackItem>
                  <Button
                    w="70px"
                    size="xs"
                    variant="outline"
                    borderColor={buttonBgColor}
                    onClick={() => handleRemoveAccount(account)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            {accountsToAdd.length > 0 && (
              <Fragment>
                <Divider pt={4} />
                <Flex flexWrap="wrap" justifyContent="space-between" pt={4}>
                  <PageData
                    total={total}
                    currentPage={page}
                    pageSize={pageSize}
                  />
                  <Pager
                    currentPage={page}
                    totalPages={Math.ceil(total / pageSize)}
                    onPageChange={onPageChange}
                  />
                </Flex>
              </Fragment>
            )}
          </Fragment>
        </ModalBody>

        <ModalFooter>
          <HStack width="100%" justifyContent="space-between">
            <Button variant="outline" mr={3} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={AddRemoveAccounts}>
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
