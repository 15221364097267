import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FC } from "react";

interface SwitchControlProps {
  label: string;
  name: string;
  value?: "true" | "false";
}

export const SwitchControl: FC<SwitchControlProps> = ({
  label,
  name,
  value,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta] = useField({ name, value });

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Switch {...field} isChecked={value === "true"} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
