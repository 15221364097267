import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Country, ICountry } from "country-state-city";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useMemo } from "react";
import { StyledSelect } from "../selects/StyledSelect";
interface CountryStatesSelectControlProps {
  label: string;
  name: string;
  value: string | null;
  placeholder?: string;
  allowEmpty?: boolean;
}

export const CountrySelectControl: FC<CountryStatesSelectControlProps> = ({
  label,
  name,
  value,
  placeholder,
  allowEmpty,
}) => {
  const { submitCount, setFieldValue } = useFormikContext();
  const [field, meta, helper] = useField({ name, value: value || "" });

  const contriesOptions = useMemo(
    () =>
      (
        [
          Country.getCountryByCode("US"),
          Country.getCountryByCode("CA"),
        ] as ICountry[]
      ).map((country: ICountry) => ({
        label: `${country.isoCode} - ${country.name}`,
        value: country.isoCode,
      })),
    []
  );

  const handleOnChange = useCallback(
    (valueSelector: { label: string; value: string } | null) => {
      const previousValue = value ? value : undefined;
      const newChange = valueSelector?.value;
      if (previousValue !== newChange) {
        setFieldValue("address.state", null);
      }
      helper.setTouched(true);
      helper.setValue(newChange);
    },
    [helper, setFieldValue, value]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <StyledSelect
        {...field}
        onChange={handleOnChange}
        options={contriesOptions}
        placeholder={placeholder}
        value={value}
        isClearable={allowEmpty}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
