import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { StyledSelect } from "../selects/StyledSelect";

interface ProductSelectControlProps {
  label: string;
  name: string;
  value?: string;
  placeholder?: string;
  isReadOnly?: boolean;
}

export const ProductSelectControl: FC<ProductSelectControlProps> = ({
  label,
  name,
  value,
  placeholder,
  isReadOnly,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({ name, value });

  const { workingAccount } = useSelector((state: RootState) => state.accounts);
  const { products } = useSelector((state: RootState) => state.auth);

  const productNames = isReadOnly
    ? workingAccount?.subscriptions
        .filter((s) => s.product.name !== value)
        .map((s) => s.product.name)
    : workingAccount?.subscriptions.map((s) => s.product.name);

  const ProductsOptions = useMemo(
    () =>
      Object.values(products)
        .map((prod) => ({
          label: prod.name,
          value: prod.name,
        }))
        .filter((prod) => !productNames?.includes(prod.value)),
    [products, productNames]
  );

  const handleOnChange = useCallback(
    (value: { label: string; value: string } | null) => {
      helper.setTouched(true);
      helper.setValue(value?.value);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {ProductsOptions.length > 0 ? (
        <StyledSelect
          {...field}
          onChange={handleOnChange}
          options={ProductsOptions}
          placeholder={placeholder}
          isDisabled={isReadOnly}
        />
      ) : (
        <Text color="gray.500">No Options</Text>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
