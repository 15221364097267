import {
  Button,
  ButtonProps,
  Icon,
  MenuItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { IoLogOut } from "react-icons/io5";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { CustomModal } from "../../../components/modal/CustomModal";
import { useShowToast } from "../../../hooks/showToast";
import { LogoutUserConfirmation } from "../../users/LogoutUserConfirmation";

interface LogoutUserTriggerProps {
  label: string;
  trigger: "button" | "menuItem";
  workingUser: ApiUserSummary | null;
  setWorkingUser: Dispatch<SetStateAction<ApiUserSummary | null>>;
  buttonProps?: ButtonProps;
}

export const LogoutUserTrigger: FC<LogoutUserTriggerProps> = ({
  label,
  trigger,
  workingUser,
  setWorkingUser,
  buttonProps,
}) => {
  const showToast = useShowToast();
  const { apiInternal } = useAuthentication();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const logoutColor = useColorModeValue("red.500", "red.300");

  const handleOnOpen = useCallback(() => {
    setWorkingUser(workingUser);
    onOpen();
  }, [onOpen, setWorkingUser, workingUser]);

  const handleOnCloseLogout = useCallback(() => {
    setWorkingUser(null);
    onClose();
  }, [onClose, setWorkingUser]);

  const handleConfirmLogoutUser = useCallback(() => {
    workingUser &&
      apiInternal
        .forceLogout({
          userId: workingUser.id,
        })
        .then(() => {
          showToast("success", "User logout successufully");
        })
        .catch(() => {
          showToast("error", "something went wrong, please try again!");
        });
    handleOnCloseLogout();
  }, [apiInternal, showToast, workingUser, handleOnCloseLogout]);

  return (
    <>
      {trigger === "button" && (
        <Button
          size="sm"
          variant="outline"
          colorScheme="red"
          {...buttonProps}
          onClick={handleOnOpen}
        >
          {label}
        </Button>
      )}

      {trigger === "menuItem" && (
        <MenuItem color={logoutColor} onClick={handleOnOpen}>
          <Icon as={IoLogOut} mr={2} />
          {label}
        </MenuItem>
      )}

      {isOpen && (
        <ConfirmationModalWrapper
          isOpen={isOpen}
          onClose={handleOnCloseLogout}
          handleConfirmLogoutUser={handleConfirmLogoutUser}
        />
      )}
    </>
  );
};

interface ConfirmationModalWrapperProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmLogoutUser: () => void;
}

const ConfirmationModalWrapper: FC<ConfirmationModalWrapperProps> = ({
  isOpen,
  onClose,
  handleConfirmLogoutUser,
}) => {
  return (
    <>
      {isOpen && (
        <CustomModal
          title="Logout User Confirmation"
          isOpen={isOpen}
          onClose={onClose}
          contentProps={{ maxW: "xl" }}
          content={
            <LogoutUserConfirmation
              onClose={onClose}
              onLogout={handleConfirmLogoutUser}
            />
          }
        />
      )}
    </>
  );
};
