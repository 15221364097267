import { CSSObject, useColorModeValue } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { getCustomSelectComponents } from "./select-overrides";
import { CustomSelectComponetProp } from "./select-overrides-types";

export interface SelectLabelValue {
  label: string;
  value: string;
}
export interface StyledSelectProps {
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  onChange?: (item: SelectLabelValue) => void;
  options?: SelectLabelValue[];
  placeholder?: string;
  value?: string | SelectLabelValue | null;
  defaultValue?: string;
  name?: string;
}

export const StyledSelect = ({
  isClearable,
  isDisabled,
  isMulti,
  onChange,
  options,
  placeholder,
  value,
  name,
}: StyledSelectProps) => {
  const bgColor = useColorModeValue("white", "transparent");
  const handleChange = useCallback(
    (item: any | null) => {
      if (onChange) onChange(item);
    },
    [onChange]
  );

  const customStyles = {
    control: (provided: CSSObject) => ({
      ...provided,
      backgroundColor: bgColor,
    }),
  };

  const components = useMemo((): CustomSelectComponetProp => {
    return {
      ...getCustomSelectComponents(),
    };
  }, []);

  const selectValue = useMemo(() => {
    if (typeof value === "string" && options) {
      return options.find((opt) => opt.value === value);
    }
    return value;
  }, [options, value]);

  return (
    <Select
      chakraStyles={customStyles}
      isDisabled={isDisabled}
      value={selectValue}
      options={options}
      onChange={handleChange}
      isClearable={isClearable}
      isMulti={isMulti}
      placeholder={placeholder}
      name={name}
      components={components}
    />
  );
};
