import { Buffer } from "buffer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface UseQueryStringFilterOptions<T> {
  defaultValue: T;
  pathname?: string;
  qsParamName?: string;
}

export function useQueryStringFilter<T>({
  defaultValue,
  pathname,
  qsParamName = "filter",
}: UseQueryStringFilterOptions<T>) {
  const history = useNavigate();
  const { pathname: currentPathname, search } = useLocation();
  const [filterInitialize, setFilterInitialize] = useState(false);

  const isUpdating = useRef(false);
  const [filter, setFilter] = useState<T>(defaultValue);

  const globalQuery = useMemo(() => new URLSearchParams(search), [search]);

  const filterParam = useMemo(() => {
    const queryString = new URLSearchParams(search);
    return queryString.get(qsParamName);
  }, [search, qsParamName]);

  const updateQueryString = useCallback(
    (newValue: T) => {
      isUpdating.current = true;
      setFilter(newValue);

      //@ts-ignore
      const cleaned = Object.entries(newValue).reduce<Partial<T>>(
        (result, [key, value]) => {
          if (Array.isArray(value) && value.length === 0) {
            return result;
          }

          if (typeof value === "string" && !value) {
            return result;
          }

          if (value === null || typeof value === "undefined") {
            return result;
          }

          // @ts-ignore
          result[key] = value;
          return result;
        },
        {}
      );

      if (Object.keys(cleaned).length === 0) {
        globalQuery.delete(qsParamName);
        history({
          pathname: pathname || currentPathname,
          search: globalQuery.toString(),
        });
        return;
      }

      const base64filter = Buffer.from(JSON.stringify(cleaned)).toString(
        "base64"
      );
      globalQuery.set(qsParamName, base64filter);
      history({
        pathname: pathname || currentPathname,
        search: globalQuery.toString(),
      });
    },
    [globalQuery, qsParamName, history, pathname, currentPathname]
  );

  const hydratePartial = useCallback(
    (parsed: Partial<T>) => {
      //@ts-ignore
      const result = Object.entries(defaultValue).reduce<T>(
        (result, [key, value]) => {
          // @ts-ignore;
          const parseValue = parsed[key];

          if (Array.isArray(value) && !parseValue) {
            // @ts-ignore;
            parsed[key] = [];
            return result;
          }

          if (typeof value === "string" && !parseValue) {
            // @ts-ignore;
            parsed[key] = "";
            return result;
          }

          if (value === null && !parseValue) {
            // @ts-ignore;
            parsed[key] = null;
            return result;
          }

          return result;
        },
        // This is being built into a full T from a Partial<T>
        parsed as T
      );

      return result;
    },
    [defaultValue]
  );

  useEffect(() => {
    // this prevents a circular loop
    if (isUpdating.current) {
      isUpdating.current = false;
      return;
    }

    // initial load, need to see if the url has a value for us to set
    if (!filterParam) {
      setFilterInitialize(true);
      return;
    }

    try {
      const parsedFilter: Partial<T> = JSON.parse(
        Buffer.from(filterParam, "base64").toString()
      );
      // need to merge back in empty arrays and empty strings;
      const reverseCleaned = hydratePartial(parsedFilter);
      setFilter(reverseCleaned);
    } catch (e) {
    } finally {
      setFilterInitialize(true);
    }
  }, [filterParam, hydratePartial, filterInitialize]);

  const cleanFilter = useCallback(
    (defaultValues: T, qsParamName: string) => {
      setFilter(defaultValues);
      globalQuery.delete(qsParamName);
      const newGlobalQuery = globalQuery.toString();

      history({
        pathname: currentPathname,
        search: newGlobalQuery,
      });
    },
    [currentPathname, globalQuery, history]
  );

  return {
    filterInitialized: filterInitialize,
    filter,
    cleanFilter,
    updateQueryString,
  };
}
