import {
  Box,
  Divider,
  Heading,
  HStack,
  StackItem,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiAccountEventSummary } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AccountsData } from "../../../services/api-internal/models/AccountData";
import { UpdateCounterModal } from "./UpdateCounterModal";

type EventSummaryProps = {
  account: AccountsData;
};

export const EventSummary: FC<EventSummaryProps> = ({ account }) => {
  const { apiInternal } = useAuthentication();

  const [summary, setSummary] = useState<ApiAccountEventSummary>();

  const headerBg = useColorModeValue("gray.100", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    apiInternal.getEventSummary(account.id).then((response) => {
      setSummary(response);
    });
  }, [apiInternal, account]);

  return (
    <Box>
      <HStack py={4} justifyContent="space-between">
        <Box>
          <Heading mb={2} size="lg">
            Events
          </Heading>
        </Box>
      </HStack>

      <HStack
        p={2}
        w="100%"
        gap="4px"
        spacing={0}
        flexDir="row"
        flexWrap="wrap"
        bgColor={headerBg}
        alignItems="center"
        d={["none", "none", "flex"]}
      >
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Event
        </StackItem>
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Last Event Number
        </StackItem>
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Current Event Number
        </StackItem>
        <StackItem w={["calc(25% - 4px)"]} fontWeight="bold">
          Total Events
        </StackItem>
      </HStack>
      {summary && (
        <HStack
          py={[2, 2, 4]}
          w="100%"
          gap="4px"
          spacing={0}
          flexDir="row"
          flexWrap="wrap"
          cursor="pointer"
          alignItems="center"
          onClick={onOpen}
        >
          <StackItem w={["calc(25% - 4px)"]} order={1} isTruncated>
            Event
          </StackItem>

          <StackItem
            w={["calc(25% - 4px)"]}
            order={[3, 3, 2]}
            textAlign={["right", "right", "left"]}
          >
            {summary.maxEventKeyUsed}
          </StackItem>
          <StackItem
            w={["calc(25% - 4px)"]}
            order={[3, 3, 2]}
            textAlign={["right", "right", "left"]}
          >
            {summary.eventKeyCounter}
          </StackItem>
          <StackItem w={["calc(25% - 4px)"]} order={[2, 2, 3]}>
            {summary.totalEvents}
          </StackItem>

          <Divider order={5} />
        </HStack>
      )}

      {summary && (
        <UpdateCounterModal
          isOpen={isOpen}
          onClose={closeModal}
          event={summary}
          accountId={account.id}
        />
      )}
    </Box>
  );
};
