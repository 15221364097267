import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { FC, useCallback } from "react";
import {
  Column,
  Row,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import { CustomLink } from "../inputs/CustomLink";

export type DataTableColumn<T extends object> = Column<T> & {
  boxProps?: BoxProps;
};

export interface DataTableProps<T extends object> {
  columns: DataTableColumn<T>[];
  data: T[];
  hiddenColumns?: string[];
  hiddenHeader?: boolean;
  displayBorderBottom?: boolean;
  onRowClick?: (item: T) => void;
  rest?: BoxProps;
  tableHeaderProps?: BoxProps;
}

export const DataTable = ({
  columns,
  data,
  hiddenColumns,
  displayBorderBottom = true,
  hiddenHeader = false,
  onRowClick,
  rest,
  tableHeaderProps,
}: DataTableProps<any>) => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: { hiddenColumns: hiddenColumns || [] },
      },
      useFlexLayout,
      useResizeColumns,
      useSortBy
    );

  const borderColor = useColorModeValue("gray.200", "gray.700");

  const handleRowClick = useCallback(
    (row: Row<object>) => {
      return () => {
        if (onRowClick) {
          onRowClick(row.original);
        }
      };
    },
    [onRowClick]
  );

  return (
    <Box overflowX="auto" pb={4} {...rest}>
      <Box {...getTableProps()}>
        <Box>
          {!hiddenHeader &&
            headerGroups.map((headerGroup) => (
              <Box {...headerGroup.getHeaderGroupProps()} {...tableHeaderProps}>
                {headerGroup.headers.map((column) => (
                  <Box
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    display="inline-block"
                    borderColor={borderColor}
                    p={1}
                    fontWeight={"bold"}
                  >
                    <Flex justify="space-between">
                      <Box
                        w="100%"
                        textAlign={
                          column.render("Header")?.toString().includes("Action")
                            ? "right"
                            : "left"
                        }
                      >
                        {column.render("Header")}
                      </Box>
                      <Box
                        px="2"
                        color={column.isSorted ? "inherit" : borderColor}
                      >
                        {column.canSort ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Box>
            ))}
        </Box>

        <Box {...getTableBodyProps()} pt={2}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <TableRow
                row={row}
                onRowClick={onRowClick}
                handleRowClick={handleRowClick}
                key={`${row.original.id}::${idx}`}
                displayBorderBottom={displayBorderBottom}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

interface TableRowProps<T extends object> {
  row: Row<any>;
  displayBorderBottom?: boolean;
  onRowClick?: (item: T) => void;
  handleRowClick: (row: Row<object>) => () => void;
}

const TableRow: FC<TableRowProps<any>> = ({
  row,
  onRowClick,
  handleRowClick,
  displayBorderBottom = true,
}) => {
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return row.original.linkTo ? (
    <CustomLink to={row.original.linkTo}>
      <Box {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <Box
            display="inline-block"
            borderBottom={displayBorderBottom ? "1px" : "none"}
            borderColor={borderColor}
            p={2}
            {...cell.getCellProps()}
            isTruncated={true}
            {...(cell.column as DataTableColumn<any>).boxProps}
          >
            {cell.render("Cell")}
          </Box>
        ))}
      </Box>
    </CustomLink>
  ) : (
    <Box
      {...row.getRowProps()}
      onClick={handleRowClick(row)}
      cursor={onRowClick ? "pointer" : ""}
    >
      {row.cells.map((cell) => (
        <Box
          display="inline-block"
          borderBottom={displayBorderBottom ? "1px" : "none"}
          borderColor={borderColor}
          p={2}
          {...cell.getCellProps()}
          isTruncated={true}
          {...(cell.column as DataTableColumn<any>).boxProps}
        >
          {cell.render("Cell")}
        </Box>
      ))}
    </Box>
  );
};
