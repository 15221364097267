import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useEffect, useMemo } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "../../components/account-header/PageHeader";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../store";
import { unloadOrganizations } from "../../store/organization/organizations.slice";
import { loadOrganizations } from "../../store/organization/thunks/organization-load.thunk";
import { initOrganizations } from "../../store/organization/thunks/organizations-init.thunk";
import { OrganizationForm } from "../organizations/OrganizationForm";
import {
  OrganizationsFiltersFirstSection,
  OrganizationsFiltersSecondSection,
} from "./OrganizationListFilters";
import { OrganizationsList } from "./OrganizationsList";

export const Organizations: FC = () => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { apiInternal } = useAuthentication();

  const { filters } = useSelector((state: RootState) => state.organizations);
  const { search, page, includeInactive } = filters;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    thunkDispatch(initOrganizations({ apiInternal }));
  }, [apiInternal, thunkDispatch]);

  useEffect(() => {
    thunkDispatch(loadOrganizations({ apiInternal }));
  }, [search, page, includeInactive, thunkDispatch, apiInternal]);

  useEffect(() => {
    return () => {
      dispatch(unloadOrganizations());
    };
  }, [dispatch]);

  const pageTitle = useMemo(() => {
    return isMobile ? (
      <Flex alignItems="center" justifyContent="space-between" w="full">
        <Heading size="lg" w="max-content" isTruncated>
          Organizations
        </Heading>
        <Button onClick={onOpen} colorScheme="blue" size="sm">
          <Icon as={MdAdd} mr={1} /> Organization
        </Button>
      </Flex>
    ) : (
      "Organizations"
    );
  }, [isMobile, onOpen]);

  return (
    <>
      <Stack width="100%" gap={4}>
        <PageHeader
          pageTitle={pageTitle}
          firstSectionComponent={
            <OrganizationsFiltersFirstSection
              onOpen={onOpen}
              onClose={onClose}
            />
          }
          secondSectionComponent={<OrganizationsFiltersSecondSection />}
          firstSectionInCollpase={isMobile}
        />

        <OrganizationsList />
      </Stack>
      {isOpen && <OrganizationForm isOpen={isOpen} onClose={onClose} />}
    </>
  );
};
