import {
  Badge,
  Button,
  Flex,
  Icon,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiAccount, ApiUserSummary } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback, useMemo, useState } from "react";
import { FaCircle, FaGoogle, FaMicrosoft } from "react-icons/fa";
import { MdPassword } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseTableCellProps } from "react-table";
import { useAuthentication } from "../../components/auth/AuthProvider";
import {
  ApiUserSummarySession,
  UserBadge,
} from "../../components/badges/UserBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../components/data-table/DataTable";
import { CustomModal } from "../../components/modal/CustomModal";
import { PageData } from "../../components/pagination/PageData";
import { Pager } from "../../components/pagination/Pager";
import { useShowToast } from "../../hooks/showToast";
import { InternalUser } from "../../services/api-internal/models/InternalUser";
import { RootState } from "../../store";
import { updateFilters, updateUser } from "../../store/user/users.slice";
import { LogoutUserConfirmation } from "./LogoutUserConfirmation";

export const UserList: FC = () => {
  const [workingUser, setWorkingUser] = useState<ApiUserSummary | null>(null);
  const { users, filters } = useSelector((state: RootState) => state.users);

  const {
    isOpen: isOpenConfirm,
    onOpen: openConfirmDialog,
    onClose: closeConfirmDialog,
  } = useDisclosure();

  const { apiInternal, currentUser } = useAuthentication();
  const showToast = useShowToast();

  const { total, page, pageSize } = filters;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableHeaderBg = useColorModeValue("blue.50", "transparent");

  const dateFormat = useBreakpointValue({
    base: "yyyy-MM-dd",
    sm: "yyyy-MM-dd",
    md: "yyyy-MMM-dd 'at' HH:mm",
  });

  const getUniqueAccounts = useCallback((accounts: ApiAccount[]) => {
    if (!accounts) return [];

    const map = accounts.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {} as Record<string, ApiAccount>);

    return Object.values(map);
  }, []);

  const onPageChange = useCallback(
    (newPage: number) => {
      dispatch(updateFilters({ page: newPage }));
    },
    [dispatch]
  );

  const onClickAccountRow = useCallback(
    (acct: String) => {
      navigate(`/accounts/${acct}`);
    },
    [navigate]
  );

  const handleOnClickLogout = useCallback(
    (user: ApiUserSummarySession) => {
      user && setWorkingUser(user);
      openConfirmDialog();
    },
    [setWorkingUser, openConfirmDialog]
  );

  const cofirmLogoutUser = useCallback(() => {
    workingUser &&
      apiInternal
        .forceLogout({
          userId: workingUser.id,
        })
        .then(() => {
          showToast("success", "User logout successufully");
          dispatch(updateUser({ id: workingUser.id, isActiveSession: false }));
        })
        .catch(() => {
          showToast("error", "something went wrong, please try again!");
        });
    closeConfirmDialog();
  }, [apiInternal, showToast, workingUser, closeConfirmDialog, dispatch]);

  function methodLogin({ method }: { method: String }) {
    if (method === "microsoft") {
      return <Icon as={FaMicrosoft} boxSize={5} />;
    }
    if (method === "google") {
      return <Icon as={FaGoogle} boxSize={5} />;
    }
    if (method === "password") {
      return <Icon as={MdPassword} boxSize={5} />;
    }
    return null;
  }

  const columns = useMemo<DataTableColumn<InternalUser>[]>(
    () => [
      {
        Header: "Name",
        minWidth: 250,
        Cell: (data: UseTableCellProps<InternalUser>) => {
          const user = data.row.original;
          return (
            <Flex gap={2} alignItems="center">
              <UserBadge value={user} includeCopyIcon />
              {user.products.length > 0 &&
                user.accounts.length > 0 &&
                !user.products.find((x) => x.name === "HeroHQ")?.enabled &&
                !user.products.find((x) => x.name === "Events")?.enabled &&
                !user.products.find((x) => x.name === "InventoryHQ")
                  ?.enabled && (
                  <Badge colorScheme="gray" ml={1} maxH="20px">
                    Inactive
                  </Badge>
                )}
            </Flex>
          );
        },
      },
      {
        Header: "Email",
        Cell: (data: UseTableCellProps<InternalUser>) => {
          const user = data.row.original;
          return (
            <Link isTruncated color="blue.500" href={`mailto:${user.email}`}>
              {user.email}
            </Link>
          );
        },
      },
      {
        Header: "List of Accounts",
        maxWidth: 100,
        Cell: (data: UseTableCellProps<InternalUser>) => {
          const user = data.row.original;
          return (
            <List w="100%" display="flex" flexDir="column" height="auto">
              {getUniqueAccounts(user.accounts).map((account, idx) => (
                <ListItem
                  display="flex"
                  alignItems="center"
                  key={`userAccount::${idx}`}
                >
                  <ListIcon as={FaCircle} color="gray" boxSize="8px" />
                  <Link onClick={() => onClickAccountRow(account.id)}>
                    {account.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          );
        },
      },
      {
        Header: "Method",
        maxWidth: 80,
        Cell: (data: UseTableCellProps<InternalUser>) => {
          const user = data.row.original;
          return methodLogin({ method: user.method });
        },
      },
      {
        Header: "Last Login",
        minWidth: 200,
        Cell: (data: UseTableCellProps<InternalUser>) => {
          const user = data.row.original;
          return (
            <Flex>
              <Text color="grey">Last login:</Text>
              <Text>{format(new Date(user.created), dateFormat || "")}</Text>
            </Flex>
          );
        },
      },
      {
        Header: "Actions",
        maxWidth: 80,
        Cell: (data: UseTableCellProps<InternalUser>) => {
          const user = data.row.original;
          return (
            <Flex justifyContent="flex-end">
              {user.isActiveSession && user.id !== currentUser.id && (
                <Button
                  size="xs"
                  variant="outline"
                  colorScheme="red"
                  onClick={() => handleOnClickLogout(user)}
                >
                  Logout
                </Button>
              )}
            </Flex>
          );
        },
      },
    ],
    [
      currentUser.id,
      dateFormat,
      getUniqueAccounts,
      handleOnClickLogout,
      onClickAccountRow,
    ]
  );

  return (
    <Flex w="100%" gap={4} flexDir="column" mt={4}>
      <PageData total={total} currentPage={page} pageSize={pageSize} />
      <DataTable
        columns={columns}
        data={users}
        rest={{ w: "100%" }}
        tableHeaderProps={{ p: 2, bgColor: tableHeaderBg, borderRadius: 6 }}
      />

      <Flex flexWrap="wrap" justifyContent="space-between">
        <PageData total={total} currentPage={page} pageSize={pageSize} />
        <Pager
          currentPage={page}
          totalPages={Math.ceil(total / pageSize)}
          onPageChange={onPageChange}
        />
      </Flex>

      {isOpenConfirm && (
        <CustomModal
          title="Logout User Confirmation"
          isOpen={isOpenConfirm}
          onClose={closeConfirmDialog}
          contentProps={{ maxW: "xl" }}
          content={
            <LogoutUserConfirmation
              onClose={closeConfirmDialog}
              onLogout={cofirmLogoutUser}
            />
          }
        />
      )}
    </Flex>
  );
};
