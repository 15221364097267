import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { StyledSelect } from "../selects/StyledSelect";

interface DashboardControlProps {
  label: string;
  name: string;
  value?: string;
  placeholder?: string;
}

export const DashboardControl: FC<DashboardControlProps> = ({
  label,
  name,
  value,
  placeholder,
}) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({ name, value });

  const { dashboards } = useSelector((state: RootState) => state.auth);

  const dashboardItems = useMemo(() => {
    return dashboards.map((d) => {
      return {
        label: d.name,
        value: d.id,
      };
    });
  }, [dashboards]);

  const handleOnChange = useCallback(
    (value: { label: string; value: string } | null) => {
      helper.setTouched(true);
      helper.setValue(value?.value);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {dashboardItems.length > 0 ? (
        <StyledSelect
          {...field}
          onChange={handleOnChange}
          options={dashboardItems}
          placeholder={placeholder}
        />
      ) : (
        <Text color="gray.500">No Options</Text>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
