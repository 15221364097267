import { Box } from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { FocusEventHandler, useCallback, useMemo } from "react";
import { useShowToast } from "../../hooks/showToast";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponetProp,
  SingleValueSelect,
} from "./select-overrides-types";

export interface UserAutocompleteProps {
  name?: string;
  value: ApiUserSummary | null;
  allowEmpty?: boolean;
  emptyText?: string;
  onChange?: (user: ApiUserSummary | null) => void;
  onBlur?: FocusEventHandler;
  isDisabled?: boolean;
  isInvalid?: boolean;
  displayLeadGroups?: boolean;
  accountId?: string;
  excludeUsers?: ApiUserSummary[];
}

const CustomOptionComponent = createOptionComponent(UserBadge);

const CustomSingleValueComponent = (
  props: SingleValueSelect<ApiUserSummary>
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <UserBadge value={data} />
    </Box>
  );
};

export const UserAutocomplete = ({
  allowEmpty,
  emptyText,
  onChange,
  onBlur,
  name,
  value: user,
  isDisabled,
  isInvalid,
  displayLeadGroups,
  accountId,
  excludeUsers,
}: UserAutocompleteProps) => {
  const showToast = useShowToast();
  const { apiInternal, apiClient } = useAuthentication();

  const nullUser: ApiUserSummary = useMemo(
    () => ({
      firstName: emptyText || "No User",
      lastName: "",
      id: "null-user",
      email: "",
      profileImage: "",
      timeZone: null,
      phone: "",
    }),
    [emptyText]
  );

  const handleChange = useCallback(
    (value: ApiUserSummary | null) => {
      const newValue =
        value === null || value.id === nullUser.id ? null : value;
      if (onChange) onChange(newValue);
    },
    [onChange, nullUser]
  );

  const loadOptions = useCallback(
    async (inputValue: string, cb: any) => {
      if (accountId) {
        return apiClient
          .findAccountUsers(accountId, { search: inputValue })
          .then((response) => {
            if (excludeUsers) {
              const result = response.data.filter(
                (item) => !excludeUsers.find((u) => u.id === item.id)
              );
              return cb(result);
            }
            return cb(response.data);
          })
          .catch(() => {
            showToast("error", "Something went wrong loading users");
          });
      } else {
        return apiInternal
          .findUsers({ search: inputValue })
          .then((response) => {
            return cb(response.data);
          })
          .catch(() => {
            showToast("error", "Something went wrong loading users");
          });
      }
    },
    [accountId, apiClient, apiInternal, excludeUsers, showToast]
  );

  const debouncedLoadOptions = debounce(loadOptions, 250);

  const components = useMemo((): CustomSelectComponetProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiUserSummary>) =>
        CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiUserSummary>) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      key={`userAutocomplete${excludeUsers?.length}`}
      name={name}
      onBlur={onBlur}
      defaultOptions={true}
      value={user || nullUser}
      cacheOptions={true}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      getOptionValue={(item: ApiUserSummary) => item.id}
      isDisabled={isDisabled}
    />
  );
};
