import {
  Box,
  Collapse,
  Flex,
  FlexProps,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";

interface CustomCollapseProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  triggerProps?: FlexProps;
}

export const CustomCollapse: FC<CustomCollapseProps> = ({
  trigger,
  children,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box w="full">
      <Flex justifyContent="space-between" onClick={() => onToggle()} w="full">
        {trigger}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Box mt={2}>{children}</Box>
      </Collapse>
    </Box>
  );
};
