import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ApiAccountSubscription } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { SubscriptionForm } from "./SubcriptionForm";

interface SubscriptionFormProps {
  isOpen: boolean;
  onClose: () => void;
  subscription: ApiAccountSubscription | null;
}

export const SubscriptionModal: FC<SubscriptionFormProps> = ({
  isOpen,
  onClose,
  subscription,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold">
          {subscription && subscription.id
            ? "Update Subscription"
            : "Add Subscription"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {subscription && (
            <SubscriptionForm
              subscription={subscription}
              closeModal={onClose}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
